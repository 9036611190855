import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavBar from "../../user/SideNavbar/SideNaveBar";
import DashboardHeader from "../../user/DashboardHeader/DashboardHeader";
import Footer from "../../../components/Footer";
import client from "../../../api";
import Urls from "../../../api/urls";
import { useDispatch } from "react-redux";
import { addUserInfo } from "../../../redux/features/user/userInfoSlice";
import { message } from "antd";

const DefaultLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Paths where the sidebar and header should be hidden
  const excludedPaths = [
    "/",
    "/verify",
    "/forgot",
    "/reset-password",
    "/signup",
    "/GuestLogin",
    "/dashboard",
    "/login",
    "/terms",
    "/privacy",
    "/account-verification",
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const get_user = async () => {
    try {
      const response = await client.get(Urls.CURRENT_USER);
      if (response.status === 200) {
        const results = response.data.results;
        dispatch(addUserInfo(results));
      } else if (response.status === 401) {
        message.error("Your session has expired. Please log in again.");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Your session has expired. Please log in again.");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      } else {
        message.error(`An error occurred while fetching user details.${error}`);
      }
      console.error("Error occurred while fetching user details:", error);
    }
  };

  const shouldHideSidebarAndHeader = excludedPaths.includes(location.pathname) || location.pathname.includes("/pdps") || location.pathname.includes("/welcome");

  useEffect(() => {
    if (!excludedPaths.includes(location.pathname)) get_user();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          backgroundColor: "#f3f4f6",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          {!shouldHideSidebarAndHeader && (
            <DashboardHeader
              isDrawerOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSideNavbar={() => setIsSidebarOpen(false)}
              style={{ width: "100%" }}
            />
          )}
          {!shouldHideSidebarAndHeader && (
            <SideNavBar
              toggleSidebar={toggleSidebar}
              isDrawerOpen={isSidebarOpen}
            />
          )}
          <main
            style={{
              flexGrow: 1,
              overflow: "auto",
            }}
          >
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
