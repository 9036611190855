import React, { useState } from "react";
import "./SupportPanel.css";
import { RxCross1 } from "react-icons/rx";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import GeneralInquiry from "./GeneralInquiry/GeneralInquiry";

export default function SupportPanel({
  showSupportPanel,
  setShowSupportPanel,
}) {
  const [showInquiryPanel, setShowInquiryPanel] = useState(false);
  const [faqS, setFaqs] = useState(false);

  const handleCardClick = (type) => {
    if (type === "General Inquiry / Bug Report") {
      setShowInquiryPanel(true);
    } else if (type === "FAQs") {
      setFaqs(true);
    }
  };
  const handleCloseSupportPanel = () => {
    setShowSupportPanel(false);
  };

  return (
    <div className="support-panel-sidebar">
      <RxCross1
        style={{ width: "20px", height: "20px", margin: 20 }}
        onClick={handleCloseSupportPanel}
      />
      <h1 className="supportPanelHeading">Support Center</h1>
      <Card
        sx={{
          maxWidth: 350,
          height: 100,
          margin: 3,
          borderRadius: "12px",
          overflow: "hidden",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(104, 105, 247, 0.2)",
        }}
        onClick={() => handleCardClick("General Inquiry / Bug Report")}
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            padding={1}
          >
            <Typography
              className="supportPanel-labels"
              sx={{ fontSize: "16px" }}
            >
              General Inquiry / Bug Report
            </Typography>
            <Typography sx={{ ml: 2 }}>
              <IoIosArrowForward />
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card
        sx={{
          maxWidth: 350,
          height: 100,
          margin: 3,
          borderRadius: "12px",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(104, 105, 247, 0.2)",
        }}
        onClick={() => handleCardClick("FAQs")}
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            padding={2}
          >
            <Typography
              className="supportPanel-labels"
              sx={{ fontSize: "16px" }}
            >
              FAQs
            </Typography>
            <Typography sx={{ ml: 2 }}>
              <IoIosArrowForward />
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {showInquiryPanel && (
        <GeneralInquiry
          showInquiryPanel={showInquiryPanel}
          setShowInquiryPanel={setShowInquiryPanel}
        />
      )}
    </div>
  );
}
