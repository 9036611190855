import React from "react";
import { Link } from "react-router-dom";

const CustomCheckbox = ({ label, checked, setChecked }) => {
  //   const [checked, setChecked] = useState(false);

  return (
    <label className="flex items-center cursor-pointer gap-2">
      <div>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
          className="hidden"
        />
        <div
          className={`w-[26px] h-[26px] rounded-lg border border-black flex items-center justify-center
          ${
            checked ? "bg-[#EDEDFF] border-[#6869F7]" : "bg-white border-black"
          }`}
          style={{ borderWidth: "1px" }}
        >
          {checked && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6869F7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
          )}
        </div>
      </div>
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default CustomCheckbox;
