// Main styles for the site components
import styled from "styled-components";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { useField } from "formik";
import backgroundImage from "../components/loginbackground.png";

export const FormContainer = styled.div`
  align-items: center;
  &::before {
    content: ""; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(#6464ff50, #6464ff50),
      url(${backgroundImage});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    // z-index: -1;
    pointer-events: none;
  }

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
    overflow-y: auto;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    max-width: 100%;
  }
`;

export const OthersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LoginForm = styled.div`
  min-width: 300px;
  width: 30vw;
  border-radius: 8px;

  @media (min-width: 1000px) {
    position: relative;
    overflow-y: auto; /* Allows scrolling inside if needed */
    max-height: 100vh; /* Prevents cutting off errors */
  }

  @media (max-width: 1000px) {
    margin: auto;
  }
`;
export const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 2rem;
`;

export const MainTitle = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
`;

export const SmallText = styled.h1`
  color: #707070;
  text-align: center;
`;

export const FormikInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Input
        {...props}
        label={label}
        value={field.value}
        onChange={field.onChange}
        htmlFor={props.id || props.name}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 10px 5px;
  padding: 10px 0;
  background-color: #6869f7;
  border: 0;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  fontsize: 16px;
  texttransform: capitalize;
`;

export const CreateAccount = styled(Button)`
  width: 100%;
  margin: 10px 5px;
  padding: 10px 0;
  background-color: transparent;
  border: 2px solid #6869f7;
  border-radius: 50px;
  color: #6869f7;
  font-weight: 600;
  fontsize: 16px;
  texttransform: capitalize;
  &:hover {
    background-color: #6869f7;
    color: #fff;
  }
`;
