import React, { useState, useEffect } from "react";
import { HiArrowRight } from "react-icons/hi";
import {
  IN_TAKE_FORM_QUESTIONS,
  MEDICAL_INSURANCE_LIST,
} from "../../../utils/constants";
import { getPhoneNumberPattern } from "../../../utils/constants";
import CustomCheckbox from "../../../utils/CustomCheckbox";
import CustomDropdown from "../../../utils/CustomDropdown/CustomDropdown";
import { Country, State } from "country-state-city";
import {
  parsePhoneNumberFromString,
  AsYouType,
  Metadata,
} from "libphonenumber-js";
import CustomDateField from "../../../utils/CustomDateField";

const PDPSurveyForm = ({ handleNextStep }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [selectedState, setSelectedState] = useState(undefined);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [maxLength, setMaxLength] = useState(null);
  const [selectedInsurance, setSelectedInsurance] = useState(undefined);

  const currentQuestion = IN_TAKE_FORM_QUESTIONS[currentQuestionIndex];
  // const isAnswered = currentQuestion.fields
  //   ? currentQuestion.fields.every((field) => answers[field.id])
  //   : answers[currentQuestion.id];

  const isAnswered = () => {
    if (currentQuestion.fields) {
      return currentQuestion.fields.every((field) => answers[field.id]);
    }

    if (currentQuestion.questions) {
      return currentQuestion.questions.every((q) =>
        q.fields.every((field) => {
          if (
            field.id === "health_insurance" &&
            selectedCountry.value !== "US"
          ) {
            return true;
          }
          if (
            field.id === "health_insurance" &&
            answers[field.id] === "Other"
          ) {
            return false;
          }
          return answers[field.id];
        })
      );
    }

    return answers[currentQuestion.id] || false;
  };

  const medicalInsuranceList = [
    ...MEDICAL_INSURANCE_LIST.sort((a, b) => a - b),
    "Other",
  ].map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const handleNext = () => {
    if (isAnswered) {
      console.log(
        "currentQuestionIndex",
        currentQuestionIndex,
        selectedCountry
      );

      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentQuestionIndex((prev) => prev - 1);

    if (error) setError("");
  };

  const formatPhoneNumber = (number) => {
    if (!selectedCountry) return number;

    const formattedNumber = new AsYouType(selectedCountry.value).input(number);
    return formattedNumber;
  };

  const validatePhoneNumber = (id) => {
    if (!selectedCountry || !answers[id]) {
      setError(null);
      return;
    }

    const parsedNumber = parsePhoneNumberFromString(
      answers[id],
      selectedCountry.value
    );
    const isValidNumber = parsedNumber?.isValid() || false;

    setError(isValidNumber ? "" : "Invalid Phone Number");
  };

  const handleChange = (id, value, type, min, max) => {
    console.log("answers", answers);
    if (id === "phone_number") {
      let rawNumber = value.replace(/\D/g, ""); // Remove non-numeric characters
      // Restrict length based on country max length
      if (maxLength && rawNumber.length > maxLength) {
        rawNumber = rawNumber.slice(0, maxLength);
      }
      setAnswers((prev) => ({ ...prev, [id]: formatPhoneNumber(rawNumber) }));
    } else {
      let error = "";

      // Convert value to number if it's a number field
      if (type === "number") {
        value = value === "" ? "" : Number(value);

        // Validate min and max
        if (min !== undefined && value < min) {
          error = `Value should be at least ${min}.`;
        } else if (max !== undefined && value > max) {
          error = `Value should not exceed ${max}.`;
        }
      }

      setErrors((prevErrors) => ({ ...prevErrors, [id]: error }));
      setAnswers((prev) => ({
        ...prev,
        [id]: value.value ? value.value : value,
      }));
    }
    if (type === "button") handleNext();
  };

  const handleSubmit = () => {
    handleNextStep();
    const BMI = calculateBMI();
    const age = calculateAge(answers.birth_date);
    setAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        BMI,
        age,
      };
      console.log("Updated Answers:", updatedAnswers); // Log inside setAnswers
      return updatedAnswers;
    });
  };

  const calculateBMI = () => {
    const totalInches =
      (parseFloat(answers.height_feet) || 0) * 12 +
      (parseFloat(answers.height_inches) || 0);
    const weightLbs = parseFloat(answers.weight_lbs) || 0;

    if (totalInches > 0 && weightLbs > 0) {
      return ((weightLbs * 703) / (totalInches * totalInches)).toFixed(2);
    }
  };

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const countriesList = Country.getAllCountries();
    const allCountries = countriesList.map((country) => ({
      label: country.name,
      value: country.isoCode,
      dialCode: `+${country.phonecode}`, //country.phonecode,
    }));
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setAnswers((prev) => ({
        ...prev,
        ["phone_number"]: undefined,
        ["health_insurance"]: undefined,
      }));
      const states = State.getStatesOfCountry(selectedCountry.value).map(
        (state) => ({ label: state.name, value: state.isoCode })
      );
      setStates(states);

      try {
        // Extract possible phone number lengths from metadata
        const metadata = new Metadata();
        metadata.selectNumberingPlan(selectedCountry.value);
        const possibleLengths = metadata.numberingPlan.possibleLengths();
        const maxPhoneLength =
          possibleLengths[possibleLengths.length - 1] || 15;

        setMaxLength(maxPhoneLength);
      } catch (error) {
        setMaxLength(15); // Fallback max length
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!answers["birth_date"]) {
      const today = new Date();
      const maxDate = new Date(
        today.getFullYear() - 10,
        today.getMonth(),
        today.getDate()
      );

      // Format to MM/DD/YYYY
      const formattedDate = maxDate.toLocaleDateString("en-US");

      setAnswers((prev) => ({
        ...prev,
        birth_date: formattedDate,
      }));
    }
  }, [answers, setAnswers]); // Runs when `answers` state updates

  const renderInput = (field) => {
    console.log(field.id);
    switch (field.type) {
      case "checkbox":
        return (
          <CustomCheckbox
            id={field.id}
            label={field.question}
            checked={answers[field.id] || false}
            setChecked={(value) => handleChange(field.id, value, field.type)}
          />
        );

      case "dropdown":
        if (field.id === "country") {
          return (
            <CustomDropdown
              label={
                <div>
                  Country<span className="text-red-600">*</span>
                </div>
              }
              options={countries}
              placeholder="Select option"
              value={selectedCountry?.value}
              onChange={(value) => {
                const selected = countries.find(
                  (country) => country.value === value
                );
                setSelectedCountry(selected);
                setSelectedState(""); // Reset state selection when country changes
                handleChange(field.id, selected, field.type);
              }}
            />
          );
        }

        if (field.id === "state") {
          // Render state dropdown only if a country is selected
          if (!selectedCountry) return null;

          return (
            <CustomDropdown
              label={
                <div>
                  State<span className="text-red-600">*</span>
                </div>
              }
              options={states}
              placeholder="Select option"
              value={selectedState.value}
              onChange={(value) => {
                const selected = states.find((state) => state.value === value);
                setSelectedState(selected);
                handleChange(field.id, selected, field.type);
              }}
            />
          );
        }

        if (field.id === "health_insurance") {
          return (
            <>
              <CustomDropdown
                label={
                  <div>
                    Insurance Provider<span className="text-red-600">*</span>
                  </div>
                }
                options={medicalInsuranceList}
                placeholder="Select option"
                value={selectedInsurance?.value}
                onChange={(value) => {
                  const selected = medicalInsuranceList.find(
                    (insurance) => insurance.value === value
                  );
                  setSelectedInsurance(selected);
                  handleChange(field.id, selected, field.type);
                }}
              />

              {selectedInsurance && selectedInsurance.value === "Other" ? (
                <div className="mb-4">
                  {/* Input field */}
                  <input
                    type="text"
                    placeholder="Enter Insurance Provider"
                    className="w-full p-3 border border-gray-300 rounded-2xl  focus:outline-none"
                    style={{ borderColor: "rgba(51, 51, 51, 0.5)" }}
                    value={
                      answers[field.id] && answers[field.id].value !== "Other"
                        ? answers[field.id].value
                        : ""
                    }
                    required
                    onChange={(e) =>
                      handleChange(field.id, e.target.value, field.type)
                    }
                  />
                </div>
              ) : null}
            </>
          );
        } else {
          return (
            <CustomDropdown
              options={field.options}
              placeholder="Select option"
              value={answers[field.id]}
              onChange={(value) => {
                const selected = field.options.find(
                  (option) => option.value === value
                );
                setSelectedInsurance(selected);
                handleChange(field.id, selected, field.type);
              }}
            />
          );
        }

      case "date":
        if (field.id === "birth_date") {
          const today = new Date();
          const maxDate = new Date(
            today.getFullYear() - 10,
            today.getMonth(),
            today.getDate()
          ); // Min age is 10
          const minDate = new Date(1970, 0, 1); // Max date is 1970 or younger

          return (
            <CustomDateField
              value={
                answers[field.id]
                  ? answers[field.id]
                  : maxDate.toISOString().split("T")[0]
              }
              onChange={(value) => handleChange(field.id, value, field.type)}
              minDate={minDate}
              maxDate={maxDate}
              showYearDropdown={true}
            />
          );
        }

        if (field.id === "baby_due_date") {
          const today = new Date();

          const minDate = today; // Today’s date
          const maxDate = new Date(
            today.getFullYear(),
            today.getMonth() + 10,
            today.getDate()
          );

          return (
            <CustomDateField
              value={answers[field.id] ? answers[field.id] : undefined}
              onChange={(value) => handleChange(field.id, value, field.type)}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        }

        return (
          <CustomDateField
            value={answers[field.id] ? answers[field.id] : undefined}
            onChange={(value) => handleChange(field.id, value, field.type)}
          />
        );

      case "text":
      case "number":
        if (field.id === "phone_number") {
          const { pattern, placeholder } =
            getPhoneNumberPattern(selectedCountry);

          return (
            <>
              <div key={field.id} className="mb-4">
                <label
                  htmlFor={field.id}
                  className="block text-base font-medium text-gray-800 ml-2 mb-2"
                >
                  Phone Number<span className="text-red-600">*</span>
                </label>

                <input
                  type="tel"
                  placeholder={placeholder}
                  className="w-full p-3 border border-gray-300 rounded-2xl  focus:outline-none"
                  style={{ borderColor: "rgba(51, 51, 51, 0.5)" }}
                  value={answers[field.id] || ""}
                  required={field.required}
                  onChange={(e) =>
                    handleChange(field.id, e.target.value, field.type)
                  }
                  onBlur={() => validatePhoneNumber(field.id)}
                />
                {error && (
                  <span className="text-red-500 text-sm mt-1">{error}</span>
                )}
              </div>
            </>
          );
        }
        if (
          ["weight_lbs", "height_feet", "height_inches", "obgyn_name"].includes(
            field.id
          )
        ) {
          return (
            <div key={field.id} className="mb-4">
              {/* Label for the input */}
              {field.question && (
                <label
                  htmlFor={field.id}
                  className={`block text-base font-medium text-gray-800 ml-2 mb-2 ${
                    field.id === "obgyn_name" ? "mt-8 mb-2" : ""
                  }`}
                >
                  {field.question}
                </label>
              )}

              {/* Input field */}
              <input
                id={field.id}
                type={field.type}
                placeholder={field.placeholder}
                className={`w-full p-3 border rounded-2xl focus:outline-none ${
                  errors[field.id] ? "border-red-500" : "border-gray-300"
                }`}
                min={field.type === "number" ? field.min : undefined}
                max={field.type === "number" ? field.max : undefined}
                value={answers[field.id] || ""}
                required={field.required}
                onChange={(e) =>
                  handleChange(
                    field.id,
                    e.target.value,
                    field.type,
                    field.min,
                    field.max
                  )
                }
              />

              {errors[field.id] && (
                <p className="text-red-500 text-sm mt-1">{errors[field.id]}</p>
              )}
            </div>
          );
        }

        return (
          <>
            <input
              type={field.type}
              placeholder={field.placeholder}
              className="w-full p-3 border border-gray-300 rounded-2xl focus:outline-none"
              style={{ borderColor: "rgba(51, 51, 51, 0.5)" }}
              value={answers[field.id] ? answers[field.id] : undefined}
              required={field.required}
              onChange={(e) =>
                handleChange(field.id, e.target.value, field.type)
              }
            />
          </>
        );

      case "button":
        return (
          <div className="flex flex-col gap-4">
            {field?.options?.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => handleChange(field.id, option, field.type)}
                className={`px-6 py-2 border rounded-xl ${
                  answers[field.id] === option
                    ? "bg-blue-100 text-black border-[#6869F7]"
                    : "bg-white text-black hover:bg-blue-50"
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white p-4">
      <div
        className="max-w-2xl mx-auto rounded-xl p-8 relative flex flex-col"
        style={{ height: "92vh" }}
      >
        {/* Progress Indicator */}
        <div className="mb-8">
          <div
            className="h-3 rounded-full"
            style={{ backgroundColor: "#FFDB7C" }}
          >
            <div
              className="h-3 rounded-full transition-all"
              style={{
                backgroundColor: "#7A7FFF",
                width: `${
                  (currentQuestionIndex / IN_TAKE_FORM_QUESTIONS.length) * 100
                }%`,
              }}
            ></div>
          </div>
        </div>

        {/* Question Content */}
        <div className="space-y-6 flex-1 overflow-y-auto">
          {currentQuestion.questions && currentQuestion.questions.length > 0 ? (
            <>
              {currentQuestion.questions.map((question) => {
                // Condition to hide only "Do you have a health plan?" for non-US users
                if (
                  question.question === "Do you have a health plan?" &&
                  selectedCountry.value !== "US"
                ) {
                  return null;
                }
                return (
                  <div key={question.question}>
                    <h2 className="text-2xl font-semibold text-gray-800">
                      {question.question}
                    </h2>
                    {question.fields
                      ? question.fields.map((field) => (
                          <div key={field.id}>{renderInput(field)}</div>
                        ))
                      : renderInput(question)}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <h2 className="text-2xl font-semibold text-gray-800">
                {currentQuestion.question}
              </h2>
              {currentQuestion.fields
                ? currentQuestion.fields.map((field) => (
                    <div key={field.id}>{renderInput(field)}</div>
                  ))
                : renderInput(currentQuestion)}
            </>
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="mt-8 flex justify-between pt-4">
          <button
            type="button"
            onClick={handleBack}
            className={`px-10 py-3 rounded-3xl ${
              currentQuestionIndex === 0
                ? "bg-[#FFDB7C] cursor-not-allowed"
                : "bg-[#FFDB7C] hover:bg-[#f3c240]"
            }`}
            disabled={currentQuestionIndex === 0}
          >
            Back
          </button>
          {currentQuestionIndex === IN_TAKE_FORM_QUESTIONS.length - 1 ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="px-10 py-3 bg-[#6869F7] text-white rounded-3xl hover:bg-blue-600"
            >
              Submit
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              className={`flex items-center gap-2 px-10 py-3 rounded-3xl ${
                isAnswered() &&
                !error &&
                Object.values(errors).every((err) => !err)
                  ? "bg-[#6869F7] text-white hover:bg-[#2f30f5]"
                  : "bg-[#6869F7] cursor-not-allowed"
              }`}
              disabled={
                !isAnswered() ||
                error ||
                Object.values(errors).some((err) => err)
              }
            >
              Next <HiArrowRight size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PDPSurveyForm;
