import React, { useState, useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { Slider } from "@mui/material";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./chatSettings.css";
import { Language as LanguageIcon } from "@mui/icons-material";
import { RiVoiceprintFill, RiSpeedMiniFill } from "react-icons/ri";
import InputLabel from "@mui/material/InputLabel";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  height: "50px !important",
  width: "82px !important",
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    border: "1px solid slateblue",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    backgroundColor: theme.palette.grey[400],
    position: "relative",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "16px",
      fontWeight: "400",
      color: theme.palette.getContrastText(theme.palette.primary.main),
      transition: "opacity 0.3s",
    },
    "&::before": {
      content: '"On"',
      left: 12,
      opacity: 0, // Hide "ON" by default (unchecked state)
    },
    "&::after": {
      content: '"Off"',
      right: 12,
      opacity: 1, // Show "OFF" by default (unchecked state)
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 20,
    height: 20,
    margin: 10,
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    transition: "transform 0.3s",
    transform: "translateX(5px)",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        "&::after": {
          opacity: 0, // Hide "OFF" when the switch is checked
        },
        "&::before": {
          opacity: 1, // Show "ON" when the switch is checked
        },
      },
    },
    "&:not(.Mui-checked) + .MuiSwitch-track": {
      "&::before": {
        opacity: 0, // Hide "ON" when the switch is unchecked
      },
      "&::after": {
        opacity: 1, // Show "OFF" when the switch is unchecked
      },
    },
  },
}));

const ChatSettings = ({
  handleVoiceChange,
  handleTestSpeaker,
  handleSaveSettings,
  handleLanguageChange,
  toggleVoiceover,
  handleTextSizeChange,
  language,
  isVoiceEnabled,
  voice,
  volume,
  voices,
  textSize,
  handleCloseChatSettings,
  handleVolumeChange,
  selectedTextToPlay,
  speed,
  handleSpeedChange,
  speedOptions,
}) => {
  return (
    <>
      <div className="chat-settings-sidebar">
        <button onClick={handleCloseChatSettings} className="close">
          <FontAwesomeIcon icon={faX} />
        </button>

        <div className="chatSettingsHeading">Chat Settings</div>

        <div style={{ padding: "0 15px" }}>
          {/* Language Dropdown */}
          <div className="setting-item">
            <div className="chatSettings-labels">Language</div>
            <div className="dropdown-div">
              <Select
                disabled={selectedTextToPlay !== null}
                value={language}
                onChange={handleLanguageChange}
                className="chat-settings-fields"
                displayEmpty
                renderValue={(selected) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LanguageIcon
                      style={{ marginRight: "8px", color: "white" }}
                    />
                    {selected ? voices[selected][0].display : "Select Language"}
                  </div>
                )}
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={key}>
                    {voices[key][0].display}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Voiceover Toggle */}
          <div className="setting-item">
            <div className="chatSettings-labels">Voiceover</div>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={isVoiceEnabled}
                  onChange={toggleVoiceover}
                  inputProps={{ "aria-label": "Voiceover switch" }}
                  className="chat-settings-fields"
                />
              }
            />
          </div>

          {/* Voice Dropdown */}
          <div className="setting-item">
            <div className="chatSettings-labels">Voice</div>
            <div className="dropdown-div">
              <Select
                disabled={selectedTextToPlay !== null}
                value={voice}
                onChange={handleVoiceChange}
                className="chat-settings-fields"
                displayEmpty
                renderValue={(selected) => {
                  const selectedLabel = Object.values(voices)
                    .flat()
                    .find((voiceObj) => voiceObj.name === selected)?.label;

                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiVoiceprintFill
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      {selectedLabel || "Select Voice"}
                    </div>
                  );
                }}
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={voices[key][0].name}>
                    {voices[key][0].label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Volume Slider */}
          <div className="setting-item">
            <div className="chatSettings-labels">Volume</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "150px",
                gap: "10px",
                opacity: selectedTextToPlay !== null ? "0.5" : "1",
              }}
            >
              <VolumeDown />
              <Slider
                aria-label="Volume"
                value={volume}
                onChange={(event, newValue) =>
                  handleVolumeChange(event, newValue)
                }
                min={0}
                max={100}
                style={{ margin: "0 3px", flex: 1 }}
                className="chat-settings-fields"
                disabled={selectedTextToPlay !== null}
              />
              <VolumeUp />
            </div>
          </div>

          {/* Speed */}
          <div className="setting-item">
            <div className="chatSettings-labels">Speed</div>
            <div className="dropdown-div">
              <Select
                disabled={selectedTextToPlay !== null}
                value={speed}
                onChange={(e) => handleSpeedChange(e)}
                className="chat-settings-fields"
                displayEmpty
                renderValue={(selected) => {
                  const selectedLabel = speedOptions.find(
                    (option) => option.value === selected
                  )?.label;

                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiSpeedMiniFill
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      {selectedLabel || "Select Speed"}
                    </div>
                  );
                }}
              >
                {speedOptions.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Test Speaker Button */}
          <button className="test-speaker" onClick={handleTestSpeaker}>
            Test Speaker
          </button>

          {/* Text Size Slider */}
          <div className="setting-item">
            <div className="chatSettings-labels">Text Size</div>
            <div className="textsize-slider">
              <span style={{ marginRight: "10px" }}>A</span>
              <Slider
                aria-label="textSize"
                value={textSize}
                onChange={handleTextSizeChange}
                min={12}
                max={24}
                className="chat-settings-fields"
              />
              <span style={{ marginLeft: "10px", fontSize: "larger" }}>A</span>
            </div>
          </div>

          {/* Sample Text */}
          <p className="sample-text" style={{ "--text-size": `${textSize}px` }}>
            This is a sample text of what you will see in the chat.
          </p>

          {/* Division bar */}
          <div className="bar"></div>

          {/* Save Settings Button */}
          <button onClick={handleSaveSettings} className="save-settings">
            Save Settings
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatSettings;
