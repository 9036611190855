import React, { useState, useEffect, useRef, useContext } from "react";
import ChatSettings from "../ChatSettings/ChatSettings";
import { ChatContext } from "../../../context/chatContext";
import Chat from "./Chat";
import { useDispatch, useSelector } from "react-redux";
import chatFlow from "../../../utils/Chatflow";
import client from "../../../api";
import Urls from "../../../api/urls";
import { addChatData } from "../../../redux/features/chat/chatSlice";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import "./Chat.css";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import SupportPanel from "../SupportPanel/SupportPanel";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const voices = {
  "en-US": [
    { name: "en-US-JennyNeural", label: "Jenny", display: "English (US)" },
  ],
  "es-MX": [
    { name: "es-MX-DaliaNeural", label: "Dalia", display: "Spanish (Mexico)" },
  ],
  "es-US": [
    { name: "es-US-AlonsoNeural", label: "Alonso", display: "Spanish (US)" },
  ],
  "zh-TW": [
    {
      name: "zh-TW-HsiaoChenNeural",
      label: "Hsiao Chen",
      display: "Taiwanese (TW)",
    },
  ],
};

const speedOptions = [
  { label: "0.25x", value: 0.25 },
  { label: "0.5x", value: 0.5 },
  { label: "0.75x", value: 0.75 },
  { label: "1x", value: 1 },
  { label: "1.25x", value: 1.25 },
  { label: "1.5x", value: 1.5 },
  { label: "1.75x", value: 1.75 },
  { label: "2x", value: 2 },
];

const ChatsPage = () => {
  const gainNodeRef = useRef(null);
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    showChatSettings,
    setShowChatSettings,
    showSupportPanel,
    setShowSupportPanel,
    currentQuestionId,
    setCurrentQuestionId,
  } = useContext(ChatContext);
  const chatData = useSelector((state) => state.chat.data);
  const questionSequence = [
    74, 76, 78, 80, 86, 104, 107, 131, 139, 146, 168, 176, 182, 200, 232, 237,
    239, 241, 250, 252, 263, 264,
  ];

  const [textSize, setTextSize] = useState(14);
  const [voice, setVoice] = useState("en-US-JennyNeural");
  const [volume, setVolume] = useState(50);
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  const [language, setLanguage] = useState("en-US");
  const [selectedTextToPlay, setSelectedTextToPlay] = useState(null);
  const [speed, setSpeed] = useState(1);
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(true);
  const [userResponses, setUserResponses] = useState({});
  const [waitingForInput, setWaitingForInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [userTextInput, setUserTextInput] = useState("");
  const [player, setPlayer] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [storeSelectedText, setStoreSelectedText] = useState(null);

  const fetchChatHistory = () => {
    setIsChatHistoryLoading(true);
    const { messages, nextId } = chatData;
    const orderedHistory = [];
    messages.forEach((message) => {
      const systemChat = chatFlow[language].find(
        (flow) => flow.id === message.system
      );
      const userReply = { text: message.user, user: true };
      orderedHistory.push(systemChat);
      orderedHistory.push(userReply);
    });
    if (nextId) {
      const nextIdChat = chatFlow[language].find((flow) => flow.id === nextId);
      orderedHistory.push(nextIdChat);
    }
    setChatHistory(orderedHistory);

    // Update progress if last message has options
    // if (
    //   orderedHistory.length > 0 &&
    //   orderedHistory[orderedHistory.length - 1].options &&
    //   orderedHistory[orderedHistory.length - 1].options.length > 0
    // ) {
    //   setCurrentQuestionId(
    //     orderedHistory[orderedHistory.length - 1].options[0].nextId
    //   );
    // }
    setIsChatHistoryLoading(false);
  };

  const get_chat = async () => {
    setIsChatHistoryLoading(true);
    try {
      const response = await client.get(Urls.GET_CHAT);
      const results = response.data.data;
      dispatch(addChatData(results));
    } catch (error) {
      console.error("Error while fetching chat data:", error);
      if (error.status && error.status === 401) {
        message.error("Your session has expired. Please log in again.");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      } else {
        message.error(
          `Error while fetching chat data: ${
            error || "An unexpected error occurred."
          }`
        );
      }
    }
    setIsChatHistoryLoading(false);
  };

  const toggleSettingsPanel = () => {
    setShowChatSettings(false);
    setShowSupportPanel(false);
  };

  const handleTextSizeChange = (event, newValue) => {
    setTextSize(newValue);
  };
  const toggleVoiceover = (event) => {
    const enabled = event.target.checked;
    console.log("voiceOverEnabled", enabled);
    setIsVoiceEnabled(enabled);
    if (chatHistory && enabled) {
      const value = chatHistory[chatHistory.length - 1].text;
      const combinedText = Array.isArray(value)
        ? value.join(" ") // Join array elements with a space
        : value;
      handleVoiceCommand(combinedText);
      setSelectedTextToPlay(value);
      setStoreSelectedText(value);
      setIsSpeaking(true);
    } else {
      setSelectedTextToPlay(null);
    }
  };

  const handleLanguageChange = async (e) => {
    setLanguage(e.target.value);
    setVoice(voices[e.target.value][0].name);
    // setShowLanguageDropdown(false);
  };

  const handleSaveSettings = () => {
    localStorage.setItem("lang", language);
    localStorage.setItem("vc", voice);
    localStorage.setItem("size", textSize);
    localStorage.setItem("vcover", isVoiceEnabled.toString());
    toggleSettingsPanel();
  };

  const handleVolumeChange = (event, newValue) => {
    console.log("volumeNUm", newValue);
    setVolume(newValue);
    // if (gainNodeRef.current) {
    //   gainNodeRef.current.gain.value = newValue / 100; // Dynamically adjust volume
    //   console.log("Updated volume:", gainNodeRef.current.gain.value);
    // }
  };

  const handleSpeedChange = (e) => {
    setSpeed(parseFloat(e.target.value));
    // setShowSpeedDropdown(false);
  };

  const handleVoiceCommand = async (text) => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      process.env.REACT_APP_AZURE_KEY,
      process.env.REACT_APP_REGION
    );
    speechConfig.speechSynthesisVoiceName = voice;
    speechConfig.speechSynthesisRate = speed;

    const player = new sdk.SpeakerAudioDestination();
    player.onAudioEnd = function (_) {
      setSelectedTextToPlay(null);
      setIsSpeaking(false);
    };
    setPlayer(player);
    const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
    const newSynthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    const textArray = Array.isArray(text) ? text : [text];
    const fullText = textArray.join(" ");

    const clampedVolume = Math.min(100, Math.max(0, volume));
    const clampedSpeed = Math.min(2, Math.max(0.25, speed));

    const ssml = `
      <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
        <voice name="${voice}">
          <prosody rate="${clampedSpeed}" volume="${clampedVolume}">
            ${fullText}
          </prosody>
        </voice>
      </speak>`;

    newSynthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesized successfully.");
        } else {
          console.error(`Speech synthesis failed: ${result.errorDetails}`);
        }
        newSynthesizer.close();
      },
      (error) => {
        console.error(`Error: ${error}`);
        newSynthesizer.close();
      }
    );
  };

  const handleTestSpeaker = () => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      process.env.REACT_APP_AZURE_KEY,
      process.env.REACT_APP_REGION
    );
    speechConfig.speechSynthesisVoiceName = voice;
    speechConfig.speechSynthesisRate = speed;

    const player = new sdk.SpeakerAudioDestination();
    //  player.onAudioEnd = function (_) {
    //    window.console.log("playback finished");
    //    setSelectedTextToPlay(null);
    //    setIsSpeaking(false);
    //  };
    const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
    const newSynthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    const fullText = "This is a sample text of what you will see in the chat.";
    console.log("isVoiceEnabled", isVoiceEnabled);

    const clampedVolume = Math.min(100, Math.max(0, volume));
    const clampedSpeed = Math.min(2, Math.max(0.25, speed));

    console.log("Voice synthesis properties:");
    console.log("Clamped Volume:", clampedVolume);
    console.log("Clamped Speed:", clampedSpeed);

    const ssml = `
      <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
        <voice name="${voice}">
          <prosody rate="${clampedSpeed}" volume="${clampedVolume}%">
            ${fullText}
          </prosody>
        </voice>
      </speak>`;

    newSynthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesized successfully.");
        } else {
          console.error(`Speech synthesis failed: ${result.errorDetails}`);
        }
        newSynthesizer.close();
      },
      (error) => {
        console.error(`Error: ${error}`);
        newSynthesizer.close();
      }
    );
  };

  const handleVoiceChange = async (e) => {
    setVoice(e.target.value);
    // setShowVoiceDropdown(false);
  };

  const startChat = () => {
    const firstStep = chatFlow[language].find((step) => step.id === 1);
    setCurrentQuestionId(1);
    setChatHistory([
      ...chatHistory,
      { text: firstStep.text, options: firstStep.options },
    ]);
  };

  const storeChat = async (nextId, optionText) => {
    await client.post(Urls.UPDATE_CHAT, {
      email: localStorage.getItem("email"),
      message: { system: currentQuestionId, user: optionText },
      last_session_date: new Date().toLocaleDateString(),
      nextId,
    });
  };

  const handleOptionClick = (nextId, optionText) => {
    storeChat(nextId, optionText);
    const parsedOptionValue = parseInt(optionText, 10);

    setChatHistory([...chatHistory, { text: optionText, user: true }]);

    const updatedResponses = {
      ...userResponses,
      [currentQuestionId]: parsedOptionValue,
    };
    setUserResponses(updatedResponses);

    const value5 = updatedResponses[5] || 0;
    const value6 = updatedResponses[6] || 0;
    const cumulativeValue = value5 + parsedOptionValue;

    if (currentQuestionId === 5) {
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === 6).text,
          options: chatFlow[language].find((step) => step.id === 6).options,
        },
      ]);
      setCurrentQuestionId(6);
      setWaitingForInput(true);
    } else if (currentQuestionId === 6) {
      const nextStep = cumulativeValue < 2 ? 7 : 10;
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextStep).text,
          options: chatFlow[language].find((step) => step.id === nextStep)
            .options,
        },
      ]);
      setCurrentQuestionId(nextStep);
      setWaitingForInput(nextStep === 6);
      setSelectedOption(optionText);
    } else if ([10, 11, 12, 13, 14, 15, 16, 17].includes(currentQuestionId)) {
      let nextQuestionId;
      if (currentQuestionId < 17) {
        nextQuestionId = currentQuestionId + 1;
      } else {
        const questionIdsToCheck = [10, 11, 12, 13, 14, 15, 16, 17];
        const proceedTo19 = questionIdsToCheck.some(
          (id) => updatedResponses[id] > 0
        );
        nextQuestionId = proceedTo19 < 5 ? 7 : 19;
      }

      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextQuestionId)
            .text,
          options: chatFlow[language].find((step) => step.id === nextQuestionId)
            .options,
        },
      ]);
      setCurrentQuestionId(nextQuestionId);
      setWaitingForInput(nextQuestionId === 6);
      setSelectedOption(optionText);
    } else {
      const nextStep = chatFlow[language].find((step) => step.id === nextId);
      const finalChatHistory = [
        ...chatHistory,
        { text: optionText, user: true },
      ];
      if (nextStep)
        finalChatHistory.push({
          text: nextStep.text,
          options: nextStep.options,
        });
      setChatHistory([...finalChatHistory]);
      setCurrentQuestionId(nextId);
      setWaitingForInput(nextId === 6);
      setSelectedOption(optionText);
    }
  };

  const handleTextInputSubmit = () => {
    const userResponse = userTextInput;
    setUserTextInput("");
    const currentIndex = questionSequence.indexOf(currentQuestionId);
    const nextId = currentQuestionId + 1;
    storeChat(nextId, userResponse);

    setChatHistory([...chatHistory, { text: userResponse, user: true }]);

    if (currentIndex !== -1 && currentIndex < questionSequence.length - 1) {
      const nextQuestionId = nextId;
      setCurrentQuestionId(nextQuestionId);

      const nextQuestion = chatFlow[language].find(
        (q) => q.id === nextQuestionId
      );
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    } else {
      setCurrentQuestionId(nextId);
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const handlePlayAndPause = (type, text) => {
    console.log("handlePlayAndPause", type, text);
    console.log(player.isClosed, player);
    if (type === "play") {
      if (isSpeaking && storeSelectedText === text) {
        setSelectedTextToPlay(text);
        player.resume();
      } else {
        handleVoiceCommand(text);
        setSelectedTextToPlay(text);
        setStoreSelectedText(text);

        setIsSpeaking(true);
      }
    } else {
      player.pause();
      setSelectedTextToPlay(null);
    }
  };

  const renderChatHistory = () =>
    chatHistory.map((entry, index) => {
      const isUserMessage = entry.user;

      const renderMessageText = (text) =>
        (Array.isArray(text) ? text : [text]).map((line, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
        ));

      const renderPlayPauseIcon = () =>
        selectedTextToPlay !== entry.text ? (
          <FaRegCirclePlay
            onClick={() => handlePlayAndPause("play", entry.text)}
            className={`icon ${
              selectedTextToPlay && selectedTextToPlay !== entry.text
                ? "disabled"
                : ""
            }`}
            color="#A1A2F7"
          />
        ) : (
          <FaRegCirclePause
            onClick={() => handlePlayAndPause("pause", entry.text)}
            className="icon"
            color="#A1A2F7"
          />
        );

      return (
        <div
          key={index}
          className={`chat-history-container ${isUserMessage ? "user" : "bot"}`}
        >
          <div className={`message-wrapper ${isUserMessage ? "user" : "bot"}`}>
            <div
              className={`chat-bubble ${isUserMessage ? "user" : "bot"}`}
              style={{ fontSize: `${textSize}px` }}
            >
              {renderMessageText(entry.text)}
            </div>
            {isVoiceEnabled && !isUserMessage && (
              <div>{renderPlayPauseIcon()}</div>
            )}
          </div>
        </div>
      );
    });

  const currentQuestion = chatFlow[language].find(
    (q) => q.id === currentQuestionId
  );

  // Load saved settings from local storage on component mount
  useEffect(() => {
    get_chat();
    const savedLang = localStorage.getItem("lang");
    const savedVcover = localStorage.getItem("vcover");
    const savedVc = localStorage.getItem("vc");
    const savedTextsize = localStorage.getItem("size");

    if (savedLang) setLanguage(savedLang);
    if (savedVc) setVoice(savedVc);
    if (savedTextsize) setTextSize(savedTextsize);
    if (savedVcover) setIsVoiceEnabled(isVoiceEnabled === "true"); // Convert string to boolean
  }, []);

  useEffect(() => {
    const { last_session_date, nextId } = chatData;
    const lastSessionDate = new Date(last_session_date);
    const todayDate = new Date(new Date().toLocaleDateString());
    if (lastSessionDate < todayDate) {
      const isPHQComplete =
        nextId < 7 || (10 <= nextId && nextId <= 19) || nextId === 47;
      if (!isPHQComplete) {
        chatFlow[language] = chatFlow[language].map((step) => {
          if (step.id === 7) {
            step.options[0].nextId = nextId;
          }
          return step;
        });
      }
    } else {
      setCurrentQuestionId(nextId);
    }
    fetchChatHistory();
  }, [chatData.messages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <div className="chat-page">
      <div ref={chatContainerRef} className="chat-container">
        {renderChatHistory()}
      </div>
      <div className="chat-footer">
        {currentQuestion ? (
          <>
            {currentQuestion.options && (
              <div className="input-options-container">
                {currentQuestion.options.map((option, idx) => {
                  const isNumeric = /^\d+$/.test(option.text.trim());
                  return (
                    <button
                      key={idx}
                      onClick={() =>
                        handleOptionClick(option.nextId, option.text)
                      }
                      className="options-btn"
                      style={{
                        borderRadius: isNumeric ? "50%" : "20px",
                        padding: isNumeric ? "10px" : "10px 20px",
                        minWidth: isNumeric ? "40px" : "80px",
                      }}
                    >
                      {option.text}
                    </button>
                  );
                })}
              </div>
            )}

            {questionSequence.includes(currentQuestion.id) && (
              <div className="input-container">
                <textarea
                  value={userTextInput}
                  onChange={(e) => setUserTextInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (userTextInput.trim()) handleTextInputSubmit();
                    }
                  }}
                  rows={2}
                  className="user-chat-input"
                />
                <button
                  disabled={!userTextInput.trim()}
                  onClick={handleTextInputSubmit}
                  className="submit-btn"
                  style={{
                    opacity: !userTextInput.trim() ? "0.5" : "1",
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        ) : (
          <button onClick={startChat} className="start-btn">
            Start
          </button>
        )}

        {showChatSettings && (
          <ChatSettings
            handleVoiceChange={handleVoiceChange}
            handleTestSpeaker={handleTestSpeaker}
            handleSaveSettings={handleSaveSettings}
            handleLanguageChange={handleLanguageChange}
            toggleVoiceover={toggleVoiceover}
            handleTextSizeChange={handleTextSizeChange}
            handleCloseChatSettings={toggleSettingsPanel}
            language={language}
            isVoiceEnabled={isVoiceEnabled}
            voice={voice}
            voices={voices}
            textSize={textSize}
            volume={volume}
            handleVolumeChange={handleVolumeChange}
            selectedTextToPlay={selectedTextToPlay}
            speed={speed}
            handleSpeedChange={handleSpeedChange}
            speedOptions={speedOptions}
          />
        )}

        {showSupportPanel && (
          <SupportPanel
            showSupportPanel={showSupportPanel}
            setShowSupportPanel={setShowSupportPanel}
          />
        )}

        {/* <Chat handleVoiceCommand={handleVoiceCommand} triggerVoiceCommand={triggerVoiceCommand}/> */}
      </div>
    </div>
  );
};

export default ChatsPage;
