import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ children, isAuthenticated }) => {
  const { isLoggedIn } = useSelector((state) => state.userInfo.data);
  return isLoggedIn || isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoutes;
