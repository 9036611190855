import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { ChatContext } from "../../../context/chatContext";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 18,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFDB7C",
    ...theme.applyStyles("dark", {
      backgroundColor: "#FFDB7C",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#A1A2F7",
    ...theme.applyStyles("dark", {
      backgroundColor: "#A1A2F7",
    }),
  },
}));

const DashboardHeader = ({ toggleSidebar, closeSideNavbar }) => {
  const {
    showChatMenu,
    setShowChatSettings,
    setShowSupportPanel,
    sessionNumber,
    progress,
  } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    closeSideNavbar();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: "#FFFFFF", width: "100%", zIndex: "1" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0px",
        }}
      >
        <IconButton color="primary" edge="end" onClick={toggleSidebar}>
          <img
            src="/images/LeftMenuHamburger-Purple.svg"
            alt="Menu Icon"
            style={{ width: "40px", height: "40px" }}
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <img
            alt="Nurtur logo"
            src="/images/nurtur_new_logo.svg"
            style={{
              height: "2.5rem",
            }}
          />
        </Box>
        {showChatMenu && (
          <IconButton color="primary" onClick={handleClick}>
            <img
              src="/images/3DotHorizontal-Purple.svg"
              alt="Menu Icon"
              style={{ width: "40px", height: "40px" }}
            />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.04)",
              },
            }}
            onClick={() => {
              setShowChatSettings(true);
              handleClose();
            }}
          >
            <img
              src="/images/ChatSettings-Black.svg"
              alt="Menu Icon"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            Chat Settings
          </MenuItem>
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 0.04)",
              },
            }}
            onClick={() => {
              setShowSupportPanel(true);
              handleClose();
            }}
          >
            <img
              src="/images/Support-Black.svg"
              alt="Menu Icon"
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
            Support
          </MenuItem>
        </Menu>
      </Toolbar>

      {showChatMenu ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "10px",
            padding: "5px 38px",
            gap: "8px",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          <div style={{ flexShrink: 0 }}>Session {sessionNumber ?? "1"}</div>
          <div style={{ flex: 1, margin: "0 8px" }}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </div>
          <div style={{ flexShrink: 0 }}>{Math.round(progress)}%</div>
        </div>
      ) : null}
    </AppBar>
  );
};

export default DashboardHeader;
