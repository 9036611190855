import React from "react";
import { Select } from "antd";
import "./CustomDropdown.css";

const { Option } = Select;

const CustomDropdown = ({ label="", options, placeholder, onChange, value }) => {
  return (
    <div className="dropdown-container">
      <label className="dropdown-label">{label}</label>
      <Select
        className="dropdown-select"
        showSearch
        value={value || undefined} // Ensure placeholder appears when no value is selected
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: "100%" }}
        bordered={false} // Remove default AntD border
        // allowClear // Adds a clear button to reset selection
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomDropdown;
