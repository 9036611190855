import React, { useState } from "react";
import PDPSurveyDescription from "./PDPSurveyDescription";
import ThankYou from "./thankYou";
import PDPSurveyForm from "./PDPSurveyForm";

const PDPSurvey = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <>
      {currentStep === 0 ? (
        <PDPSurveyDescription handleNext={handleNext} />
      ) : currentStep === 1 ? (
        <PDPSurveyForm handleNextStep={handleNext} />
      ) : (
        <ThankYou />
      )}
    </>
  );
};

export default PDPSurvey;
