import React, { useState, useEffect, useRef } from "react";
import chatFlow from "../../utils/Chatflow";
import { useSelector } from "react-redux";
import client from "../../api";
import Urls from "../../api/urls";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
// import emailjs from 'emailjs-com';

//general-inquiry
import TextField from "@mui/material/TextField";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

//chat-settings
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { Slider } from "@mui/material";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons"; // Import Play and Pause icons
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUserInfo } from "../../redux/features/user/userInfoSlice";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { HiDotsHorizontal } from "react-icons/hi";
import { GoHome } from "react-icons/go";
import { PiChat } from "react-icons/pi";
import { FiArrowLeftCircle } from "react-icons/fi";
import { GrCircleQuestion } from "react-icons/gr";
import {
  RiAccountCircleLine,
  RiVoiceprintFill,
  RiSpeedMiniFill,
} from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";
import { Language as LanguageIcon } from "@mui/icons-material";
import MenuIcon from "../../assets/LeftMenuHamburger-Purple.svg";

// const Android12Switch = styled(Switch)(({ theme }) => ({
//   padding: 8,
//   "& .MuiSwitch-track": {
//     borderRadius: 22 / 2,
//     "&::before, &::after": {
//       content: '""',
//       position: "absolute",
//       top: "50%",
//       transform: "translateY(-50%)",
//       width: 16,
//       height: 16,
//     },
//     "&::before": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
//       left: 12,
//     },
//     "&::after": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
//       right: 12,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "none",
//     width: 16,
//     height: 16,
//     margin: 2,
//   },
// }));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  height: "50px !important",
  width: "82px !important", //"75px !important",
  "& .MuiSwitch-track": {
    borderRadius: "20px", //22 / 2,
    border: "1px solid slateblue",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    backgroundColor: theme.palette.grey[400],
    position: "relative",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "16px",
      fontWeight: "400",
      color: theme.palette.getContrastText(theme.palette.primary.main),
      transition: "opacity 0.3s",
    },
    "&::before": {
      content: '"On"',
      left: 12,
      opacity: 0, // Hide "ON" by default (unchecked state)
    },
    "&::after": {
      content: '"Off"',
      right: 12,
      opacity: 1, // Show "OFF" by default (unchecked state)
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 20, //18,
    height: 20, //18,
    margin: 10, //"6px 7px",
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    transition: "transform 0.3s",
    transform: "translateX(5px)", // Default position for unchecked state
    "&.Mui-checked": {
      transform: "translateX(30px)", // Position for checked state
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        "&::after": {
          opacity: 0, // Hide "OFF" when the switch is checked
        },
        "&::before": {
          opacity: 1, // Show "ON" when the switch is checked
        },
      },
    },
    "&:not(.Mui-checked) + .MuiSwitch-track": {
      "&::before": {
        opacity: 0, // Hide "ON" when the switch is unchecked
      },
      "&::after": {
        opacity: 1, // Show "OFF" when the switch is unchecked
      },
    },
  },
}));

const timeLogs = {};

const getCurrentTime = () => new Date().getTime();

const logTime = (questionId) => {
  timeLogs[questionId] = getCurrentTime();
};

const getTimeDifference = (id1, id2) => {
  if (timeLogs[id1] && timeLogs[id2]) {
    return (timeLogs[id2] - timeLogs[id1]) / (1000 * 60 * 60);
  }
  return 0;
};

const handleNextQuestion = (currentId) => {
  const timeDiff = getTimeDifference(5, 48);
  if (timeDiff <= 24) {
    return 62;
  }
  return (
    chatFlow.en.find((q) => q.id === currentId)?.options[0]?.nextId || null
  );
};

const sessionRanges = {
  1: { start: 1, end: 48 },
  2: { start: 49, end: 85 },
  3: { start: 86, end: 114 },
  4: { start: 115, end: 150 },
  5: { start: 151, end: 191 },
  6: { start: 192, end: 235 },
  7: { start: 236, end: 261 },
  8: { start: 262, end: 282 },
};

const getSession = (questionId) => {
  return Object.keys(sessionRanges).find((session) => {
    const { start, end } = sessionRanges[session];
    return questionId >= start && questionId <= end;
  });
};

const getProgress = (questionId) => {
  const session = getSession(questionId);
  if (session) {
    const { start, end } = sessionRanges[session];
    return ((questionId - start) / (end - start)) * 100;
  }
  return 0;
};

const Chatbot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const voices = {
    "en-US": [
      { name: "en-US-JennyNeural", label: "Jenny", display: "English (US)" },
    ],
    "es-MX": [
      {
        name: "es-MX-DaliaNeural",
        label: "Dalia",
        display: "Spanish (Mexico)",
      },
    ],
    "es-US": [
      { name: "es-US-AlonsoNeural", label: "Alonso", display: "Spanish (US)" },
    ],
    "zh-TW": [
      {
        name: "zh-TW-HsiaoChenNeural",
        label: "Hsiao Chen",
        display: "Taiwanese (TW)",
      },
    ],
  };

  const speedOptions = [
    { label: "0.25x", value: 0.25 },
    { label: "0.5x", value: 0.5 },
    { label: "0.75x", value: 0.75 },
    { label: "1x", value: 1 },
    { label: "1.25x", value: 1.25 },
    { label: "1.5x", value: 1.5 },
    { label: "1.75x", value: 1.75 },
    { label: "2x", value: 2 },
  ];

  const [showTextInput, setShowTextInput] = useState(false);
  const [userTextInput, setUserTextInput] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [voice, setVoice] = useState("en-US-JennyNeural");
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [language, setLanguage] = useState("en-US");
  const [userResponses, setUserResponses] = useState({});
  const [userInput, setUserInput] = useState("");
  const [waitingForInput, setWaitingForInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  // const [volume, setVolume] = useState(1);
  const [volume, setVolume] = useState(50);
  // const [audioContext, setAudioContext] = useState(null);
  // const [gainNode, setGainNode] = useState(null);
  // const gainNodeRef = useRef(null);

  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const synthesizerRef = useRef(null);

  const [speed, setSpeed] = useState(1);
  const [textSize, setTextSize] = useState(14);
  const [isTextInputVisible, setIsTextInputVisible] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [responses, setResponses] = useState({});
  const [showVoiceDropdown, setShowVoiceDropdown] = useState(false);
  const [showSpeedDropdown, setShowSpeedDropdown] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [player, setPlayer] = useState(null);
  const [synthesizer, setSynthesizer] = useState(null);
  const [showSupportForm, setShowSupportForm] = useState(false);
  const [supportForm, setSupportForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [showThreeDotMenu, setShowThreeDotMenu] = useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [showSupportPanel, setShowSupportPanel] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [playingIndex, setPlayingIndex] = useState(null);
  const [playbackEnded, setPlaybackEnded] = useState(false);
  const [selectedTextToPlay, setSelectedTextToPlay] = useState(null);

  const buttonRef = useRef(null);
  // const handleVolumeChange = (e) => setVolume(e.target.value);
  // const handleTextSizeChange = (e) => setTextSize(e.target.value);
  const handleTextSizeChange = (event, newValue) => {
    setTextSize(newValue);
  };

  // Load saved settings from local storage on component mount
  useEffect(() => {
    const savedLang = localStorage.getItem("lang");
    const savedVcover = localStorage.getItem("vcover");
    const savedVc = localStorage.getItem("vc");
    const savedTextsize = localStorage.getItem("size");

    console.log("savedLang", savedLang);

    if (savedLang) setLanguage(savedLang);
    if (savedVc) setVoice(savedVc);
    if (savedTextsize) setTextSize(savedTextsize);
    if (savedVcover) setIsVoiceEnabled(isVoiceEnabled === "true"); // Convert string to boolean
  }, []);

  const handleSaveSettings = () => {
    localStorage.setItem("lang", language);
    localStorage.setItem("vc", voice);
    localStorage.setItem("size", textSize);
    localStorage.setItem("vcover", isVoiceEnabled.toString());
    toggleSettingsPanel();
    // alert('Settings saved successfully!');
  };

  const openSupportForm = () => setShowSupportForm(true);
  const closeSupportForm = () => setShowSupportForm(false);

  const [formData, setFormData] = useState({
    name: "",
    contactReason: "",
    email: "",
    phone: "",
    description: "",
    media: null,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, media: e.target.files[0] });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.contactReason)
      errors.contactReason = "Contact reason is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone) errors.phone = "Phone number is required.";
    if (!formData.description) errors.description = "Description is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted:", formData);
      // Add form submission logic here
    }
  };

  useEffect(() => {
    setFormErrors({}); // Clear errors when form is opened
  }, [showSupportForm]);

  const [supportMessageSent, setSupportMessageSent] = useState(false);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const chatWindowRef = useRef(null);
  const chatData = useSelector((state) => state.chat.data);
  const questionSequence = [
    74, 76, 78, 80, 86, 104, 107, 131, 139, 146, 168, 176, 182, 200, 232, 237,
    239, 241, 250, 252, 263, 264,
  ];
  const [progress, setProgress] = useState(0);
  const session = getSession(currentQuestionId);

  //   const handleSaveSettings = () => {
  //   console.log('Settings Saved:', {
  //     language,
  //     isVoiceEnabled,
  //     voice,
  //     volume,
  //     textSize
  //   });
  // };

  const toggleVoiceover = (event) => {
    console.log("event.target.checked", event.target.checked);
    setIsVoiceEnabled(event.target.checked);
    if (!event.target.checked) {
      setSelectedTextToPlay(null);
      setIsSpeaking(false);
      player.pause();
    } else {
      if (chatHistory.length > 0) {
        const lastMessage = chatHistory[chatHistory.length - 1];
        setSelectedTextToPlay(lastMessage.text);
      }
      setIsSpeaking(true);
      player.resume();
    }
  };

  //old
  // const handleVolumeChange = (event, newValue) => {
  //   setVolume(newValue);
  //   if (synthesizer) {
  //     // Set volume using synthesizer properties
  //     synthesizer.properties.setProperty(sdk.PropertyId.SpeechServiceConnection_Synthesizer_Volume, newValue.toString());
  //   }
  // };

  // const handleVolumeChange = (event, newValue) => {
  //   console.log("handleVolumeChange")
  //   console.log("newValue",newValue)
  //   setVolume(newValue);
  //   if (gainNode) {
  //     gainNode.gain.value = newValue / 100; // Dynamically adjust volume
  //     console.log("gainNode.gain.value",gainNode.gain.value)
  //   }
  // };

  //current
  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = newValue / 100; // Dynamically adjust volume
      console.log("Updated volume:", gainNodeRef.current.gain.value);
    }
  };

  const handleTestSpeaker = () => {
    if (isVoiceEnabled) {
      const speechConfig = sdk.SpeechConfig.fromSubscription(
        process.env.REACT_APP_AZURE_KEY,
        process.env.REACT_APP_REGION
      );
      speechConfig.speechSynthesisVoiceName = voice;
      const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
      synthesizer.speakTextAsync(
        "This is a test of the speaker settings.",
        () => {
          synthesizer.close();
        },
        (error) => {
          console.error(error);
          synthesizer.close();
        }
      );
    } else {
      alert("Voiceover is disabled. Please enable it to test the speaker.");
    }
  };

  //old
  useEffect(() => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      process.env.REACT_APP_AZURE_KEY,
      process.env.REACT_APP_REGION
    );
    speechConfig.speechSynthesisVoiceName = voice;
    speechConfig.speechSynthesisRate = speed;

    const player = new sdk.SpeakerAudioDestination();
    setPlayer(player);
    const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    setSynthesizer(synthesizer);
  }, [voice, speed]);

  // useEffect(() => {
  //   const speechConfig = sdk.SpeechConfig.fromSubscription(
  //     process.env.REACT_APP_AZURE_KEY,
  //     process.env.REACT_APP_REGION
  //   );
  //   speechConfig.speechSynthesisVoiceName = voice;
  //   speechConfig.speechSynthesisRate = speed;

  //   // Create an audio context and gain node for volume control
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   const gainNode = audioContext.createGain();
  //   gainNode.gain.value = volume / 100; // Set initial volume

  //   setAudioContext(audioContext);
  //   setGainNode(gainNode);

  //   // Create a pull audio output stream to process audio data
  //   const audioStream = sdk.AudioOutputStream.createPullStream();
  //   // const audioConfig = sdk.AudioConfig.fromStreamOutput(audioStream);
  //   const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
  //   const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

  //   synthesizer.synthesizing = (s, e) => {

  //     const audioData = e.audioData;
  //     const audioBuffer = new Float32Array(audioData.length);
  //     for (let i = 0; i < audioData.length; i++) {
  //       audioBuffer[i] = audioData[i] / 32768.0; // Convert to normalized float
  //     }
  //     const bufferSource = audioContext.createBufferSource();
  //     const audioBufferData = audioContext.createBuffer(1, audioBuffer.length, audioContext.sampleRate);
  //     audioBufferData.getChannelData(0).set(audioBuffer);

  //     bufferSource.buffer = audioBufferData;
  //     bufferSource.connect(gainNode).connect(audioContext.destination);
  //     bufferSource.start();
  //   };

  //   setSynthesizer(synthesizer);
  // }, [voice, speed]);

  // useEffect(() => {
  //   const speechConfig = sdk.SpeechConfig.fromSubscription(
  //     process.env.REACT_APP_AZURE_KEY,
  //     process.env.REACT_APP_REGION
  //   );
  //   speechConfig.speechSynthesisVoiceName = voice;
  //   speechConfig.speechSynthesisRate = speed;

  //   // Create audio context and gain node for volume control
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   const gainNode = audioContext.createGain();
  //   gainNode.gain.value = volume / 100;

  //   // Store in refs
  //   audioContextRef.current = audioContext;
  //   gainNodeRef.current = gainNode;

  //   // Audio config and synthesizer
  //   const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
  //   const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
  //   synthesizerRef.current = synthesizer;

  //   synthesizer.synthesizing = (s, e) => {
  //     const audioData = e.audioData;
  //     const audioBuffer = new Float32Array(audioData.length);
  //     for (let i = 0; i < audioData.length; i++) {
  //       audioBuffer[i] = audioData[i] / 32768.0; // Convert to normalized float
  //     }

  //     const bufferSource = audioContext.createBufferSource();
  //     const audioBufferData = audioContext.createBuffer(1, audioBuffer.length, audioContext.sampleRate);
  //     audioBufferData.getChannelData(0).set(audioBuffer);

  //     bufferSource.buffer = audioBufferData;
  //     bufferSource.connect(gainNode).connect(audioContext.destination);
  //     bufferSource.start();

  //   };

  //   synthesizerRef.current = synthesizer;

  //   return () => {
  //     synthesizer.close();
  //     audioContext.close();
  //   };
  // }, [voice, speed,volume]);

  useEffect(() => {
    const { last_session_date, nextId } = chatData;
    const lastSessionDate = new Date(last_session_date);
    const todayDate = new Date(new Date().toLocaleDateString());
    // updateChatHistory();
    updateChatHistoryNew();
    if (lastSessionDate < todayDate) {
      const isPHQComplete =
        nextId < 7 || (10 <= nextId && nextId <= 19) || nextId === 47;
      if (!isPHQComplete) {
        chatFlow[language] = chatFlow[language].map((step) => {
          if (step.id === 7) {
            step.options[0].nextId = nextId;
          }
          return step;
        });
      }
    } else {
      setCurrentQuestionId(nextId);
    }
  }, [chatData.messages]);

  // const updateChatHistory = () => {
  //   const { messages, nextId } = chatData;
  //   console.log("chatData", chatData);
  //   const chatIds = messages?.map((message) => message.system);
  //   chatIds.push(nextId);
  //   const history = chatFlow[language].filter((q) => chatIds.includes(q.id));
  //   console.log("history", history);
  //   const historyMap = new Map(history.map((item) => [item.id, item]));
  //   const orderedHistory = chatIds
  //     .map((id) => historyMap.get(id))
  //     .filter((item) => item !== undefined);
  //   const chatMessages = orderedHistory.map((message) => ({
  //     text: message.text,
  //     options: message.options,
  //   }));
  //   console.log("chat messages", chatMessages);
  //   setChatHistory(chatMessages);
  //   // console.log("chatMessages", chatMessages);
  //   if (
  //     chatMessages.length > 0 &&
  //     chatMessages[chatMessages.length - 1].options &&
  //     chatMessages[chatMessages.length - 1].options.length > 0
  //   )
  //     setProgress(
  //       getProgress(chatMessages[chatMessages.length - 1].options[0].nextId)
  //     ); // Update progress
  // };
  
  // const updateChatHistoryNew = () => {
  //   const { messages, nextId } = chatData;
  //   const orderedHistory = [];
  //   messages.forEach((message) => {
  //     const systemChat = chatFlow[language].find((flow) => flow.id === message.system);
  //     const userReply = {text: message.user, user: true};
  //     orderedHistory.push(systemChat);
  //     orderedHistory.push(userReply);
  //   });
  //   if(nextId) {
  //     const nextIdChat = chatFlow[language].find((flow) => flow.id === nextId);
  //     orderedHistory.push(nextIdChat);
  //   }
  //   setChatHistory(orderedHistory);

  //   // Update progress if last message has options
  //   if (
  //     orderedHistory.length > 0 &&
  //     orderedHistory[orderedHistory.length - 1].options &&
  //     orderedHistory[orderedHistory.length - 1].options.length > 0
  //   ) {
  //     setProgress(
  //       getProgress(orderedHistory[orderedHistory.length - 1].options[0].nextId)
  //     );
  //   }

  // }

  const updateChatHistoryNew = () => {
    const { messages, nextId } = chatData;
    const orderedHistory = [];
    messages.forEach((message) => {
      const systemChat = chatFlow[language].find(
        (flow) => flow.id === message.system
      );
      const userReply = { text: message.user, user: true };
      orderedHistory.push(systemChat);
      orderedHistory.push(userReply);
    });
    if (nextId) {
      const nextIdChat = chatFlow[language].find((flow) => flow.id === nextId);
      orderedHistory.push(nextIdChat);
    }
    setChatHistory(orderedHistory);

    // Update progress if last message has options
    if (nextId) {
      setProgress(getProgress(nextId));
    } else if (
      orderedHistory.length > 0 &&
      orderedHistory[orderedHistory.length - 1].options &&
      orderedHistory[orderedHistory.length - 1].options.length > 0
    ) {
      setProgress(
        getProgress(orderedHistory[orderedHistory.length - 1].options[0].nextId)
      );
    }
  };

  const updateChatHistory = () => {
    const { messages, nextId } = chatData;
    console.log("chatData", chatData);

    // Extract chat system message IDs and ensure nextId is included
    const chatIds = messages?.map((message) => message.system);
    console.log("chatIds", chatIds);

    if (nextId) chatIds.push(nextId);

    // Retrieve corresponding history messages based on chatIds
    const history = chatFlow[language].filter((q) => chatIds.includes(q.id));
    console.log("history", history);

    // Create a map for quick access to system messages
    const historyMap = new Map(history.map((item) => [item.id, item]));
    console.log("historyMap", historyMap);

    // Construct ordered chat history with interleaved user messages
    const orderedHistory = [];
    let stopProcessing = false;

    for (const id of chatIds) {
      if (stopProcessing) break;

      const systemMessage = historyMap.get(id);
      const userMessage = messages.find((msg) => msg.system === id);

      if (systemMessage) {
        orderedHistory.push(systemMessage);
      }

      if (userMessage) {
        orderedHistory.push({ text: userMessage.user, user: true });
      }

      // Stop further additions after nextId message is processed
      if (id === nextId) {
        stopProcessing = true;
      }
    }

    console.log("orderedHistory", orderedHistory);
    orderedHistory.pop();

    // Format chat messages consistently
    const chatMessages = orderedHistory.map((message) =>
      message.text
        ? {
            id: message.id || null,
            text: message.text,
            options: message.options || undefined,
            user: message.user || false,
          }
        : message
    );

    console.log("chat messages", chatMessages);
    setChatHistory(chatMessages);

    // Update progress if last message has options
    if (
      chatMessages.length > 0 &&
      chatMessages[chatMessages.length - 1].options &&
      chatMessages[chatMessages.length - 1].options.length > 0
    ) {
      setProgress(
        getProgress(chatMessages[chatMessages.length - 1].options[0].nextId)
      );
    }
  };

  // Functions to toggle panels and menus
  const toggleThreeDotMenu = () => {
    setShowThreeDotMenu((prev) => !prev);
    setShowHamburgerMenu(false);
  };
  const toggleSettingsPanel = () => {
    setShowSettingsPanel((prev) => !prev);
    setShowSupportPanel(false);
  };
  const toggleSupportPanel = () => {
    setShowSupportPanel((prev) => !prev);
    setShowSettingsPanel(false);
  };
  const toggleHamburgerMenu = () => {
    setShowHamburgerMenu((prev) => !prev);
    setShowSettingsPanel(false);
    setShowSupportPanel(false);
    setShowThreeDotMenu(false);
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("localId");
    dispatch(addUserInfo({ isLoggedIn: false }));
    navigate("/login");
  };

  const handleTextInputSubmit = () => {
    console.log("User input:", userTextInput);
    const userResponse = userTextInput;
    setUserTextInput("");
    setShowTextInput(false);
    const currentIndex = questionSequence.indexOf(currentQuestionId);
    const nextId = currentQuestionId + 1;
    storeChat(nextId, userResponse);

    setChatHistory([...chatHistory, { text: userResponse, user: true }]);
    setProgress(getProgress(nextId)); // Update progress

    if (currentIndex !== -1 && currentIndex < questionSequence.length - 1) {
      const nextQuestionId = nextId;
      setCurrentQuestionId(nextQuestionId);

      const nextQuestion = chatFlow[language].find(
        (q) => q.id === nextQuestionId
      );
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    } else {
      setCurrentQuestionId(nextId);
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const handleAnswer = (questionId, answer) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: parseInt(answer),
    }));
    let nextId = getNextQuestionId(questionId, answer);
    setCurrentQuestionId(nextId);
  };

  const getNextQuestionId = (questionId, answer) => {
    const { nextId } = chatData;
    if (questionId === 5 || questionId === 6) {
      const response5 = responses[5];
      const response6 = responses[6];
      if (response5 !== undefined && response6 !== undefined) {
        const cumulativeValue = response5 + response6;
        return cumulativeValue < 2 ? 7 : 10;
      }
    }
    if (
      questionId === 10 ||
      questionId === 11 ||
      questionId === 12 ||
      questionId === 13 ||
      questionId === 14 ||
      questionId === 15 ||
      questionId === 16 ||
      questionId === 17
    ) {
      const response10 = responses[10];
      const response11 = responses[11];
      const response12 = responses[12];
      const response13 = responses[13];
      const response14 = responses[14];
      const response15 = responses[15];
      const response16 = responses[16];
      const response17 = responses[17];

      if (
        response10 !== undefined &&
        response11 !== undefined &&
        response12 !== undefined &&
        response13 !== undefined &&
        response14 !== undefined &&
        response15 !== undefined &&
        response16 !== undefined &&
        response17 !== undefined
      ) {
        const cumulativeValuephq8 =
          response10 +
          response11 +
          response12 +
          response13 +
          response14 +
          response15 +
          response16 +
          response17;
        return cumulativeValuephq8 < 1 ? 20 : 7;
      }
    }
    const questionSequence = [
      74, 76, 78, 80, 86, 104, 107, 131, 139, 146, 168, 176, 182, 200, 232, 237,
      240, 242, 251, 254, 264, 265,
    ];

    const currentIndex = questionSequence.indexOf(currentQuestion.id);
    if (currentIndex !== -1 && currentIndex < questionSequence.length - 1) {
      return questionSequence[currentIndex + 1];
    }
    return (
      chatFlow.en
        .find((q) => q.id === questionId)
        ?.options.find((option) => option.text === answer)?.nextId || questionId
    );
  };

  useEffect(() => {
    if (chatHistory.length > 0 && isVoiceEnabled) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      console.log("lastMessage+++++++++", lastMessage);
      // setPlayingIndex(chatHistory.length - 1)
      readOutLoud(lastMessage.text, volume);
    }
  }, [chatHistory, isVoiceEnabled]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleUserResponse = (questionId, response) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: response,
    }));
  };
  function calculateVolume(a1, a0) {
    return 20 * Math.log10(a1 / a0);
  }

  const estimateSpeechDuration = (text, speed = "1.0") => {
    const wordsPerMinute = 150 * parseFloat(speed); // Adjust WPM based on speed
    const words = text.split(" ").length;
    const minutes = words / wordsPerMinute;
    return minutes * 60 * 1000; // Convert to milliseconds
  };

  //old
  const readOutLoud = async (texts, volume) => {
    const textArray = Array.isArray(texts) ? texts : [texts];
    const fullText = textArray.join(" ");
    setIsSpeaking(true);
    setPlaybackEnded(false);

    const ssml = `
    <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
      <voice name="${voice}">
        <prosody rate="${speed}" volume="${volume}"
         >
          ${fullText}
        </prosody>
      </voice>
    </speak>`;

    synthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesized successfully.");
          const estimatedDuration = estimateSpeechDuration(fullText, speed);
          console.log(`Estimated duration: ${estimatedDuration}ms`);

          // Set a timeout to update the state after playback should finish
          setTimeout(() => {
            console.log("Playback has ended.");
            setPlaybackEnded(true);
            setPlayingIndex(null);
            setIsSpeaking(false); // Update state to indicate playback ended
            setSelectedTextToPlay(null);
          }, estimatedDuration);
        } else {
          console.error(`Speech synthesis failed: ${result.errorDetails}`);
        }
      },
      (error) => {
        console.error(`Error: ${error}`);
        setIsSpeaking(false);
        synthesizer.close();
      }
    );
    synthesizer.events?.onPlaybackEnd(() => {
      console.log("Playback has ended.");
      // setIsSpeaking(false); // Set speaking state to false when playback ends
    });
  };

  // const readOutLoud = async (text) => {
  //   if (!synthesizerRef.current) {
  //     console.error("Synthesizer is not initialized.");
  //     return;
  //   }

  //   const ssml = `
  //   <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
  //     <voice name="${voice}">
  //       <prosody rate="${speed}">
  //         ${text}
  //       </prosody>
  //     </voice>
  //   </speak>`;

  //   synthesizerRef.current.speakSsmlAsync(
  //     ssml,
  //     (result) => {
  //       if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
  //         console.log("Speech synthesized successfully.");
  //       } else {
  //         console.error(`Speech synthesis failed: ${result.errorDetails}`);
  //       }
  //     },
  //     (error) => {
  //       console.error(`Error: ${error}`);
  //     }
  //   );
  // };

  const storeChat = async (nextId, optionText) => {
    await client.post(Urls.UPDATE_CHAT, {
      email: localStorage.getItem("email"),
      message: { system: currentQuestionId, user: optionText },
      last_session_date: new Date().toLocaleDateString(),
      nextId,
    });
  };

  const handleOptionClick = (nextId, optionText) => {
    storeChat(nextId, optionText);
    const parsedOptionValue = parseInt(optionText, 10);
    handleUserResponse(currentQuestionId, parsedOptionValue);

    setChatHistory([...chatHistory, { text: optionText, user: true }]);

    const updatedResponses = {
      ...userResponses,
      [currentQuestionId]: parsedOptionValue,
    };
    setUserResponses(updatedResponses);

    setProgress(getProgress(nextId)); // Update progress

    const value5 = updatedResponses[5] || 0;
    const value6 = updatedResponses[6] || 0;
    const cumulativeValue = value5 + parsedOptionValue;

    if (currentQuestionId === 5) {
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === 6).text,
          options: chatFlow[language].find((step) => step.id === 6).options,
        },
      ]);
      setCurrentQuestionId(6);
      setWaitingForInput(true);
    } else if (currentQuestionId === 6) {
      const nextStep = cumulativeValue < 2 ? 7 : 10;
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextStep).text,
          options: chatFlow[language].find((step) => step.id === nextStep)
            .options,
        },
      ]);
      setCurrentQuestionId(nextStep);
      setWaitingForInput(nextStep === 6);
      setSelectedOption(optionText);
    } else if ([10, 11, 12, 13, 14, 15, 16, 17].includes(currentQuestionId)) {
      let nextQuestionId;
      if (currentQuestionId < 17) {
        nextQuestionId = currentQuestionId + 1;
      } else {
        const questionIdsToCheck = [10, 11, 12, 13, 14, 15, 16, 17];
        const proceedTo19 = questionIdsToCheck.some(
          (id) => updatedResponses[id] > 0
        );
        nextQuestionId = proceedTo19 ? 19 : 7;
      }

      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextQuestionId)
            .text,
          options: chatFlow[language].find((step) => step.id === nextQuestionId)
            .options,
        },
      ]);
      setCurrentQuestionId(nextQuestionId);
      setWaitingForInput(nextQuestionId === 6);
      setSelectedOption(optionText);
    } else {
      const nextStep = chatFlow[language].find((step) => step.id === nextId);
      const finalChatHistory = [
        ...chatHistory,
        { text: optionText, user: true },
        // { text: nextStep.text, options: nextStep.options },
      ];
      if (nextStep)
        finalChatHistory.push({
          text: nextStep.text,
          options: nextStep.options,
        });
      // setChatHistory([
      //   ...chatHistory,
      //   { text: optionText, user: true },
      //   { text: nextStep.text, options: nextStep.options },
      // ]);
      setChatHistory([...finalChatHistory]);
      setCurrentQuestionId(nextId);
      setWaitingForInput(nextId === 6);
      setSelectedOption(optionText);
    }
  };

  const startChat = () => {
    const firstStep = chatFlow[language].find((step) => step.id === 1);
    setCurrentQuestionId(1);
    setChatHistory([
      ...chatHistory,
      { text: firstStep.text, options: firstStep.options },
    ]);
  };

  const handleUserInput = () => {
    if (userInput.trim()) {
      handleUserResponse(currentQuestionId, userInput);
      setChatHistory([...chatHistory, { text: userInput, options: [] }]);
      setUserInput("");
      setWaitingForInput(false);
      setIsTextInputVisible(false);
      const nextId = currentQuestionId + 1;
      setCurrentQuestionId(nextId);
      setProgress(getProgress(nextId)); // Update progress
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const handleLanguageChange = async (e) => {
    setLanguage(e.target.value);
    setVoice(voices[e.target.value][0].name);
    setShowLanguageDropdown(false);
  };

  const handleVoiceChange = async (e) => {
    setVoice(e.target.value);
    setShowVoiceDropdown(false);
  };

  const handlePlayAndPause = (type, text) => {
    console.log(
      "player test ",
      player,
      player.isClosed,
      synthesizerRef.current
    );
    if (type === "play") {
      if (playbackEnded || (player.isClosed && !synthesizerRef.current)) {
        readOutLoud(text, volume);
      } else {
        player.resume(
          () => {
            console.log("Resuming speech");
          },
          (error) => console.log("Error playing", error)
        );
      }
      setSelectedTextToPlay(text);
    } else {
      player.pause();
      setSelectedTextToPlay(null);
    }
  };

  //old
  const togglePlayPause = () => {
    console.log("togglePlayPause");
    console.log("isSpeaking", isSpeaking);
    if (isSpeaking) {
      player.pause();
      setIsSpeaking(false);
    } else if (isVoiceEnabled && (player.isClosed || !synthesizerRef.current)) {
      if (chatHistory.length > 0) {
        const lastMessage = chatHistory[chatHistory.length - 1];
        readOutLoud(lastMessage.text, volume);
      }
    } else if (!isSpeaking && isVoiceEnabled) {
      player.resume(
        () => {
          console.log("Resuming speech");
          setIsSpeaking(true);
        },
        (error) => console.log("Error playing", error)
      );
    }
  };

  const toggleListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  const handleResetTranscript = () => {
    resetTranscript();
  };

  const handleSupportFormSubmit = (e) => {
    e.preventDefault();
    setSupportMessageSent(true);

    setTimeout(() => {
      setSupportMessageSent(false);
      setShowSupportForm(false);
    }, 5000);

    // Send support message to the provided email
    // You can use your backend service to handle this
  };

  const handleDisabled = (ind) => {
    console.log("handleDisabled-ind", ind);
    console.log("playingIndex", playingIndex);
    if (playbackEnded) {
      return false;
    }
    if (playbackEnded == false && playingIndex == ind) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("chatHistory", chatHistory);
  }, [chatHistory]);

  const currentQuestion = chatFlow[language].find(
    (q) => q.id === currentQuestionId
  );
  const renderChatHistory = () => {
    return chatHistory.map((entry, index) => (
      <div
        key={index}
        style={{
          marginBottom: "20px",
          display: "flex",
          position: "relative",
          justifyContent: entry.user ? "flex-end" : "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: entry.user ? "flex-end" : "space-between",
            width: "100%",
            alignItems: "flex-end",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          {/* {!entry.user && (
          <img
            src="https://drive.google.com/thumbnail?id=1i-IeMQwo6SuFbpXJMm36kRYYPlpXiPgZ&sz=w1000"
            alt="Profile"
            style={{
              width: '42px',
              height: '42px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
          />
        )} */}
          <div
            style={{
              background: entry.user ? "#E5E6FF" : "#ffffff",
              border: "1px solid #ddd",
              borderRadius: "18px",
              padding: "15px",
              fontFamily: "Poppins, sans-serif",
              // fontSize: '14px', // Smaller font size
              fontSize: `${textSize}px`,
              lineHeight: "24px",
              maxWidth: "80%", // Limit max width to prevent overly large blocks
              minWidth: "80%",
              display: "inline-block",
              wordWrap: "break-word", // Ensure text wraps properly
              boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {(Array.isArray(entry.text) ? entry.text : [entry.text]).map(
              (line, i) => (
                <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
              )
            )}
            {isVoiceEnabled && !entry.user && (
              <>
                {/* <img
                  src="https://drive.google.com/thumbnail?id=1LvMyD0PAujX1pQwdLMHuRYzzuqfQNMO6&sz=w100"
                  alt="Replay"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => readOutLoud(entry.text, volume)}
                /> */}

                {/* <button 
             onClick={togglePlayPause} 
             style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <FontAwesomeIcon
            icon={isSpeaking ? faPause : faPlay}  // Toggle between Play and Pause icon
            style={{ fontSize: '24px', color: '#7B7FFF', position: 'absolute',
              bottom: '10px',
              right: '40px',
              cursor: 'pointer', }}
          />
        </button> */}

                {/* <button 
            // disabled={playbackEnded==true? false :(playbackEnded==false && playingIndex==index?true :false)}
            disabled={handleDisabled(index)}
            // disabled={!(playbackEnded === false && playingIndex === index)} 
            // disabled={true}
            onClick={() => {setPlayingIndex(index);togglePlayPause();}} 
             style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <FontAwesomeIcon
            icon={isSpeaking ? faPause : faPlay}  // Toggle between Play and Pause icon
            style={{ fontSize: '24px', color: '#7B7FFF', position: 'absolute',
              bottom: '10px',
              right: '40px',
              cursor: 'pointer', }}
          />
        </button> */}
              </>
            )}
          </div>
          {isVoiceEnabled && !entry.user && (
            <div>
              {selectedTextToPlay !== entry.text ? (
                <FaRegCirclePlay
                  onClick={() => handlePlayAndPause("play", entry.text)}
                  style={{
                    opacity:
                      selectedTextToPlay && selectedTextToPlay !== entry.text
                        ? "0.5"
                        : "1",
                    pointerEvents:
                      selectedTextToPlay && selectedTextToPlay !== entry.text
                        ? "none"
                        : "auto",
                    cursor:
                      selectedTextToPlay && selectedTextToPlay !== entry.text
                        ? "default"
                        : "pointer",
                  }}
                  color="#A1A2F7"
                />
              ) : (
                <FaRegCirclePause
                  onClick={() => handlePlayAndPause("pause", entry.text)}
                  color="#A1A2F7"
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="chatbot-box">
      <div
        style={{
          // border: "1px solid #E3E3FB",
          // boxShadow: "0 4px 8px -2px rgba(0, 0, 0, 0.2)",
          // width: "100%",
          // padding: "0 20px",
          position: "relative", // Ensure the element can properly position the shadow
          borderBottom: "1px solid #E3E3FB",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Focused shadow for bottom
          backgroundColor: "#FFF", // Ensure a contrasting background
          zIndex: 1, // Ensure it's above other elements
          width: "100%",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0px",
            backgroundColor: "#FFFFFF",
            width: "100%", // Ensure it takes up the full width
          }}
        >
          {/* Hamburger Menu */}
          <button
            onClick={toggleHamburgerMenu}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginLeft: "0", // Remove extra margin
            }}
          >
            {/* <span style={{ fontSize: "34px", color: "#7B7FFF" }}>&#9776;</span> */}
            <img src={MenuIcon} alt="Menu Icon" />
          </button>

          {/* Logo (Center) */}
          <div style={{ flexGrow: 1, textAlign: "center" }}>
            <img
              src="https://i0.wp.com/nurturcare.com/wp-content/uploads/2024/08/Purple_Horiz.png?resize=300%2C109&ssl=1"
              alt="Logo"
              style={{ height: "50px", margin: "0 auto" }}
            />
          </div>

          {/* Three-Dot Menu */}
          <div style={{ position: "relative", display: "inline-block" }}>
            <button
              ref={buttonRef}
              onClick={toggleThreeDotMenu}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "#7B7FFF",
                // padding: '10px',
              }}
            >
              {/* <span style={{ fontSize: '44px' }}>...</span> */}
              <HiDotsHorizontal size="1.5em" />
            </button>
            {showThreeDotMenu && (
              <div
                style={{
                  position: "absolute",
                  top: buttonRef.current?.offsetHeight || 45,
                  right: 0,
                  background: "white",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  minWidth: "150px",
                  padding: "5px 0",
                }}
              >
                <button
                  onClick={() => {
                    toggleSettingsPanel();
                    setShowThreeDotMenu(false);
                    console.log("Settings clicked");
                  }}
                  style={{
                    display: "flex", //"block",
                    width: "100%",
                    padding: "10px",
                    border: "none",
                    background: "none",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                >
                  {/* <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      fontSize: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  /> */}
                  <IoSettingsOutline
                    style={{
                      fontSize: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  Chat Settings
                </button>
                <button
                  onClick={() => {
                    toggleSupportPanel();
                    setShowThreeDotMenu(false);
                    console.log("Support clicked");
                  }}
                  style={{
                    display: "flex", //"block",
                    width: "100%",
                    padding: "10px",
                    border: "none",
                    background: "none",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                >
                  {/* <FontAwesomeIcon
                    icon={faCircleQuestion}
                    style={{
                      fontSize: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  /> */}
                  <GoQuestion
                    style={{
                      fontSize: "20px",
                      color: "black",
                      marginRight: "10px",
                    }}
                  />
                  Support
                </button>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <div style={{ marginRight: "10px", fontWeight: "bold" }}>
            Session {session}
          </div>
          <div
            style={{
              flex: 1,
              height: "18px",
              // backgroundColor: '#E5E6FF',
              backgroundColor: "rgb(255, 207, 79)",
              borderRadius: "10px",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "#A0A8FF",
                // backgroundColor: 'rgb(255, 207, 79)',
                transition: "width 0.5s ease-in-out",
                borderRadius: "10px",
              }}
            ></div>
          </div>
          <div
            style={{ marginLeft: "10px", fontWeight: "bold", color: "#000" }}
          >
            {Math.round(progress)}%
          </div>
        </div>
      </div>

      <div
        ref={chatWindowRef}
        style={{
          width: "100%",
          flex: 1,
          display: "flex",
          // marginTop: "20px",
          // marginBottom: "10px",
          // border: '1px solid #ccc',
          // borderRadius: '8px',
          overflowY: "auto",
          padding: "10px 20px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "10px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          {renderChatHistory()}
        </div>
        {/* <div>play</div> */}
      </div>

      {/* Hamburger Menu Panel */}
      {showHamburgerMenu && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "250px",
            height: "100%",
            backgroundColor: "#FFF", // "#7B7FFF",
            color: "#000",
            zIndex: 1000,
            padding: "20px",
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: "400",
            cursor: "pointer",
            borderLeft: "1px solid #E3E3FB",
            boxShadow: "0 4px 20px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* Logo (Center) */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src="https://i0.wp.com/nurturcare.com/wp-content/uploads/2024/08/Purple_Horiz.png?resize=300%2C109&ssl=1"
              alt="Logo"
              style={{ height: "50px", margin: "0" }}
            />
            <button
              onClick={toggleHamburgerMenu}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {/* <span style={{ fontSize: "34px", color: "#7B7FFF" }}>
                &#9776;
              </span> */}
              <img src={MenuIcon} alt="Menu Icon" />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: "1rem 0 3rem 0.5rem",
            }}
          >
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <GoHome /> <div>Home</div>
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <PiChat /> <div>Chat</div>
              </div>
            </div>
            <div
              style={{
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
                // onClick={handleLogout}
              >
                <RiAccountCircleLine /> <div>Account Details</div>
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
                // onClick={handleLogout}
              >
                <GrCircleQuestion /> <div>Support</div>
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
                onClick={handleLogout}
              >
                <FiArrowLeftCircle /> <div>Log Out</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Three-Dot Menu Options */}
      {/* {showThreeDotMenu && (
        <div style={{ position: 'absolute', top: '50px', right: '10px', background: 'white', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 1000 }}>
          <button
            onClick={toggleSettingsPanel}
            style={{ display: 'block', width: '100%', padding: '10px', border: 'none', background: 'none', textAlign: 'left', cursor: 'pointer' }}
          >
            Settings
          </button>
          <button
            onClick={toggleSupportPanel}
            style={{ display: 'block', width: '100%', padding: '10px', border: 'none', background: 'none', textAlign: 'left', cursor: 'pointer' }}
          >
            Support
          </button>
        </div>
      )} */}

      {/* nruth */}
      {showSettingsPanel && (
        <div
          className="chat-settings-sidebar"
          // style={{
          //   position: 'fixed',
          //   top: 0,
          //   right: 0,
          //   height: '100%',
          //   backgroundColor: '#FFFFFF',
          //   zIndex: 1000,
          //   padding: '20px',
          //   boxShadow: '-2px 0px 10px rgba(0,0,0,0.2)',
          //   width: '350px', // Default width for normal browser window
          //   '@media (max-width: 425px)': { // For mobile (iPhone XR dimensions)
          //     width: '425px',
          //     // width: '415px', // Width for mobile devices
          //   },
          // }}
          // style={{ position: 'fixed', top: 0, right: 0, width: '300px', height: '100%', backgroundColor: '#FFFFFF', zIndex: 1000, padding: '20px', boxShadow: '-2px 0px 10px rgba(0,0,0,0.2)' }}
        >
          <button
            onClick={toggleSettingsPanel}
            style={{
              background: "none",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            <IoMdClose />
          </button>
          <h1
            // style={{ marginBottom: '20px', color: '#7B7FFF' }}
            style={{
              fontSize: "1.5em", // Equivalent to default h2 font size
              // fontWeight: 'bold', // Default h2 weight
              marginBottom: "25px", // Custom margin from your example
              color: "black", // Custom color from your example
              display: "flex", // Equivalent to d-flex
              justifyContent: "center", // Equivalent to justify-content-center
              alignItems: "center", // Vertically centers the text
              textAlign: "center", // Horizontally centers the text (useful for centering text inside the flex container)
            }}
          >
            Chat Settings
          </h1>

          {/* Language Dropdown */}
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <label style={{ display: 'block', marginBottom: '5px', color: '#7B7FFF' }}>Language</label>
      <select value={language} onChange={handleLanguageChange} style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ddd' }}>
        <option value="en-US">English (US)</option>
        <option value="es-MX">Spanish (Mexico)</option>
        <option value="es-US">Spanish (US)</option>
        <option value="zh-TW">Taiwanese (TW)</option>
      </select> */}
            <InputLabel
              id="demo-simple-select-label"
              style={{ flex: "0 0 25%" }}
            >
              Language
            </InputLabel>
            {/* <div style={{ borderRadius: "40px" }}>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={language}
                label="Language"
                onChange={handleLanguageChange}
                className="chat-settings-fields"
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={key}>
                    {voices[key][0].display}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
            <div className="dropdown-div" style={{ bborderRadius: "40px" }}>
              {/* <Select
              value={language}
              label="Language"
              onChange={handleLanguageChange}
              className='chat-settings-fields'
            >
              {Object.keys(voices).map((key) => (
                <MenuItem key={key} value={key}>
                  {voices[key][0].display}
                </MenuItem>
              ))}
            </Select> */}
              <Select
                disabled={selectedTextToPlay !== null}
                value={language}
                onChange={handleLanguageChange}
                className="chat-settings-fields"
                displayEmpty
                renderValue={(selected) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LanguageIcon
                      style={{ marginRight: "8px", color: "white" }}
                    />
                    {selected ? voices[selected][0].display : "Select Language"}
                  </div>
                )}
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={key}>
                    {voices[key][0].display}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Voiceover Toggle */}
          {/* <div
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          > */}
          {/* <InputLabel id="demo-simple-select-label">Voiceover</InputLabel>
    <span>Off</span>
    <Switch
      checked={isVoiceEnabled}
      onChange={toggleVoiceover}
      inputProps={{ 'aria-label': 'controlled' }}
    />
    <span>On</span> */}
          {/* <label style={{ color: 'black' }}>Voiceover</label>
      <label style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
        <input
          type="checkbox"
          checked={isVoiceEnabled}
          onChange={() => setIsVoiceEnabled(!isVoiceEnabled)}
          style={{ display: 'none' }}
        />
        <span style={{ width: '60px', height: '30px', backgroundColor: isVoiceEnabled ? '#7B7FFF' : '#ccc', borderRadius: '20px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{
            position: 'absolute',
            top: '50%',
            left: isVoiceEnabled ? '28px' : '6px',
            width: '16px',
            height: '16px',
            backgroundColor: 'white',
            borderRadius: '50%',
            transition: 'left 0.3s',
            transform: 'translateY(-50%)',
          }}></span>
          <span style={{
            position: 'absolute',
            fontSize: '12px',
            color: isVoiceEnabled ? 'black' : 'white',
            fontWeight: 'bold',
            left: isVoiceEnabled ? '10px' : '30px'
          }}>
            {isVoiceEnabled ? 'On' : 'Off'}
          </span>
        </span>
      </label> */}
          {/* <InputLabel
              id="demo-simple-select-label"
              style={{ flex: "0 0 25%" }}
            >
              Voiceover
            </InputLabel>
            <FormControlLabel
              //  label="Voiceover"
              control={
                <Android12Switch
                  checked={isVoiceEnabled}
                  onChange={toggleVoiceover}
                  inputProps={{ "aria-label": "Voiceover switch" }}
                  className="chat-settings-fields"
                />
              }
              // label="Voiceover"
            />
          </div> */}

          {/* Voiceover Toggle */}
          <div
            className="setting-item"
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="chatSettings-labels"
              style={{ fontSize: "16px", fontWeight: "500", flex: "0 0 32%" }}
            >
              Voiceover
            </div>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={isVoiceEnabled}
                  onChange={toggleVoiceover}
                  inputProps={{ "aria-label": "Voiceover switch" }}
                  className="chat-settings-fields"
                />
              }
            />
          </div>

          {/* Play/Pause Toggle */}
          {/* <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
    
<InputLabel id="demo-simple-select-label"style={{ flex: '0 0 25%' }}>{isSpeaking==true?'Pause':'Play'}</InputLabel>
<FormControlLabel
      control={
        <Android12Switch 
          checked={isVoiceEnabled} 
          onChange={togglePlayPause} 
          inputProps={{ 'aria-label': 'Voiceover switch' }} 
        />
      }
    />
    </div> */}

          {/* Voice Dropdown */}
          <div
            style={{
              marginBottom: "25px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <label style={{ color: 'black' }}>Voice</label>
      <div style={{ position: 'relative', width: '60%' }}>
        <select value={voice} onChange={handleVoiceChange} style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ddd', backgroundColor: '#7B7FFF', color: 'white', appearance: 'none', paddingLeft: '30px' }}>
          <option value="en-US-JennyNeural">Jenny</option>
          <option value="es-MX-DaliaNeural">Dalia</option>
          <option value="es-US-AlonsoNeural">Alonso</option>
          <option value="zh-TW-HsiaoChenNeural">Hsiao Chen</option>
        </select>
        <img
          src="https://drive.google.com/uc?export=view&id=1WAlF8osRoXz1ysi8tbhiKo1fzZTKNud9"
          alt="icon"
          style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}
        />
      </div> */}
            <InputLabel
              id="demo-simple-select-label"
              style={{ flex: "0 0 25%" }}
            >
              Voice
            </InputLabel>
            {/* <div style={{ borderRadius: "40px" }}>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={voice}
                label="Voice"
                onChange={handleVoiceChange}
                sx={{
                  borderRadius: "12px", // Adjust the value as needed
                  backgroundColor: "#f5f5f5", // Optional: set a background color
                }}
                className="chat-settings-fields"
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={voices[key][0].name}>
                    {voices[key][0].label}
                  </MenuItem>
                ))}
              </Select>
            </div> */}

            <div className="dropdown-div" style={{ bborderRadius: "40px" }}>
              <Select
                disabled={selectedTextToPlay !== null}
                value={voice}
                onChange={handleVoiceChange}
                className="chat-settings-fields"
                displayEmpty
                // renderValue={(selected) => {
                //   console.log("selected", selected, voices)
                //   return (
                //   <div style={{ display: "flex", alignItems: "center" }}>
                //     <RiVoiceprintFill
                //       style={{ marginRight: "8px", color: "white" }}
                //     />
                //     {/* {selected ? voices[selected][0].label : "Select Voce"} */}
                //   </div>
                // )}}
                renderValue={(selected) => {
                  // Find the label for the selected value
                  const selectedLabel = Object.values(voices)
                    .flat()
                    .find((voiceObj) => voiceObj.name === selected)?.label;

                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiVoiceprintFill
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      {selectedLabel || "Select Voice"}
                    </div>
                  );
                }}
              >
                {Object.keys(voices).map((key) => (
                  <MenuItem key={key} value={voices[key][0].name}>
                    {voices[key][0].label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Speed */}
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputLabel id="Speed-label" style={{ flex: "0 0 25%" }}>
              Speed
            </InputLabel>
            <div className="dropdown-div" style={{ bborderRadius: "40px" }}>
              <Select
                disabled={selectedTextToPlay !== null}
                value={speed}
                onChange={(e) => {
                  setSpeed(parseFloat(e.target.value));
                  setShowSpeedDropdown(false);
                }}
                className="chat-settings-fields"
                displayEmpty
                renderValue={(selected) => {
                  // Find the label for the selected value
                  const selectedLabel = speedOptions.find(
                    (option) => option.value === selected
                  )?.label;

                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RiSpeedMiniFill
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      {selectedLabel || "Select Speed"}
                    </div>
                  );
                }}
              >
                {speedOptions.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          {/* Volume Slider */}
          {/* <div style={{ marginBottom: '15px'}}>
    <InputLabel id="demo-simple-select-label">Volume</InputLabel>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <VolumeDown />
        <Slider
          aria-label="Volume"
          value={volume}
          onChange={handleVolumeChange}
          min={0}
          max={100}
          style={{ margin: '0 3px', flex: 1 }}
        />
        <VolumeUp />
        </div> */}
          {/* <label style={{ display: 'block', marginBottom: '5px', color: 'black' }}>Volume</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>🔊</span>
        <input type="range" min="0" max="100" value={volume} onChange={handleVolumeChange} style={{ flex: 1 }} />
      </div> */}
          {/* </div> */}

          {/* <div 
    style={{ marginBottom: '15px'}}
    > */}
          {/* <InputLabel id="demo-simple-select-label">Volume</InputLabel> */}
          {/* <div 
    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    style={{ display: 'flex', alignItems: 'center' }}
    >
      <InputLabel id="demo-simple-select-label">Volume</InputLabel>
      <div style={{ display: 'flex', alignItems: 'center', width: '150px', marginLeft:'17px' }}>
    <VolumeDown />
        <Slider
          aria-label="Volume"
          value={volume}
          onChange={(event, newValue) => handleVolumeChange(event, newValue)}
          // onChange={handleVolumeChange}
          min={0}
          max={100}
          style={{ margin: '0 3px', flex: 1 }}
          className='chat-settings-fields'
        />
        <VolumeUp />
        </div>
        </div> */}
          {/* <label style={{ display: 'block', marginBottom: '5px', color: 'black' }}>Volume</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>🔊</span>
        <input type="range" min="0" max="100" value={volume} onChange={handleVolumeChange} style={{ flex: 1 }} />
      </div> */}
          {/* </div> */}

          {/* Test Speaker Button */}
          {/* <button
    onClick={handleTestSpeaker}
    // style={{ width: '100%', padding: '10px', backgroundColor: '#7B7FFF', border: 'none', borderRadius: '5px', cursor: 'pointer', color: '#fff', fontSize: '16px', marginBottom: '15px' }}
    style={{
      width: '100%',
      padding: '10px',
      backgroundColor: 'white',
      border: '2px solid #7B7FFF', // Add border
      borderRadius: '20px',
      cursor: 'pointer',
      color: '#7B7FFF', // Updated text color
      fontSize: '16px',
      marginBottom: '15px',
    }}    >
      Test Speaker
    </button> */}

          {/* Text Size Slider */}
          <div style={{ marginBottom: "20px" }}>
            {/* <label style={{ display: 'block', marginBottom: '5px', color: 'black' }}>Text Size</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>🅰</span>
        <input type="range" min="12" max="24" value={textSize} onChange={handleTextSizeChange} style={{ flex: 1 }} />
        <span style={{ marginLeft: '10px' }}>🅰</span>
      </div> */}
            {/* <InputLabel id="demo-simple-select-label">Text Size</InputLabel> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputLabel id="demo-simple-select-label">Text Size</InputLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "150px",
                  marginLeft: "8px",
                }}
              >
                <span style={{ marginRight: "10px" }}>🅰</span>
                <Slider
                  aria-label="textSize"
                  value={Number(textSize)}
                  onChange={handleTextSizeChange}
                  min={12}
                  max={24}
                  className="chat-settings-fields"
                />
                <span style={{ marginLeft: "10px", fontSize: "larger" }}>
                  🅰
                </span>
              </div>
            </div>
            {/* <p style={{ fontSize: `${textSize}px`, backgroundColor: '#E0E4FF', padding: '10px', borderRadius: '5px', marginTop: '10px' }}>
        This is a sample text of what you will see in the chat.
      </p> */}
          </div>
          <p
            style={{
              fontSize: `${textSize}px`,
              backgroundColor: "#E0E4FF",
              padding: "10px",
              borderRadius: "20px",
              marginTop: "5px",
              marginBottom: "15px",
            }}
          >
            This is a sample text of what you will see in the chat.
          </p>

          {/* Test Speaker Button */}
          {/* <button
    onClick={handleTestSpeaker}
      style={{ width: '100%', padding: '10px', backgroundColor: '#7B7FFF', border: 'none', borderRadius: '5px', cursor: 'pointer', color: '#fff', fontSize: '16px', marginBottom: '15px' }}
    >
      Test Speaker
    </button> */}

          {/* Save Settings Button */}
          <button
            onClick={handleSaveSettings}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#FFC64F",
              border: "none",
              borderRadius: "20px",
              cursor: "pointer",
              color: "black",
              fontSize: "16px",
            }}
          >
            Save Settings
          </button>
        </div>
      )}

      {showSupportPanel && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "300px",
            height: "100%",
            backgroundColor: "#FFFFFF",
            zIndex: 1000,
            padding: "20px",
            boxShadow: "-2px 0px 10px rgba(0,0,0,0.2)",
          }}
        >
          <button
            onClick={toggleSupportPanel}
            style={{
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            X
          </button>
          <h2>Support Center</h2>
          <div style={{ marginBottom: "20px" }}>
            <button
              onClick={openSupportForm}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#E0E4FF",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                color: "#000",
                fontSize: "16px",
              }}
            >
              General Inquiry & Bug Report
            </button>
          </div>
          <div>
            <button
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#E0E4FF",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                color: "#000",
                fontSize: "16px",
              }}
            >
              FAQs
            </button>
          </div>
        </div>
      )}

      {/*old-support-general inquiry*/}
      {/* {showSupportForm && (
        <div style={{ position: 'fixed', top: 0, right: 0, width: '300px', height: '100%', backgroundColor: '#FFFFFF', zIndex: 1000, padding: '20px', boxShadow: '-2px 0px 10px rgba(0,0,0,0.2)' }}>
          <button
            onClick={closeSupportForm}
            style={{ background: 'none', border: 'none', fontSize: '18px', cursor: 'pointer', marginBottom: '20px' }}
          >
            X
          </button>
          <h2>Support Form</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '10px' }}>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
              {formErrors.name && <p style={{ color: 'red', fontSize: '12px' }}>{formErrors.name}</p>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>Contact Reason:</label>
              <input
                type="text"
                name="contactReason"
                value={formData.contactReason}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
              {formErrors.contactReason && <p style={{ color: 'red', fontSize: '12px' }}>{formErrors.contactReason}</p>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
              {formErrors.email && <p style={{ color: 'red', fontSize: '12px' }}>{formErrors.email}</p>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>Phone Number:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
              {formErrors.phone && <p style={{ color: 'red', fontSize: '12px' }}>{formErrors.phone}</p>}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>Media Upload:</label>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              />
              {formErrors.description && <p style={{ color: 'red', fontSize: '12px' }}>{formErrors.description}</p>}
            </div>
            <button
              type="submit"
              style={{ width: '100%', padding: '10px', backgroundColor: '#FFC64F', border: 'none', borderRadius: '5px', cursor: 'pointer', color: '#fff', fontSize: '16px' }}
            >
              Submit
            </button>
          </form>
        </div>
      )} */}

      {showSupportForm && (
        <div
          className="chat-settings-sidebar"
          // style={{ position: 'fixed', top: 0, right: 0, width: '300px', height: '100%', backgroundColor: '#FFFFFF', zIndex: 1000, padding: '20px', boxShadow: '-2px 0px 10px rgba(0,0,0,0.2)' }}
        >
          <button
            onClick={closeSupportForm}
            style={{
              background: "none",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              marginBottom: "20px",
            }}
          >
            X
          </button>
          <h2>General Inquiry / Bug Report</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%", color: "black" }}
              >
                Name*
              </InputLabel>
              <TextField
                // id="outlined-controlled"
                // label="Controlled"
                value={formData.name}
                className="myinp"
                // style={{width:'100%'}}
              />
              {/* <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
              {formErrors.name && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formErrors.name}
                </p>
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%" }}
              >
                Contact Reason*
              </InputLabel>
              {/* <div style={{borderRadius:'40px'}}> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.contactReason}
                label="Choose Contact Reason"
                // className='myinp'
                className="custom-select"
                // onChange={handleLanguageChange}
              ></Select>
              {/* </div> */}
              {/* <label>Contact Reason:</label>
              <input
                type="text"
                name="contactReason"
                value={formData.contactReason}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
              {formErrors.contactReason && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formErrors.contactReason}
                </p>
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%" }}
              >
                Email*
              </InputLabel>
              <TextField value={formData.email} className="myinp" />
              {/* <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
              {formErrors.email && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formErrors.email}
                </p>
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%" }}
              >
                Phone Number
              </InputLabel>
              <TextField value={formData.phone} className="myinp" />
              {/* <label>Phone Number:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
              {formErrors.phone && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formErrors.phone}
                </p>
              )}
            </div>

            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%" }}
              >
                Description*
              </InputLabel>
              <TextField
                id="outlined-multiline-static"
                // label="description"
                multiline
                rows={4}
                className="myinp"
                // defaultValue="Default Value"
              />
              {/* <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
              {formErrors.description && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formErrors.description}
                </p>
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ flex: "0 0 25%" }}
              >
                Upload screenshot or recording
              </InputLabel>
              <div className="dashed-border-box">
                <button>
                  <FontAwesomeIcon icon={faUpload} /> Select file to upload
                </button>
              </div>
              {/* <label>Media Upload:</label>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ width: '100%', padding: '5px', marginBottom: '5px' }}
              /> */}
            </div>
            <button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#FFC64F",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                color: "#fff",
                fontSize: "16px",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      )}

      {currentQuestion ? (
        <>
          {currentQuestion.options && (
            <div
              style={{
                // display: "flex",
                // flexWrap: "wrap",
                // gap: "10px",
                // width: "100%",
                // // marginTop: "10px",
                // justifyContent: "center",

                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                width: "100%",
                justifyContent: "center",
                padding: "10px 0",
                marginTop: "10px",
                borderTop: "1px solid #E3E3FB", // Adds the border at the top
                boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.2)", // Top shadow
                backgroundColor: "#FFF", // Ensures shadow is visible
                position: "relative", // Needed for shadow to render properly
              }}
            >
              {currentQuestion.options.map((option, idx) => {
                const isNumeric = /^\d+$/.test(option.text.trim());
                return (
                  <button
                    key={idx}
                    onClick={() =>
                      handleOptionClick(option.nextId, option.text)
                    }
                    style={{
                      borderRadius: isNumeric ? "50%" : "20px",
                      border: "none",
                      padding: isNumeric ? "10px" : "10px 20px",
                      background: "#FFCF4F",
                      color: "#000",
                      cursor: "pointer",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                      minWidth: isNumeric ? "40px" : "80px",
                      height: "40px",
                      textAlign: "center",
                    }}
                  >
                    {option.text}
                  </button>
                );
              })}
              {/* <button 
    onClick={togglePlayPause} 
    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
  > */}
              {/* {isSpeaking ?"Pause":"Play"} */}
              {/* <FontAwesomeIcon
      icon={isSpeaking ? faPause : faPlay}  // Toggle between Play and Pause icon
      style={{ fontSize: '24px', color: '#7B7FFF', cursor: 'pointer' }}
    />
  </button> */}
            </div>
          )}

          {questionSequence.includes(currentQuestion.id) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "100%",
                justifyContent: "center",
                padding: "10px 20px",
                marginTop: "10px",
                borderTop: "1px solid #E3E3FB",
                boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#FFF",
                position: "relative",
              }}
            >
              {/* <input
                type="text"
                value={userTextInput}
                onChange={(e) => setUserTextInput(e.target.value)}
                style={{
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  width: "100%",
                  fontSize: "16px",
                }}
              /> */}
              <textarea
                value={userTextInput}
                onChange={(e) => setUserTextInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (userTextInput.trim()) handleTextInputSubmit();
                  }
                }}
                rows={2}
                style={{
                  padding: "8px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  width: "100%",
                  fontSize: "16px",
                  resize: "none",
                  whiteSpace: "pre-wrap",
                  // outline: "none",
                }}
              />
              <button
                disabled={!userTextInput.trim()}
                onClick={handleTextInputSubmit}
                style={{
                  borderRadius: "20px",
                  border: "none",
                  background: "#FFCF4F",
                  color: "#000",
                  cursor: "pointer",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  minWidth: "80px",
                  height: "40px",
                  textAlign: "center",
                  opacity: !userTextInput.trim() ? "0.5" : "1",
                }}
              >
                Submit
              </button>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            width: "100%",
            justifyContent: "center",
            padding: "10px 0",
            marginTop: "10px",
            borderTop: "1px solid #E3E3FB",
            boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#FFF",
            position: "relative",
          }}
        >
          <button
            onClick={startChat}
            style={{
              borderRadius: "20px",
              border: "none",
              padding: "10px 20px",
              background: "#FFCF4F",
              color: "#000",
              cursor: "pointer",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "medium",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              width: "auto",
              textAlign: "center",
            }}
          >
            Start
          </button>
        </div>
      )}

      {/* <div>mic</div> */}
      {/* <button onClick={toggleListening} style={{ fontSize: '24px', padding: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
          {listening ? '🎤 Stop Listening' : '🎙 Start Listening'}
      </button>
      <p>Transcript:{transcript}</p>
      <button onClick={handleResetTranscript} style={{ fontSize: '16px', padding: '8px 12px', background: '#f44336', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' }}>
          Reset Transcript
        </button> */}

      {/* <div>Down ? img/icon</div> */}
      {/* <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 2 }}>
        <button
          onClick={() => setShowSupportForm((prev) => !prev)}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: showSupportForm ? '#7B7FFF' : '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1Fk3VmsdVDkCVZosRUhbGvBAT2RILVel7&sz=w1000"} alt="Support" style={{ width: '30px', height: '30px' }} />
        </button>
        {showSupportForm && (
          <div style={{ position: 'fixed', bottom: '80px', right: '20px', background: '#fff', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 3 }}>
            <h3>Support Form</h3>
            <h5>You can also reach us at support@nurturcare.com</h5>
            <form onSubmit={handleSupportFormSubmit}>
              <div>
                <label>Name:</label>
                <input type="text" value={supportForm.name} onChange={(e) => setSupportForm({ ...supportForm, name: e.target.value })} required />
              </div>
              <div>
                <label>Phone:</label>
                <input type="tel" value={supportForm.phone} onChange={(e) => setSupportForm({ ...supportForm, phone: e.target.value })} required />
              </div>
              <div>
                <label>Email:</label>
                <input type="email" value={supportForm.email} onChange={(e) => setSupportForm({ ...supportForm, email: e.target.value })} required />
              </div>
              <div>
                <label>Message:</label>
                <textarea value={supportForm.message} onChange={(e) => setSupportForm({ ...supportForm, message: e.target.value })} required />
              </div>
              <button type="submit" style={{ marginTop: '10px', padding: '10px', borderRadius: '4px', background: '#7B7FFF', color: '#fff', cursor: 'pointer', width: '100%', fontFamily: 'Helvetica Neue, Arial, sans-serif' }}>
                Submit
              </button>
            </form>
            {supportMessageSent && <p style={{ marginTop: '10px', color: 'green' }}>Support message has been sent. The Nurtur team will get back to you shortly.</p>}
          </div>
        )}
      </div> */}

      {/* <div>Down icon settings</div> */}
      {/* <div style={{ position: 'fixed', bottom: '80px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 2 }}>
        <button
          onClick={() => setIsButtonsVisible((prev) => !prev)}
          onMouseEnter={() => setIsButtonsVisible(true)}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: isButtonsVisible ? '#7B7FFF' : '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1ZP7JfXib4IyOigdYiTrHx1mKBEOygCee&sz=w1000"} alt="Settings" style={{ width: '30px', height: '30px' }} />
        </button>
        
        {isButtonsVisible && (
          <div style={{ position: 'fixed', bottom: '140px', right: '20px', display: 'flex', gap: '10px', background: '#fff', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 3 }}>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setIsVoiceEnabled((prev) => !prev);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: isVoiceEnabled ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >vv */}
      {/* <img src={"https://drive.google.com/thumbnail?id=1RGuggUvWKHIt17pkhGKhUKq4svDn4h74&sz=w1000"} alt="Enable Voice" style={{ width: '30px', height: '30px' }} /> */}
      {/* </button>
            <button
              onClick={() => {
                setShowVoiceDropdown(false);
                setShowSpeedDropdown(false);
                setShowLanguageDropdown((prev) => !prev);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: showLanguageDropdown ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1bZ5pXYyWu2DnYpkQYbo6Uq8unzB3kFJY&sz=w1000"} alt="Select Language" style={{ width: '30px', height: '30px' }} />
            </button>
            {showLanguageDropdown && (
              <div style={{ position: 'absolute', bottom: '60px', background: '#FFCF4F', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                <select value={language} onChange={handleLanguageChange}>
                  <option value="en-US">English hiiiii(US)</option>
                  <option value="es-MX">Spanish (Mexico)</option>
                  <option value="es-US">Spanish (US)</option>
                  <option value="zh-TW">Taiwanese (TW)</option>
                </select>
              </div>
            )} */}
      {/* {isVoiceEnabled && (
              <>
                <button
                  onClick={() => {
                    setShowLanguageDropdown(false);
                    setShowSpeedDropdown(false);
                    setShowVoiceDropdown((prev) => !prev);
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: showVoiceDropdown ? '#7B7FFF' : '#FFCF4F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <img src={"https://drive.google.com/thumbnail?id=1AEKIKhs6RffndnEqh7sUKP5nDutOZ9hs&sz=w1000"} alt="Select Voice" style={{ width: '30px', height: '30px' }} />
                </button>
                {showVoiceDropdown && (
                  <div style={{ position: 'absolute', bottom: '60px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                    <select
                      onChange={handleVoiceChange}
                      value={voice ? voice : ''}
                      style={{ padding: '10px', width: '100%' }}
                    >
                      {voices[language].map((v) => (
                        <option key={v.name} value={v.name}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <button
                  onClick={() => {
                    setShowVoiceDropdown(false);
                    setShowLanguageDropdown(false);
                    setShowSpeedDropdown((prev) => !prev)
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: showSpeedDropdown ? '#7B7FFF' : '#FFCF4F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <img src={"https://drive.google.com/thumbnail?id=1zs6tHOt-qfOCvoYBJhbiDi5z2cLy5dsT&sz=w1000"} alt="Adjust Speed" style={{ width: '30px', height: '30px' }} />
                </button>
                {showSpeedDropdown && (
                  <div style={{ position: 'absolute', bottom: '60px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                    <select
                      onChange={(e) => {
                        setSpeed(parseFloat(e.target.value));
                        setShowSpeedDropdown(false);
                      }}
                      value={speed}
                      style={{ padding: '10px', width: '100%' }}
                    >
                      <option value="5">5x</option>
                      <option value="4">4x</option>
                      <option value="3">3x</option>
                      <option value="2">2x</option>
                      <option value="0.5">0.5x</option>
                      <option value="0.7">0.7x</option>
                      <option value="0.8">0.8x</option>
                      <option value="0.9">0.9x</option>
                      <option value="1">1x</option>
                    </select>
                  </div>
                )}
              </>
            )} */}
      {/* <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                togglePlayPause();
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: isSpeaking ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >PP */}
      {/* <img src={"https://drive.google.com/thumbnail?id=1OMK2EFI_9qgQqG1ffdowYYRBYwJDErc6&sz=w1000"} alt="Pause/Play Voice" style={{ width: '30px', height: '30px' }} /> */}
      {/* </button>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setTextSize((prev) => prev + 1);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            > */}
      {/* <img src={"https://drive.google.com/thumbnail?id=14pZEsXniPPn1rgajSdY9GT5Cx_IonPYd&sz=w1000"} alt="Increase Text Size" style={{ width: '30px', height: '30px' }} />
            </button>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setTextSize((prev) => prev - 1);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=15NdJsuPm_aFSSAp-EEtu5c7-Af-w9Yji&sz=w1000"} alt="Decrease Text Size" style={{ width: '30px', height: '30px' }} />
            </button>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Chatbot;