import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"; // Make sure to install axios: npm install axios
import { FormContainer, SubmitButton } from "../components/MainStyles";
import logo from "../nurtur_new_logo.svg";
import { LoginForm, MainTitle } from "../components/MainStyles";
import Input from "../utils/Input";
const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function ResetPassword() {
  const [message, setMessage] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [oobCode, setOobCode] = useState(null);
  const [resendEmailSent, setResendEmailSent] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("oobCode");
    if (code) {
      setOobCode(code);
    } else {
      setIsLinkValid(false);
      setMessage("Invalid password reset link.");
    }
  }, [location]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/reset-password",
        {
          oobCode,
          newPassword: values.password,
        }
      );

      if (response.status === 200) {
        setResetSuccess(true);
        setMessage(
          "Password reset successful. You can now log in with your new password."
        );
      } else {
        setMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
    setSubmitting(false);
  };

  const handleResendEmail = async () => {
    navigate("/forgot");
    // try {
    //   const response = await axios.post('/send-forgot-password-email', { email });
    //   if (response.data.success) {
    //     setResendEmailSent(true);
    //     setMessage('A new password reset email has been sent. Please check your inbox.');
    //   } else {
    //     setMessage(`Error: ${response.data.message}`);
    //   }
    // } catch (error) {
    //   setMessage(`Error: ${error.response?.data?.message || error.message}`);
    // }
  };

  return (
    <div style={{ position: "relative" }}>
      <FormContainer>
        <img
          src={logo}
          alt="nurtur logo"
          className="logo"
          style={{ borderRadius: "10px" }}
        />
        <LoginForm>
          <MainTitle>Reset Password</MainTitle>
          {isLinkValid ? (
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Input
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="New password"
                    mandatory
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm new password"
                    mandatory
                  />
                  <SubmitButton
                    theme="blue"
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      backgroundColor: "#6869F7",
                      marginRight: "0",
                      marginLeft: "0",
                    }}
                  >
                    Reset Password
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          ) : (
            <div style={{ textAlign: "center" }}>
              {!resendEmailSent ? (
                <>
                  {/* <p style={{ marginBottom: '20px', color: '#333' }}>
                    Please request a new password reset email.
                  </p> */}
                  <SubmitButton
                    theme="blue"
                    onClick={handleResendEmail}
                    style={{
                      backgroundColor: "#6869F7",
                      marginRight: "0",
                      marginLeft: "0",
                    }}
                  >
                    Resend Email
                  </SubmitButton>
                </>
              ) : (
                <p style={{ marginBottom: "20px", color: "green" }}>
                  A new password reset email has been sent. Please check your
                  inbox.
                </p>
              )}
            </div>
          )}
          {message && (
            <p
              style={{
                marginTop: "20px",
                color: isLinkValid ? "green" : "red",
                textAlign: "center",
              }}
            >
              {message}
            </p>
          )}
          {resetSuccess && (
            <SubmitButton
              theme="blue"
              onClick={() => navigate("/login")}
              style={{
                backgroundColor: "#6869F7",
                marginRight: "0",
                marginLeft: "0",
              }}
            >
              Login
            </SubmitButton>
          )}
        </LoginForm>
      </FormContainer>
    </div>
  );
}

export default ResetPassword;
