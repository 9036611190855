import React, { createContext, useEffect, useState } from "react";

export const ChatContext = createContext();

const sessionRanges = {
  1: { start: 1, end: 48 },
  2: { start: 49, end: 85 },
  3: { start: 86, end: 114 },
  4: { start: 115, end: 150 },
  5: { start: 151, end: 191 },
  6: { start: 192, end: 235 },
  7: { start: 236, end: 261 },
  8: { start: 262, end: 282 },
};

const getSession = (questionId) => {
  return Object.keys(sessionRanges).find((session) => {
    const { start, end } = sessionRanges[session];
    return questionId >= start && questionId <= end;
  });
};

const getProgress = (questionId) => {
  const session = getSession(questionId);
  if (session) {
    const { start, end } = sessionRanges[session];
    return ((questionId - start) / (end - start)) * 100;
  }
  return 0;
};

const ChatContextProvider = ({ children }) => {
  const [showChatMenu, setShowChatMenu] = useState(false);
  const [showChatSettings, setShowChatSettings] = useState(false);
  const [showSupportPanel, setShowSupportPanel] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [sessionNumber, setSessionNumber] = useState(
    getSession(currentQuestionId)
  );
  const [progress, setProgress] = useState(getProgress(currentQuestionId));

  useEffect(() => {
    setSessionNumber(getSession(currentQuestionId));
    setProgress(getProgress(currentQuestionId));
  }, [currentQuestionId]);

  return (
    <ChatContext.Provider
      value={{
        showChatMenu,
        setShowChatMenu,
        showChatSettings,
        setShowChatSettings,
        showSupportPanel,
        setShowSupportPanel,
        sessionNumber,
        progress,
        currentQuestionId,
        setCurrentQuestionId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
