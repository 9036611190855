import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import "./PDPSurvey.css";

const PDPSurveyDescription = ({ handleNext }) => {
  return (
    <div className="h-screen w-full flex items-center justify-center bg-white p-1">
      <div className="w-full max-w-[500px] bg-white flex flex-col justify-between p-5 md:p-6 ">
        <div className="flex align-center justify-center mb-5">
          <img
            alt="nurtur logo"
            src="/images/nurtur_new_logo.svg"
            style={{
              height: "2.5rem",
            }}
          />
        </div>
        <h1 className="text-lg md:text-xl font-semibold text-start mt-4 md:mt-5">
          <span className="text-yellow-500">First,</span> let’s get to know you
          and your health
        </h1>

        <div className="max-w-md w-full text-base mt-5 text-start ">
          <p>
            Before jumping in, we need a little more information about you and
            your health history.
          </p>
          <br />
          <p>
            This survey is a quick check-in to help us understand how we can
            support your maternal mental health journey.
            <strong>This is a prediction tool, not a medical diagnosis.</strong>
          </p>
          <br />
          <p>
            If something doesn’t feel right, call your healthcare provider
            immediately, regardless of your score.
          </p>
          <br />
          <p className="text-gray-500 text-sm md:text-xs">
            <i>
              Postpartum Depression Prediction Survey, Adapted from The American
              Journal of Preventive Medicine Board of Governors, September 2023,
              Vol. 2, Issue 3, by C. Wakefield, M. Frosch.
            </i>
          </p>
        </div>

        <button
          onClick={handleNext}
          className="bg-[#6869F7] text-white px-7 py-3 rounded-full text-lg w-full 
                   transition-colors duration-200 gap-3 
                   inline-flex items-center justify-center mt-7 "
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
  );
};
export default PDPSurveyDescription;
