import { React } from "react";
import { useNavigate } from "react-router-dom";
import { MainTitle } from "../../components/MainStyles";
import { RxCross2 } from "react-icons/rx";
import { FaArrowLeft } from "react-icons/fa6";
import "./PrivacyPolicy.css";
import { IconButton } from "@mui/material";
const Terms = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <div className="privacy_policy_and_tos">
      {/* <div className="privacy_topIcon">
        <IconButton className="privacy_crossIcon">
          <RxCross2 onClick={handleClose} />
        </IconButton>
        <p>nurturcare.com</p>
      </div> */}
      <div className="privacy_container">
        <div className="privacy_topContainer">
          <div>
            <FaArrowLeft onClick={handleClose} />
          </div>
          <div className="privacy_iconLogo">
            <img
              alt="nurtur logo"
              src="/images/nurtur_new_logo.svg"
              style={{
                height: "2.5rem",
              }}
            />
          </div>
        </div>

        <div className="Privacy_textContainer">
          <MainTitle className="privacy_heading">Terms of Service</MainTitle>
          <div className="privacy_subHeading">
            <p>Last Updated: Jun 1, 2024</p>
          </div>
          <p>
            Welcome, and thank you for your interest in nurtur health inc
            (“nurtur,” “we,” or “us”) and our website at{" "}
            <a href="https://app.nurturcare.com/">
              https://app.nurturcare.com/
            </a>
            , our maternal mental health services, along with any related
            websites, networks, applications, mobile applications, software and
            other services provided by us (collectively, the “Service”). These
            Terms of Service (the “Terms”) are a legally binding contract
            between you and nurtur regarding your use of the Service.
          </p>
          <br />
          <p>
            <strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</strong>
          </p>
          <p>
            BY CLICKING “I ACCEPT,” OR BY OTHERWISE ACCESSING OR USING THE
            SERVICE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A
            CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE
            FOLLOWING TERMS AND CONDITIONS, INCLUDING THE nurtur’S PRIVACY
            POLICY (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT
            AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
            SERVICE. YOUR USE OF THE SERVICE, AND nurtur’S PROVISION OF THE
            SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY nurtur AND BY YOU TO BE
            BOUND BY THESE TERMS. IF YOU ARE ENTERING THESE TERMS ON BEHALF OF
            AN ORGANIZATION, YOU CONFIRM THAT YOU HAVE THE AUTHORITY TO BIND THE
            ORGANIZATION AND THAT THE ORGANIZATION WILL BE BOUND BY THESE TERMS.
            THE TERM “YOU” OR “YOUR” SHALL REFER TO YOU AND SUCH ORGANIZATION
            (IF ANY).
          </p>
          <h2>1. Privacy.</h2>
          <p>
            You consent to the collection and use of certain of your personally
            identifiable information in accordance with nurtur’s Privacy Policy.
          </p>
          <h2>2. nurtur Service Overview.</h2>
          <p>
            nurtur provides maternal mental health services through prediction
            and prevention of postpartum depression specifically through
            clinically proven protocol.
          </p>
          <h2>3. No Medical Advice; Disclaimer.</h2>
          <p>
            nurtur does not provide medical advice. You acknowledge and agree
            that the Service and all information made available in connection
            with the Service are solely for informational purposes and that the
            Service and content on the Service do not constitute medical advice,
            and are not intended to independently diagnose, prevent, or treat
            any condition or disease or to ascertain the state of your health or
            treatment thereof in the absence of specific medical advice and
            clinical diagnosis. Information provided through the use of the
            Service may not be relevant for an individual’s personal situation.
            Your use of the Service does not create a patient relationship with
            nurtur. You understand that the Service and associated
            recommendations are intended for research, informational, and
            educational purposes only, and that while this information might
            point to a possible diagnosis or treatment, diagnoses should come
            from licensed medical and/or behavioral health personnel. You
            acknowledge that nurtur urges you to seek the advice of your
            physician or other health care provider if you have questions or
            concerns arising from information you receive from nurtur. You
            assume full risk and responsibility for the information you provide,
            use, or rely on, in relation to the Service. nurtur DISCLAIMS ALL
            LIABILITY RELATED TO CONTENT, INFORMATION, OR SERVICES PROVIDED IN
            CONNECTION WITH THE SERVICE, AND AS A RESULT OF, OR IN RELATION TO
            THE USE OF THE SERVICE. nurtur OFFERS OPPORTUNITIES TO DISCUSS
            TREATMENT OPTIONS AND DOES NOT, AND DOES NOT INTEND TO, PROVIDE
            MEDICAL OR HEALTH-RELATED SERVICES, ADVICE, OR ACT IN ANY WAY AS A
            MEDICAL OR HEALTH-RELATED PROVIDER. nurtur IS NOT A PRACTITIONER AND
            IS NOT A MEDICAL ORGANIZATION, HOSPITAL, HEALTHCARE PROVIDER, OR
            MEDICAL SERVICE PROVIDER.
          </p>
          <h2>4. Eligibility.</h2>
          <p>
            You must be at least 18 years old to use the Service. By agreeing to
            these Terms, you represent and warrant to us that: (a) you are at
            least 18 years old and (b) your use of the Service is in compliance
            with any and all applicable laws and regulations.
          </p>
          <h2>5. Ownership; Proprietary Rights.</h2>
          <p>
            The Service is owned and operated by nurtur. The graphics, designs,
            information, data, computer code (including source code or object
            code), products, software, services, and all other elements of the
            Service (“Materials”) provided by nurtur are protected by
            intellectual property and other laws. All Materials included in the
            Service are the property of nurtur or its third-party licensors.
          </p>
          <h2>6. Accounts and Registration.</h2>
          <p>
            To access most features of the Service, you must register for an
            account. When you register for an account, you may be required to
            provide us with some information about yourself, such as your name,
            email address, or other contact information. You agree that the
            information you provide to us is accurate and that you will keep it
            accurate and up to date at all times. When you register, you will be
            asked to provide a password. You are solely responsible for
            maintaining the confidentiality of your account and password, and
            you accept responsibility for all activities that occur under your
            account. If you believe that your account is no longer secure, then
            you must immediately notify us at{" "}
            <a href="mailto:support@nurturcare.com">support@nurturcare.com</a>
          </p>
          <h2>7. General Payment Terms.</h2>
          <p>
            Before you pay any fees, you will have an opportunity to review and
            accept the fees that you will be charged. All fees are in U.S.
            Dollars and are non-refundable. Prices and availability of products
            or services are subject to change without notice.
          </p>
          <p>
            You agree that we have permission to provide your payment
            information to third-party payment processors and/or service
            providers in order to process payments from you.
          </p>
          <p>
            nurtur may reject any orders or refuse to provide the Service to
            anyone for any reason.
          </p>
          <h2>8. Licenses.</h2>
          <h3>a. Limited License.</h3>
          <p>
            Subject to your complete and ongoing compliance with these Terms,
            nurtur grants you, solely for your personal, non-commercial use, a
            limited, non-exclusive, non-transferable, non-sublicensable,
            revocable license to access and use the Service. Unless explicitly
            stated herein, nothing in these Terms shall be construed as
            conferring any other license to intellectual property rights,
            whether by estoppel, implication or otherwise.
          </p>
          <h3>b. License Restrictions.</h3>
          <p>
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (a) reproduce, distribute,
            publicly display, or publicly perform the Service; (b) make
            modifications to or derivate uses of the Service; or (c) interfere
            with or circumvent any feature of the Service, including any
            security or access control mechanism. If you are prohibited under
            applicable law from using the Service, you may not use it.
          </p>
          <h3>c. Ownership; Proprietary Rights.</h3>
          <p>
            The Service is owned and operated by nurtur. The visual interfaces,
            graphics, design, compilation, information, data, computer code
            (including source code or object code), products, software,
            services, and all other elements of the Service (“Materials”)
            provided by nurtur are protected by intellectual property and other
            laws. All Materials included in the Service are the property of
            nurtur or its third-party licensors. Except as expressly authorized
            by nurtur, you may not make use of the Materials. nurtur reserves
            all rights to the Materials not granted expressly in these Terms.
          </p>
          <h2>9. Feedback.</h2>
          <p>
            If you choose to provide input and suggestions regarding problems
            with or proposed modifications or improvements to the Service
            (“Feedback”), then you hereby grant nurtur an unrestricted,
            worldwide, perpetual, irrevocable, non-exclusive, transferable,
            sublicensable (through infinite levels of sublicense), fully-paid,
            royalty-free right to exploit the Feedback in any manner and for any
            purpose, including to improve the Service and create other products
            and services.
          </p>
          <h2>10. Data</h2>
          <h3>a. Data Generally.</h3>
          <p>
            The Service permits users to upload information and device data to
            the Service.
          </p>
          <h3>b. Limited License Grant to nurtur.</h3>
          <p>
            You hereby grant nurtur a non-exclusive, royalty-free, transferable,
            sublicensable (through infinite levels of sublicense), perpetual,
            fully paid right and license (with the right to sublicense) to use,
            copy, distribute, store, and make derivative works of any
            information and data (“Data”) that you provide to the Service for
            the purpose of preparing health evaluation reports for you and to
            anonymize (such that no personal information will be discoverable),
            aggregate and transform your Data with that of other users into
            databases for the purpose of research, development, improvement of
            our AI software, and debugging our Service. nurtur shall exclusively
            own such anonymized and aggregated data.
          </p>
          <h3>c. Data Representations and Warranties.</h3>
          <p>By uploading Data, you affirm, represent, and warrant that:</p>
          <h4>
            i. you are the owner of the Data, or have the necessary rights,
            consents, and permissions to authorize nurtur to use and distribute
            the Data in the manner described above; and
          </h4>
          <h4>
            ii. your Data, and the use of your Data as contemplated by these
            Terms, does not and will not: (i) infringe, violate, or
            misappropriate any third party right, including any copyright,
            trademark, patent, trade secret, moral right, privacy right, right
            of publicity, or any other intellectual property or proprietary
            right; (ii) slander, defame, libel, or invade the right of privacy,
            publicity or other property rights of any other person; or (iii)
            cause nurtur to violate any law or regulation.
          </h4>
          <h2>11. Prohibited Conduct.</h2>
          <p>
            <strong>BY USING THE SERVICE, YOU AGREE NOT TO:</strong>
          </p>
          <h4>
            a. use the Service for any illegal purpose or in violation of any
            local, state, national, or international law;
          </h4>
          <h4>
            b. violate, or encourage others to violate, any right of a third
            party, including by infringing or misappropriating any third-party
            intellectual property right;
          </h4>
          <h4>
            c. download (other than page caching) or copy any portion of the
            Service or Materials or any information contained therein, except as
            expressly permitted;
          </h4>
          <h4>
            d. attempt to determine the identity of any user of the Service or
            collect or harvest any personally identifiable information,
            including, but not limited to, company names, domain names or
            account names, from the Service nor to use the communication systems
            provided by the Service for any commercial solicitation purposes;
          </h4>
          <h4>
            e. interfere with security-related features of the Service,
            including by: (i) disabling or circumventing features that prevent
            or limit use or copying of any content; or (ii) reverse engineering
            or otherwise attempting to discover the source code of any portion
            of the Service except to the extent that the activity is expressly
            permitted by applicable law;
          </h4>
          <h4>
            f. interfere with the operation of the Service or any user’s
            enjoyment of the Service, including by: (i) uploading or otherwise
            disseminating any virus, adware, spyware, worm, or other malicious
            code; (ii) making any unsolicited offer or advertisement to another
            user of the Service; (iii) collecting personal information about
            another user or third party without consent; (iv) attempt to gain
            unauthorized access to nurtur’s computer systems or engage in any
            activity that disrupts, diminishes the quality of, interferes with
            the performance of, or impairs the functionality of, the Service or
            (v) interfering with or disrupting any network, equipment, or server
            connected to or used to provide the Service;
          </h4>
          <h4>
            g. use automated means, including spiders, robots, crawlers, offline
            readers, data mining tools, or the like to download data from the
            Site or Service or to cause an overload of nurtur servers;
          </h4>
          <h4>
            h. use any portion of the Service as a destination linked from any
            unsolicited bulk messages or unsolicited commercial messages;
          </h4>
          <h4>
            i. perform any fraudulent activity including impersonating any
            person or entity, claiming a false affiliation, accessing any other
            Service account without permission, or falsifying your age or date
            of birth;
          </h4>
          <h4>
            j. sell or otherwise transfer the access granted under these Terms
            or any Materials or any right or ability to view, access, or use any
            Materials, or resell, lease, or rent the Service or any of the
            Materials contained therein;
          </h4>
          <h4>
            k. any use of the Service or the Materials other than for their
            intended purpose; or
          </h4>
          <h4>
            l. attempt to do any of the acts described in this Section or assist
            or permit any person in engaging in any of the acts described in
            this Section.
          </h4>
          <h2>12. Digital Millennium Copyright Act</h2>
          <p>
            <strong>DMCA Notification.</strong> We comply with the provisions of
            the Digital Millennium Copyright Act applicable to Internet service
            providers (17 U.S.C. §512, as amended). If you have an intellectual
            property rights-related complaint about material posted on the
            Service, you may contact our Designated Agent at the following
            address:
          </p>
          <p>nurtur health inc</p>
          <p>ATTN: Legal Department (Copyright Notification)</p>
          <p>Derry, NH, USA</p>
          <p>Phone: 801.910.0308</p>
          <p>
            Email: <a href="mailto:team@nurturcare.com">team@nurturcare.com</a>
          </p>
          <p>
            Any notice alleging that materials hosted by or distributed through
            the Service infringe intellectual property rights must comply with
            elements of notification as described in 17 U.S.C. §512, including:
          </p>
          <h4>
            (i) a physical or electronic signature of a person authorized to act
            on behalf of the owner of an exclusive right that is allegedly
            infringed;
          </h4>
          <h4>
            (ii) identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works are covered by a single
            notification, a representative list of such works;
          </h4>
          <h4>
            (iii) identification of the material that is claimed to be
            infringing or to be the subject of infringing activity and that is
            to be removed or access to which is to be disabled, and information
            reasonably sufficient to locate the material;
          </h4>
          <h4>
            (iv) information reasonably sufficient to permit nurtur to contact
            the complaining party, such as an address, telephone number, and, if
            available, an electronic mail address at which the complaining party
            may be contacted;
          </h4>
          <h4>
            (v) a statement that the complaining party has a good faith belief
            that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or the law; and
          </h4>
          <h4>
            (vi) a statement that the information in the notification is
            accurate, and under penalty of perjury, that the complaining party
            is authorized to act on behalf of the owner of an exclusive right
            that is allegedly infringed.
          </h4>
          <h2>13. Modification of these Terms.</h2>
          <p>
            We reserve the right, at our discretion, to change these Terms on a
            going-forward basis at any time. Any changes or modification will be
            effective immediately upon posting of the revisions on the Service.
            Please check these Terms periodically for changes. Your continued
            use of the Service following the posting of changes or modifications
            to these Terms will constitute your acceptance of such changes or
            modifications. If you do not agree to the amended Terms, you must
            stop using the Services. Except as expressly permitted in this
            Section, these Terms may be amended only by a written agreement
            signed by authorized representatives of the parties to these Terms.
            Disputes arising under these Terms will be resolved in accordance
            with the version of these Terms that was in effect at the time the
            dispute arose.
          </p>
          <h2>14. Term, Termination and Modification of the Service</h2>
          <h3>a. Term.</h3>
          <p>
            These Terms are effective beginning when you accept the Terms or
            first access or use the Service and ending when terminated as
            described in this Section.
          </p>
          <h3>b. Termination.</h3>
          <p>
            If you violate any provision of these Terms these Terms shall
            automatically terminate. In addition, nurtur may, at its sole
            discretion, terminate these Terms, or suspend or terminate your
            access to the Service, at any time for any reason or no reason, with
            or without notice. You may terminate these Terms at any time by
            contacting customer service at{" "}
            <a href="mailto:support@nurturcare.com">support@nurturcare.com</a>.
          </p>
          <h3>c. Effect of Termination.</h3>
          <p>
            Upon termination of these Terms: (a) your license rights will
            terminate and you must immediately cease all use of the Service; (b)
            you will no longer have access to your account; (c) you must pay
            nurtur any unpaid amount that was due prior to termination; and (d)
            all payment obligations accrued prior to termination, and any of
            these Terms that by their nature or otherwise reasonably should
            survive termination or expiration shall survive any termination or
            expiration of these Terms.
          </p>
          <h3>d. Modification of the Service.</h3>
          <p>
            nurtur reserves the right to modify or discontinue the Service at
            any time (including by limiting or discontinuing certain features of
            the Service), temporarily or permanently, without notice to you.
            nurtur will have no liability for any change to the Service or any
            suspension or termination of your access to or use of the Service.
          </p>
          <h2>15. Indemnity.</h2>
          <p>
            You are responsible for your use of the Service, and you will defend
            and indemnify nurtur and its officers, directors, employees,
            consultants, affiliates, subsidiaries and agents (together, the
            “nurtur Entities”) from and against every claim brought by a third
            party, and any related liability, damage, loss, and expense,
            including reasonable attorneys’ fees and costs, arising out of or
            connected with: (a) your use of, or misuse of, the Service; (b) your
            violation of any portion of these Terms, any representation,
            warranty, or agreement referenced in these Terms, or any applicable
            law or regulation; (c) your violation of any third party right,
            including any intellectual property right or publicity,
            confidentiality, other property, or privacy right; or (d) any
            dispute or issue between you and any third party.
          </p>
          <h2>16. Disclaimers; No Warranties</h2>
          <p>
            THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
            SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. nurtur
            DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
            RELATING TO OR PROVIDED FROM THE SERVICE AND ALL MATERIALS AND
            CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED
            WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
            COURSE OF DEALING, USAGE, OR TRADE. nurtur DOES NOT WARRANT THAT
            MATERIALS, INCLUDING ANY INFORMATION AVAILABLE THROUGH THE SERVICES
            ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE, OR THAT
            THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
            CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE,
            OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND nurtur
            DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
          </p>
          <p>
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM THE SERVICE OR nurtur ENTITIES OR ANY MATERIALS OR CONTENT
            AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY
            OF THE nurtur ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED
            IN THESE TERMS. nurtur IS NOT RESPONSIBLE FOR ERRORS OR OMISSIONS IN
            ANY INFORMATION OR MATERIALS ACCESSED THROUGH THE SERVICES, THE
            SITE, OR ANY OTHER PART OF THE SERVICE. WE ARE NOT RESPONSIBLE FOR
            ANY DAMAGE THAT MAY RESULT FROM THE SERVICE. YOU UNDERSTAND AND
            AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION
            AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR
            PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN
            CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA.
          </p>
          <p>
            HOWEVER, nurtur DOES NOT DISCLAIM ANY WARRANTY OR OTHER RIGHT THAT
            nurtur IS PROHIBITED FROM DISCLAIMING UNDER APPLICABLE LAW.
          </p>
          <p>
            THE SERVICE AND MATERIALS ARE GENERAL IN NATURE AND IS NOT INTENDED
            FOR ANY PARTICULAR PURPOSE, INCLUDING, IN ANY WAY, TO BE A
            SUBSTITUTE FOR A MEDICAL EXAMINATION OR ANY PROFESSIONAL MEDICAL
            ADVICE, MEDICAL OPINION, DIAGNOSIS OR TREATMENT, SYMPTOM ASSESSMENT,
            OR HEALTH COUNSELING. ALWAYS SEEK THE ADVICE OF APPROPRIATELY
            QUALIFIED AND REGULATED HEALTHCARE PROVIDERS WITH ANY QUESTIONS YOU
            MAY HAVE WITH REGARD TO A SPECIFIC MEDICAL CONDITION FOR TREATMENT
            OF WHICH YOU ARE CONCERNED. IT IS IMPORTANT NOT TO DISREGARD OR
            DELAY SEEKING CONSULTATIVE ADVICE BECAUSE OF SOMETHING YOU HAVE READ
            OR VIEWED WHILE USING THE SERVICE. RELIANCE ON ANY INFORMATION
            PROVIDED BY THE SERVICE IS SOLELY AT YOUR OWN RISK AND YOU ASSUME
            FULL RESPONSIBILITY FOR THE USE OF THE SERVICE AND ITS INFORMATION.
          </p>
          <h2>17. Limitation of Liability</h2>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE nurtur
            ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
            PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
            RELATING TO THE SERVICE OR YOUR USE OF, OR YOUR INABILITY TO USE,
            THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
            BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE,
            OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY nurtur ENTITY HAS
            BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF
            THE nurtur ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING
            TO THE SERVICE OR THE USE OF OR ANY INABILITY TO USE ANY PORTION OF
            THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT,
            TORT, OR OTHERWISE, IS LIMITED TO THE AMOUNT YOU HAVE PAID TO nurtur
            FOR SERVICES IN THE TWELVE (12) MONTHS PRIOR TO THE EVENT OR
            CIRCUMSTANCE GIVING RISE TO CLAIM.
          </p>
          <p>
            EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
            LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
            INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
            THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
            THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
            SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS.
            THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED
            REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
          <h2>18. Dispute Resolution and Arbitration</h2>
          <h3>a. Generally.</h3>
          <p>
            In the interest of resolving disputes between you and nurtur in the
            most expedient and cost effective manner, and except as otherwise
            explicitly stated in these Terms, you and nurtur agree that all
            disputes arising in connection with these Terms will be resolved by
            binding arbitration using a neutral arbitrator instead of a judge or
            jury. This agreement to arbitrate disputes includes all claims
            arising out of or relating to any aspect of these Terms, whether
            based in contract, tort, statute, fraud, misrepresentation, or any
            other legal theory, and regardless of whether a claim arises during
            or after the termination of these Terms. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND nurtur ARE EACH WAIVING
            THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
          </p>
          <h3>b. Exceptions.</h3>
          <p>
            Notwithstanding the foregoing Section, nothing in these Terms will
            be deemed to waive, preclude, or otherwise limit the right of either
            party to: (a) bring an individual action in small claims court; (b)
            pursue an enforcement action through the applicable federal, state,
            or local agency if that action is available; (c) seek injunctive
            relief in a court of law in aid of arbitration; or (d) to file suit
            in a court of law to address an intellectual property infringement
            claim.
          </p>
          <h3>c. Arbitrator.</h3>
          <p>
            Any arbitration between you and nurtur will be settled under the
            Federal Arbitration Act and administered by the American Arbitration
            Association (“AAA”) under its Consumer Arbitration Rules
            (collectively, “AAA Rules”) as modified by these Terms. The AAA
            Rules and filing forms are available online at{" "}
            <a href="http://www.adr.org">www.adr.org</a>, by calling the AAA at
            1-800-778-7879, or by contacting nurtur. The arbitrator has
            exclusive authority to resolve any dispute relating to the
            interpretation, applicability, or enforceability of this binding
            arbitration agreement.
          </p>
          <h3>d. Notice of Arbitration; Process.</h3>
          <p>
            A party who intends to seek arbitration must first send a written
            notice of the dispute to the other party by certified U.S. Mail or
            by Federal Express (signature required) or, only if that other party
            has not provided a current physical address, then by electronic mail
            (“Notice of Arbitration”). nurtur’s address for Notice is: nurtur
            health inc, Derry, NH;{" "}
            <a href="mailto:team@nurturcare.com">team@nurturcare.com</a>. The
            Notice of Arbitration must: (a) describe the nature and basis of the
            claim or dispute; and (b) set forth the specific relief sought
            (“Demand”). The parties will make good faith efforts to resolve the
            claim directly, but if the parties do not reach an agreement to do
            so within thirty (30) days after the Notice of Arbitration is
            received, you or nurtur may commence an arbitration proceeding.
            During the arbitration, the amount of any settlement offer made by
            you or nurtur must not be disclosed to the arbitrator until after
            the arbitrator makes a final decision and award, if any. If the
            dispute is finally resolved through arbitration in your favor,
            nurtur will pay you the highest of the following: (i) the amount
            awarded by the arbitrator, if any; (ii) the last written settlement
            amount offered by nurtur in settlement of the dispute prior to the
            arbitrator’s award; or (iii) $100.
          </p>
          <p>
            Any arbitration hearing will take place at a location to be agreed
            upon in King County, Washington, but if the claim is for $10,000 or
            less, you may choose whether the arbitration will be conducted: (a)
            solely on the basis of documents submitted to the arbitrator; (b)
            through a non-appearance based telephone hearing; or (c) by an
            in-person hearing as established by the AAA Rules in the county (or
            parish) of your billing address. If the arbitrator finds that either
            the substance of your claim or the relief sought in the Demand is
            frivolous or brought for an improper purpose (as measured by the
            standards set forth in Federal Rule of Civil Procedure 11(b)), then
            the payment of all fees will be governed by the AAA Rules. In that
            case, you agree to reimburse nurtur for all monies previously
            disbursed by it that are otherwise your obligation to pay under the
            AAA Rules. Regardless of the manner in which the arbitration is
            conducted, the arbitrator must issue a reasoned written decision
            sufficient to explain the essential findings and conclusions on
            which the decision and award, if any, are based. The arbitrator may
            make rulings and resolve disputes as to the payment and
            reimbursement of fees or expenses at any time during the proceeding
            and upon request from either party made within fourteen (14) days of
            the arbitrator’s ruling on the merits.
          </p>
          <h3>e. No Class Actions.</h3>
          <p>
            YOU AND nurtur AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
            ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            Further, unless both you and nurtur agree otherwise, the arbitrator
            may not consolidate more than one person’s claims and may not
            otherwise preside over any form of a representative or class
            proceeding.
          </p>
          <h3>f. Modifications to this Arbitration Provision.</h3>
          <p>
            If nurtur makes any future change to this arbitration provision,
            other than a change to nurtur’s address for Notice of Arbitration,
            you may reject the change by sending us written notice within 30
            days of the change to nurtur’s address for Notice of Arbitration, in
            which case your account with nurtur will be immediately terminated
            and this arbitration provision, as in effect immediately prior to
            the changes you rejected will survive.
          </p>
          <h3>g. Enforceability.</h3>
          <p>
            If any portion of this Section is found to be unenforceable or if
            the entirety of this Section is found to be unenforceable, then the
            entirety of this Section will be null and void and, in that case,
            the parties agree that the exclusive jurisdiction and venue
            described below will govern any action arising out of or related to
            these Terms.
          </p>
          <h2>19. General Terms.</h2>
          <p>
            These Terms, together with the Privacy Policy and any other
            agreements expressly incorporated by reference into these Terms, are
            the entire and exclusive understanding and agreement between you and
            nurtur regarding your use of the Service. You may not assign or
            transfer these Terms or your rights under these Terms, in whole or
            in part, by operation of law or otherwise, without our prior written
            consent, and any such attempted assignment shall be void and of no
            effect. We may assign these Terms at any time without notice or
            consent. The failure to require performance of any provision will
            not affect our right to require performance at any other time after
            that, nor will a waiver by us of any breach or default of these
            Terms, or any provision of these Terms, be a waiver of any
            subsequent breach or default or a waiver of the provision itself.
            Use of section headers in these Terms is for convenience only and
            will not have any impact on the interpretation of any provision.
            Throughout these Terms the use of the word “including” means
            “including but not limited to”. If any part of these Terms is held
            to be invalid or unenforceable, the unenforceable part will be given
            effect to the greatest extent possible, and the remaining parts will
            remain in full force and effect.
          </p>
          <h2>20. Governing Law.</h2>
          <p>
            These Terms are governed by the laws of the State of Washington
            without regard to conflict of law principles. You and nurtur submit
            to the personal and exclusive jurisdiction of the state courts and
            federal courts located within King County, Washington for resolution
            of any lawsuit or court proceeding permitted under these Terms. We
            provide the Service from our offices in Washington, and we make no
            representation that Materials included in the Service are
            appropriate or available for use in other locations.
          </p>
          <h2>21. Additional Terms.</h2>
          <p>
            Your use of the Service is subject to all additional terms,
            policies, rules, or guidelines applicable to the Service or certain
            features of the Service that we may post on or link to from the
            Service (the “Additional Terms”). All Additional Terms are
            incorporated by this reference into, and made a part of, these
            Terms.
          </p>
          <h2>22. Consent to Communications.</h2>
          <p>
            By providing us with your contact information, you agree to receive
            communications, including via e-mail, voice calls, and text messages
            from or on behalf of nurtur at the email address or telephone number
            you provided, even if that number is on a national or state ‘Do Not
            Call List’. These calls will be for informational purposes, such as
            to verify your account or provide you with help or information on
            using the Service. Standard text messaging and telephone minute
            charges applied by your cell phone carrier will apply. nurtur may,
            without further notice or warning and in our discretion, monitor
            and/or record telephone conversations for our business purposes,
            such as quality assurance and training purposes and to protect our
            rights and the rights of others. IF YOU WISH TO OPT OUT OF MARKETING
            EMAILS, YOU CAN UNSUBSCRIBE BY FOLLOWING THE UNSUBSCRIBE OPTIONS IN
            THE EMAIL ITSELF. AS LONG AS YOU MAINTAIN AN ACCOUNT, YOU MAY NOT
            “OPT OUT” OF RECEIVING ACCOUNT-RELATED EMAILS FROM nurtur. IF YOU
            WISH TO OPT OUT OF TEXTS, YOU MAY REPLY “STOP” FROM THE MOBILE
            DEVICE RECEIVING THE MESSAGES. You understand that you may continue
            to receive communications while nurtur processes your opt-out
            request, and you may also receive a communication confirming the
            receipt of your opt-out request.
          </p>
          <h2>23. Consent to Electronic Notice.</h2>
          <p>
            You agree that we may give legal notice by means of electronic mail,
            which electronic mail shall be considered delivered when sent, and
            your address for the receipt of notices pursuant to this Agreement
            shall be the current email address listed by you in your account
            profile. You agree that any notices, agreements, disclosures, or
            other communications that we send to you electronically will satisfy
            any legal communication requirements, including that those
            communications be in writing.
          </p>
          <h2>24. Contact Information.</h2>
          <p>
            The Service is offered by nurtur health inc, located at Derry, NH.
            You may contact us by sending correspondence to that address or by
            emailing us at{" "}
            <a href="mailto:support@nurturcare.com">support@nurturcare.com</a>.
            You can access a copy of these Terms by clicking here.
          </p>
          <h2>25. Force Majeure.</h2>
          <p>
            If the performance of any part of these Terms by either party is
            prevented, hindered, delayed, or otherwise made impracticable by
            reason of any flood, riot, fire, judicial or governmental action,
            labor disputes, act of God, or any other causes beyond the control
            of either party, that party shall be excused from such to the extent
            that it is prevented, hindered, or delayed by such causes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
