import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Country, State } from "country-state-city";
import { message } from "antd";
import {
  InputLabel,
  Select,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../../components/MainStyles";
import logo from "../../nurtur_new_logo.svg";
import styles from "./styles";
import { Tooltip } from "react-tooltip";
import client from "../../api";
import Urls from "../../api/urls";
import { styled } from "@mui/material/styles";
import Input from "../../utils/Input";
import CustomCheckbox from "../../utils/CustomCheckbox";

const formValidation = yup.object().shape({
  firstname: yup
    .string()
    .required("Please enter first name")
    .min(1, "Please enter first name"),
  lastname: yup
    .string()
    .required("Please enter last name")
    .min(1, "Please enter last name"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Must be at least 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords does not match")
    .required("Please confirm your password"),
  email: yup
    .string()
    .lowercase()
    .trim()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email address"
    )
    .required("Please enter your email"),
});

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [states, setStates] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#7B7FFF",
      },
      "&:hover fieldset": {
        borderColor: "#5A5EFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D42FF",
      },
    },
  });
  useEffect(() => {
    const countries = Country.getAllCountries();
    const allCountries = countries.map((country) => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry.value).map(
        (state) => ({ label: state.name, value: state.isoCode })
      );
      console.log("states:::", states);
      setStates(states);
    }
  }, [selectedCountry]);

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      setLoading(true);
      // Replace the URL with your FastAPI endpoint
      const response = await client.post(Urls.REGISTER, {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        // country: selectedCountry.value,
        // state: selectedState.value,
      });
      if (response.status === 200) {
        const { email } = response.data.cosmos_user;
        const password = values.password;
        try {
          // Send verification email
          await client.post(Urls.SEND_VERIFICATION_EMAIL, { email });
          setVerificationSent(true);
          navigate("/account-verification", { state: { email, password } });
        } catch (verificationError) {
          console.error(
            "Error sending verification email:",
            verificationError.message
          );
          message.error(verificationError.message);
        }
      }
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.data?.message ===
        "An error occurred: The user with the provided email already exists (EMAIL_EXISTS)."
      ) {
        setFieldError("email", "Email already exists");
      }
      message.error(error?.response?.data?.message);
      // setErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            {/* <div className="flex flex-col"> */}
            <MainTitle>Create your nurtur account</MainTitle>

            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Input
                    name="firstname"
                    label="First Name"
                    placeholder="First name*"
                    mandatory
                    mainClassName="mt-0 mb-0"
                  />
                  <Input
                    name="lastname"
                    label="Last Name"
                    placeholder="Last name*"
                    mandatory
                    mainClassName="mt-0 mb-0"
                  />
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Email*"
                    mandatory
                    mainClassName="mt-0 mb-0"
                  />
                  <Input
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Create Password*"
                    mandatory
                    mainClassName="mt-0 mb-0"
                  />
                  <Input
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm Password*"
                    mandatory
                    mainClassName="mt-0 mb-0"
                  />

                  {/* Country Select */}
                  {/* <Input
                    name="country"
                    label="Country"
                    type="select"
                    placeholder="Select Country"
                    options={countries} // Country options list
                    onValueChange={(value) => {
                      // setFieldValue("country", value);
                      const selected = countries.find((c) => c.value === value);
                      setSelectedCountry(selected);
                    }}
                  /> */}

                  {/* State Select (Depends on Country Selection) */}
                  {/* <Input
                    name="state"
                    label="State"
                    type="select"
                    placeholder="Select State"
                    options={states} // State options list
                    onValueChange={(value) => {
                      // setFieldValue("state", value);
                      const selected = states.find((s) => s.value === value);
                      setSelectedState(selected);
                    }}
                  />
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )} */}

                  {/* <FormControlLabel
                    sx={{ margin: "0px" }}
                    control={
                      <Checkbox
                        sx={{
                          borderRadius: "10px",
                          padding: "0",
                          paddingRight: "9px", // Apply border radius here
                          "& .MuiSvgIcon-root": {
                            width: "2rem",
                            height: "2rem",
                          },
                          label: {
                            margin: "0",
                          },
                        }}
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      // <SmallText>
                      <div>
                        I agree to nurtur’s{" "}
                        <Link
                          to="/terms"
                          style={{
                            color: "#7B7FFF",
                            textDecoration: "underline",
                          }}
                        >
                          Terms of Use
                        </Link>{" "}
                        and acknowledge the{" "}
                        <Link
                          to="/privacy"
                          style={{
                            color: "#7B7FFF",
                            textDecoration: "underline",
                          }}
                        >
                          Privacy Policy
                        </Link>{" "}
                      </div>
                      // </SmallText>
                    }
                  /> */}

                  <div className="mt-3 mb-8">
                    <CustomCheckbox
                      label={
                        <div>
                          I agree to nurtur’s{" "}
                          <Link
                            to="/terms"
                            style={{
                              color: "#7B7FFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms of Use
                          </Link>{" "}
                          and acknowledge the{" "}
                          <Link
                            to="/privacy"
                            style={{
                              color: "#7B7FFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </Link>{" "}
                        </div>
                      }
                      checked={isTermsAccepted}
                      setChecked={(value) => setIsTermsAccepted(value)}
                    />
                  </div>

                  <SubmitButton
                    theme="blue"
                    type="submit"
                    disabled={isSubmitting || !isTermsAccepted}
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      backgroundColor: "#6869F7",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Create Account"
                    )}
                  </SubmitButton>
                  <SmallText style={{ marginTop: 5, marginBottom: 5 }}>
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      style={{
                        fontWeight: "bold",
                        color: "#7B7FFF",
                        textDecoration: "underline",
                      }}
                    >
                      Sign in
                    </Link>
                  </SmallText>
                </Form>
              )}
            </Formik>
            {/* </div> */}
          </LoginForm>
        </FormContainer>
        <Tooltip id="password" place="left" data-tooltip-variant="info" />
      </div>
    </>
  );
};

export default SignUp;
