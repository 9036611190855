import React from "react";
import { MainTitle, SubmitButton } from "../../../components/MainStyles";
import { FaArrowRight } from "react-icons/fa6";
import "./PDPSurvey.css";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/home");
  };

  return (
    <div className="thankYou_Container">
      <div className="thankYou_description">
        <img
          alt="plant logo"
          src="/images/Plant-Purple.svg"
          style={{
            height: "2.5rem",
          }}
        />
        <div>
          <MainTitle>Thank You for filling out the survey!</MainTitle>
        </div>
        <SubmitButton
          className="thankyou_startButton"
          onClick={handleGetStarted}
        >
          Get Started <FaArrowRight />
        </SubmitButton>
      </div>
      <div className="thankYou_footer">
        <div className="thankyou_logo">
          <img
            alt="nurtur logo"
            src="/images/nurtur_new_logo.svg"
            style={{
              height: "2.5rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
