import React, { useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Typography, Box, Button, TextField } from "@mui/material";
import {
  CreateAccount,
  FormContainer,
  SubmitButton,
} from "../components/MainStyles";
import { message } from "antd";
import { addUserInfo } from "../redux/features/user/userInfoSlice";
import { useDispatch } from "react-redux";
import client from "../api";
import Urls from "../api/urls";
import { CircularProgress } from "@mui/material";

const EmailVerification = ({ setIsAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("pending");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendStatus, setResendStatus] = useState("");
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const { email, password } = useLocation().state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleVerification = async () => {
    const code = verificationCode.join("");
    console.log(
      verificationCode.some((number) => !number),
      "code"
    );
    if (verificationCode.some((number) => !number)) {
      // setVerificationStatus("error");
      setResendStatus("Please enter 6-digit verification code.");
      return;
    }

    setResendStatus("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/verify-email`,
        {
          email,
          verification_code: code,
        }
      );

      if (response.status === 200) {
        setVerificationStatus("success");
        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}/epds/create`, {
            email,
          });
        } catch (epdsError) {
          setResendStatus(
            `Account verified, but there was an issue setting up EPDS. Please contact support. ${epdsError.message}`
          );
          return;
        }

        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}/chat/create`, {
            email,
          });
        } catch (chatError) {
          setResendStatus(
            `Account verified, but there was an issue setting up chat. Please contact support. ${chatError.message}`
          );
          return;
        }
      } else {
        setVerificationStatus("error");
        setResendStatus(
          "Verification failed. Please check your code and try again."
        );
      }
    } catch (error) {
      console.error("Email verification error:", error);
      setVerificationStatus("error");
      setResendStatus("An error occurred. Please try again later.");
    }
  };

  const handleCodeChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Only allow digits
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleResendVerification = async () => {
    console.log("handleResendVerification is clicked");
    setVerificationCode(["", "", "", "", "", ""]);
    setVerificationStatus("pending");
    if (!email) {
      setResendStatus("Please enter your email address.");
      return;
    }

    try {
      setResendStatus("Sending...");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/send-verification-email`,
        { email }
      );
      if (response.status === 200) {
        setResendStatus(
          "Verification email sent successfully. Please check your inbox."
        );
      } else {
        setResendStatus("Failed to send verification email. Please try again.");
      }
    } catch (error) {
      console.error("Resend verification error:", error);
      setResendStatus("An error occurred. Please try again later.");
    }
  };

  const get_user = async () => {
    try {
      const response = await client.get(Urls.CURRENT_USER);
      if (response.status === 200) {
        const results = response.data.results;
        dispatch(addUserInfo(results));
      }
    } catch (error) {
      message.error(`An error occurred while fetching user details:${error}`);
      console.error("An error occurred while fetching data.", error);
    }
  };

  const handleLoginSubmit = async () => {
    setIsLoading(true);
    //  setLoginError("");
    try {
      const response = await client.post(Urls.LOGIN, {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        const { results, email_verified } = response.data;
        //  setEmailVerified(email_verified);

        const { email, idToken, refreshToken, expiresIn, localId } = results;

        if (email_verified === true) {
          setIsAuthenticated(true);
          dispatch(addUserInfo({ isLoggedIn: true }));
          localStorage.setItem("email", email);
          localStorage.setItem("token", idToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("expiresIn", expiresIn);
          localStorage.setItem("localId", localId);
          await get_user();
          //  await get_chat();
          //  await get_epds();
          navigate("/welcome");
        }
      }
    } catch (error) {
      //  setLoginError(
      //    `Login failed. ${error.response?.data?.message || error.message}`
      //  );
      message.error(`Something went wrong. Please login with your credentials`);
      navigate("/login");
    }
    setIsLoading(false);
  };

  const renderContent = () => {
    switch (verificationStatus) {
      case "pending":
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Email Verification
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please enter the 6-digit verification code sent to your email:
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", my: 2, gap: 1 }}
            >
              {verificationCode.map((digit, index) => (
                <TextField
                  key={index}
                  inputRef={inputRefs[index]}
                  variant="outlined"
                  type="text"
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", width: "2rem" },
                  }}
                  sx={{
                    width: "3rem",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFF",
                    },
                  }}
                />
              ))}
            </Box>
            <SubmitButton
              theme="blue"
              type="submit"
              onClick={handleVerification}
              disabled={resendStatus === "Sending..."}
              style={{
                backgroundColor: "#6869F7",
                marginRight: "0",
                marginLeft: "0",
                position: "relative",
              }}
            >
              Submit
            </SubmitButton>
            {resendStatus && (
              <Typography
                variant="body2"
                color="textSecondary"
                paragraph
                style={{
                  color: resendStatus.includes(
                    "Please enter 6-digit verification code."
                  )
                    ? "red"
                    : "",
                }}
              >
                {resendStatus}
              </Typography>
            )}
            <div style={{ textAlign: "center" }}>
              <p style={{ marginBottom: "20px", color: "#333" }}>
                Didn't receive the verification code?
              </p>
              <Button
                variant="text"
                color="primary"
                onClick={handleResendVerification}
                sx={{ mb: 2 }}
              >
                Resend Code
              </Button>
            </div>
          </>
        );
      case "success":
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Email Verified Successfully!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Your email has been verified. You can now log in to your account.
            </Typography>
            {/* <Button onClick={handleLoginSubmit} variant="contained" color="primary">
          Continue
        </Button> */}
            <SubmitButton
              theme="blue"
              type="submit"
              disabled={isLoading}
              onClick={handleLoginSubmit}
              style={{
                backgroundColor: "#6869F7",
                marginRight: "0",
                marginLeft: "0",
                position: "relative",
                zIndex: "1",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Continue"
              )}
            </SubmitButton>
          </>
        );
      case "error":
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Verification Failed
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We couldn't verify your email. The code may have expired or is
              invalid.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleResendVerification}
              fullWidth
              sx={{
                width: "100%",
                margin: "10px 5px",
                padding: "10px 0",
                backgroundColor: "transparent",
                border: "2px solid #6869f7",
                borderRadius: "50px",
                color: "#6869f7",
                fontWeight: "600",
                fontSize: "16px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#6869f7",
                  color: "#fff",
                },
              }}
            >
              Resend Verification Code
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <FormContainer>
        <Container maxWidth="sm">
          <Box my={4} textAlign="center">
            {renderContent()}
          </Box>
        </Container>
      </FormContainer>
    </div>
  );
};

export default EmailVerification;
