import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa"; // Calendar Icon

const DateField = ({
  value,
  onChange,
  placeholder = "MM/DD/YYYY",
  dateFormat = "MM/dd/yyyy", // Default format
  minDate = null, // Optional
  maxDate = null, // Optional
  showYearDropdown = false,
  showMonthDropdown = false,
}) => {
  const parseDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US");
  };

  const datePickerRef = React.useRef(null);

  return (
    <div className="relative w-full">
      <DatePicker
        selected={parseDate(value)}
        onChange={(date) => onChange(formatDate(date))}
        dateFormat={dateFormat} // Use format from parent
        placeholderText={placeholder}
        className="w-full p-3 border border-gray-300 rounded-2xl focus:border-blue-500 focus:outline-none pr-10"
        ref={datePickerRef}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        showMonthDropdown={showMonthDropdown}
        dropdownMode="select"
        minDate={minDate || undefined} // Use minDate if provided
        maxDate={maxDate || undefined} // Use maxDate if provided
      />
      <FaRegCalendarAlt
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
        onClick={() => datePickerRef.current.setOpen(true)}
      />
    </div>
  );
};

export default DateField;
