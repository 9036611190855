export const SURVEY_QUESTIONS = [
  {
    key: "q1",
    text: "In the past 7 days I have been able to laugh and see the funny side of things",
    answers: [
      "As much as I always could",
      "Not quite so much now",
      "Definitely not so much now",
      "Not at all",
    ],
  },
  {
    key: "q2",
    text: "In the past 7 days I have looked forward with enjoyment to things",
    answers: [
      "As much as I ever did",
      "Rather less than I used to",
      "Definitely less than I used to",
      "Hardly at all",
    ],
  },
  {
    key: "q3",
    text: "In the past 7 days I have blamed myself unnecessarily when things went wrong",
    answers: [
      "No, never",
      "Not very often",
      "Yes, some of the time",
      "Yes, most of the time",
    ],
  },
  {
    key: "q4",
    text: "In the past 7 days I have been anxious or worried for no good reason",
    answers: [
      "No, not at all",
      "Hardly ever",
      "Yes, sometimes",
      "Yes, very often",
    ],
  },
  {
    key: "q5",
    text: "In the past 7 days I have felt scared or panicky for no very good reason",
    answers: [
      "No, not at all",
      "No, not much",
      "Yes, sometimes",
      "Yes, quite a lot",
    ],
  },
  {
    key: "q6",
    text: "In the past 7 days things have been getting on top of me",
    answers: [
      "No, I have been coping as well as ever",
      "No, most of the time I have coped quite well",
      "Yes, sometimes I have not been coping as well as usual",
      "Yes, most of the time I have not been able to cope at all",
    ],
  },
  {
    key: "q7",
    text: "In the past 7 days I have been so unhappy that I have had difficulty sleeping",
    answers: [
      "No, not at all",
      "Not very often",
      "Yes, sometimes",
      "Yes, most of the time",
    ],
  },
  {
    key: "q8",
    text: "In the past 7 days I have felt sad or miserable",
    answers: [
      "No, not at all",
      "Not very often",
      "Yes, quite often",
      "Yes, most of the time",
    ],
  },
  {
    key: "q9",
    text: "In the past 7 days I have been so unhappy that I have been crying",
    answers: [
      "No, never",
      "Only occasionally",
      "Yes, quite often",
      "Yes, most of the time",
    ],
  },
  {
    key: "q10",
    text: "In the past 7 days the thought of harming myself has occurred to me",
    answers: ["Never", "Hardly ever", "Sometimes", "Yes, quite often"],
  },
];

export const MEDICAL_INSURANCE_LIST = [
  "Blue Cross Blue Shield of Massachusetts",
  "Harvard Pilgrim Health Care",
  "Tufts Health Plan",
  "Fallon Health",
  "Health New England",
  "AllWays Health Partners (formerly Neighborhood Health Plan)",
  "BMC HealthNet Plan",
  "Celticare Health Plan",
  "Aetna",
  "UnitedHealthcare",
  "MassHealth",
  "WellSense Health Plan (formerly Boston Medical Center HealthNet Plan)",
  "UnitedHealth Group",
  "Anthem/Elevance Health",
  "Aetna (part of CVS Health)",
  "Cigna",
  "Humana",
  "Kaiser Permanente",
  "Blue Cross Blue Shield Association companies",
  "Centene Corporation",
  "Molina Healthcare",
  "Oscar Health",
  "Bright Health",
  "Ambetter",
  "CareSource",
  "Harvard Pilgrim Health Care",
  "Health Net",
  "HealthPartners",
  "Highmark",
  "Independence Blue Cross",
  "Premera Blue Cross",
  "WellCare",
  "Medicare",
  "Medicare Advantage",
  "Medicaid",
  "Anthem (CA, CO, CT, GA, IN, KY, ME, MO, NE, NH, NY, OH, VA, WI)",
  "Care First Blue Cross Blue Shield MD, DC and Delaware",
  "BC ID",
  "Health Care Services Corp: Blue Cross Blue Shield IL, MT, NM, OK, TX",
  "Beacon",
  "Blue Cross Blue Shield of Florida",
  "Blue Cross Blue Shield of Massachusetts",
  "Blue Cross Blue Shield of Michigan",
  "Blue Cross Blue Shield of Minnesota",
  "Blue Cross Blue Shield of North Carolina",
  "Blue Cross Blue Shield of North Dakota",
  "Blue Cross Blue Shield of New Jersey",
  "Blue Cross Blue Shield of South Carolina",
  "Emblem GHI PPO",
  "Fidelis (Commercial and public sector)",
  "HealthPartners (MN)",
  "Humana",
  "Magellan",
  "Moda Health",
  "PreferredOne",
  "Qualcare",
  "Regence Blue Shield of ID, OR, UT, WA",
  "Tricare",
  "UCare",
  "UnitedHealthCare/Optum",
  "WellCare Health Plans, Inc",
];

export const IN_TAKE_FORM_QUESTIONS = [
  {
    id: "consent_and_sharing",
    question: "",
    fields: [
      {
        id: "consent",
        question:
          "I consent to the use of my contact information provided above for the purpose of onboarding me into nurtur's digital health platform.",
        type: "checkbox",
        required: true,
      },
      {
        id: "authorize_sharing",
        question:
          "I authorize the sharing of Personal Information collected by nurtur with my physician to better manage my healthcare needs.",
        type: "checkbox",
        required: true,
      },
    ],
  },
  {
    id: "address",
    question: "Where do you live?",
    fields: [
      {
        id: "country",
        type: "dropdown",
        placeholder: "Select Country",
        required: true,
      },
      {
        id: "state",
        type: "dropdown",
        placeholder: "Select State",
        required: true,
      },
    ],
  },
  {
    id: "phone_number",
    question: "Please provide your contact information",
    type: "text",
    placeholder: "(xxx)-xxx-xxxx",
    required: true,
  },
  {
    id: "obgyn_health_insurance",
    questions: [
      {
        question: "Who is your OBGYN or PCP?",
        fields: [
          {
            id: "obgyn_name",
            type: "text",
            placeholder: "Doctor's name",
            required: false,
            question: (
              <div>
                OBGYN or PCP<span className="text-red-600">*</span>
              </div>
            ),
          },
        ],
      },
      {
        question: "Do you have a health plan?",
        fields: [
          {
            id: "health_insurance",
            type: "dropdown",
            options: ["Private Insurance", "Medicaid", "Other"],
            placeholder: "Select Health Insurance",
            required: false,
            question: (
              <div>
                Insurance Provider<span className="text-red-600">*</span>
              </div>
            ),
          },
        ],
      },
    ],
  },
  {
    id: "birth_date",
    question: "Please provide your birth date*",
    type: "date",
    placeholder: "MM/DD/YYYY",
    required: true,
  },
  {
    id: "education_level",
    question: "What is the highest level of education you completed?*",
    type: "dropdown",
    placeholder: "Select option",
    options: [
      { label: "Less than HS grad", value: "Less than HS grad" },
      { label: "HS grad or GED", value: "HS grad or GED" },
      { label: "Some college", value: "Some college" },
      { label: "Assoc/Tech degree", value: "Assoc/Tech degree" },
      { label: "Completed college", value: "Completed college" },
      {
        label: "Degree work beyond college",
        value: "Degree work beyond college",
      },
    ],
    required: true,
  },
  {
    id: "pre_pregnancy_weight_height",
    question: "Please provide your pre-pregnancy height and weight*",
    fields: [
      {
        id: "height_feet",
        question: "Height",
        type: "number",
        placeholder: "Feet",
        min: 0,
        max: 9,
        required: true,
      },
      {
        id: "height_inches",
        question: null,
        type: "number",
        placeholder: "Inches",
        min: 0,
        max: 12,
        required: true,
      },
      {
        id: "weight_lbs",
        question: "Weight",
        type: "number",
        placeholder: "Pounds (lbs)",
        min: 60,
        max: 600,
        required: true,
      },
    ],
  },
  {
    id: "diagnosed_depression",
    question: "Have you been diagnosed with depression in the past?",
    type: "button",
    options: ["Yes", "No"],
    required: true,
  },
  {
    id: "diagnosed_mental_health",
    question:
      "Have you been diagnosed with another mental health condition in the past?",
    type: "button",
    options: ["Yes", "No"],
    required: true,
  },
  {
    id: "prescribed_medication",
    question:
      "Were you prescribed medication to treat depression or another mental health condition?",
    type: "button",
    options: ["Yes", "No"],
    required: true,
  },
  {
    id: "experienced_anxiety",
    question: "Have you experienced anxiety before pregnancy?",
    type: "button",
    options: ["Yes", "No"],
    required: true,
  },
  {
    id: "pregnancy_counseling",
    question:
      "Before you got pregnant, did a doctor, nurse, or other healthcare worker talk to you about getting counseling or treatment for depression or anxiety to prepare for a healthy pregnancy and baby?",
    type: "button",
    options: ["Yes", "No"],
    required: true,
  },
  {
    id: "baby_due_date",
    question: "Baby's Due date (if applicable)",
    type: "date",
    placeholder: "MM/DD/YYYY",
    required: false,
  },
];

export const countryStateInsuranceData = [
  {
    country: "United States",
    states: [
      {
        name: "California",
        insurances: ["Blue Shield", "Health Net", "Kaiser Permanente"],
      },
      {
        name: "Texas",
        insurances: ["Aetna", "Cigna", "UnitedHealthcare"],
      },
      {
        name: "New York",
        insurances: ["Fidelis Care", "MetroPlus", "Oscar Health"],
      },
      {
        name: "Florida",
        insurances: ["AvMed", "Florida Blue", "Humana"],
      },
      {
        name: "Illinois",
        insurances: ["Ambetter", "Molina Healthcare", "Blue Cross Blue Shield"],
      },
    ],
  },
  {
    country: "Canada",
    states: [
      {
        name: "Ontario",
        insurances: ["Manulife", "Sun Life", "Green Shield Canada"],
      },
      {
        name: "British Columbia",
        insurances: [
          "Pacific Blue Cross",
          "Great-West Life",
          "Medavie Blue Cross",
        ],
      },
      {
        name: "Quebec",
        insurances: ["Desjardins", "iA Financial Group", "SSQ Insurance"],
      },
    ],
  },
  {
    country: "United Kingdom",
    states: [
      {
        name: "England",
        insurances: ["Bupa", "AXA Health", "Vitality Health"],
      },
      {
        name: "Scotland",
        insurances: ["WPA", "CS Healthcare", "The Exeter"],
      },
    ],
  },
  {
    country: "India",
    states: [
      {
        name: "Maharashtra",
        insurances: ["ICICI Lombard", "HDFC ERGO", "Tata AIG"],
      },
      {
        name: "Karnataka",
        insurances: ["ManipalCigna", "SBI Health", "Future Generali"],
      },
    ],
  },
  {
    country: "Australia",
    states: [
      {
        name: "New South Wales",
        insurances: ["Medibank", "Bupa", "HCF"],
      },
      {
        name: "Victoria",
        insurances: ["ahm Health Insurance", "Frank Health", "GMHBA"],
      },
    ],
  },
  {
    country: "Germany",
    states: [
      {
        name: "Bavaria",
        insurances: ["AOK", "Allianz", "TK"],
      },
      {
        name: "Berlin",
        insurances: ["Barmer", "DAK", "HEK"],
      },
    ],
  },
  {
    country: "Japan",
    states: [
      {
        name: "Tokyo",
        insurances: ["Tokio Marine", "Sompo Japan", "Aioi Nissay Dowa"],
      },
      {
        name: "Osaka",
        insurances: ["Mitsui Sumitomo", "Nippon Life", "Sony Financial"],
      },
      {
        name: "Hokkaido",
        insurances: ["Fukoku Mutual", "Daido Life", "Meiji Yasuda"],
      },
    ],
  },
  {
    country: "France",
    states: [
      {
        name: "Île-de-France",
        insurances: ["AXA France", "Allianz France", "Groupama"],
      },
      {
        name: "Provence-Alpes-Côte d'Azur",
        insurances: ["CNP Assurances", "MACSF", "MAIF"],
      },
      {
        name: "Auvergne-Rhône-Alpes",
        insurances: ["AG2R La Mondiale", "Crédit Agricole", "Generali France"],
      },
    ],
  },
  {
    country: "Brazil",
    states: [
      {
        name: "São Paulo",
        insurances: ["SulAmérica", "Bradesco Saúde", "Hapvida"],
      },
      {
        name: "Rio de Janeiro",
        insurances: ["Amil", "NotreDame Intermédica", "Prevent Senior"],
      },
      {
        name: "Minas Gerais",
        insurances: ["Unimed", "Golden Cross", "Porto Seguro"],
      },
    ],
  },
  {
    country: "South Africa",
    states: [
      {
        name: "Gauteng",
        insurances: ["Discovery Health", "Momentum Health", "Bonitas"],
      },
      {
        name: "Western Cape",
        insurances: ["Fedhealth", "Profmed", "Medshield"],
      },
      {
        name: "KwaZulu-Natal",
        insurances: ["KeyHealth", "Bestmed", "CompCare"],
      },
    ],
  },
  {
    country: "Spain",
    states: [
      {
        name: "Catalonia",
        insurances: ["Mapfre Salud", "Sanitas", "Asisa"],
      },
      {
        name: "Madrid",
        insurances: ["Adeslas", "DKV Seguros", "AXA Salud"],
      },
      {
        name: "Andalusia",
        insurances: ["Caser Salud", "Allianz Salud", "FIATC Seguros"],
      },
    ],
  },
  {
    country: "Italy",
    states: [
      {
        name: "Lombardy",
        insurances: ["UniSalute", "Fondiaria-Sai", "Generali Italia"],
      },
      {
        name: "Tuscany",
        insurances: ["Cattolica Assicurazioni", "Reale Mutua", "Gruppo BPU"],
      },
      {
        name: "Veneto",
        insurances: ["Poste Vita", "Allianz Italia", "Genertel"],
      },
    ],
  },
  {
    country: "China",
    states: [
      {
        name: "Beijing",
        insurances: ["Ping An", "China Life", "CPIC"],
      },
      {
        name: "Shanghai",
        insurances: ["Taikang Life", "New China Life", "Aviva-COFCO"],
      },
      {
        name: "Guangdong",
        insurances: ["China Pacific", "Sun Life Everbright", "AIA China"],
      },
    ],
  },
  {
    country: "Mexico",
    states: [
      {
        name: "Mexico City",
        insurances: ["GNP Seguros", "AXA Mexico", "Mapfre México"],
      },
      {
        name: "Jalisco",
        insurances: ["Qualitas", "Zurich México", "Chubb Seguros"],
      },
      {
        name: "Nuevo León",
        insurances: ["MetLife México", "Allianz México", "HDI Seguros"],
      },
    ],
  },
];
export const getPhoneNumberPattern = (country) => {
  const patterns = {
    "United States": { pattern: "^[0-9]{10}$", placeholder: "(555) 555-5555" },
    Canada: { pattern: "^[0-9]{10}$", placeholder: "(555) 555-5555" },
    India: { pattern: "^[0-9]{10}$", placeholder: "98765 43210" },
    "United Kingdom": { pattern: "^[0-9]{11}$", placeholder: "07911 123456" },
    Germany: { pattern: "^[0-9]{11}$", placeholder: "0176 12345678" },
    Australia: { pattern: "^[0-9]{9}$", placeholder: "0412 345 678" },
    Japan: { pattern: "^[0-9]{10,11}$", placeholder: "090-1234-5678" },
    France: { pattern: "^[0-9]{10}$", placeholder: "06 12 34 56 78" },
    Brazil: { pattern: "^[0-9]{11}$", placeholder: "(11) 91234-5678" },
    "South Africa": { pattern: "^[0-9]{10}$", placeholder: "071 234 5678" },
    Spain: { pattern: "^[0-9]{9}$", placeholder: "612 34 56 78" },
    Italy: { pattern: "^[0-9]{10}$", placeholder: "320 123 4567" },
    China: { pattern: "^[0-9]{11}$", placeholder: "138 1234 5678" },
    Mexico: { pattern: "^[0-9]{10}$", placeholder: "55 1234 5678" },
  };

  return (
    patterns[country] || {
      pattern: "^[0-9]+$",
      placeholder: "Enter phone number",
    }
  );
};
