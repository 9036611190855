// Desc: Login page for the user to login to their account

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  OthersContainer,
  SubmitButton,
  SmallText,
  CreateAccount,
} from "../../components/MainStyles";
import logo from "../../nurtur_new_logo.svg";
import styles from "./styles";
import { useDispatch } from "react-redux";
import { addUserInfo } from "../../redux/features/user/userInfoSlice";
import { addChatData } from "../../redux/features/chat/chatSlice";
import client from "../../api";
import Urls from "../../api/urls";
import { CircularProgress } from "@mui/material";
import { message } from "antd";
import Input from "../../utils/Input";

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be atleast 8 characters")
    .required("Password is required"),
});

const Login = ({ setIsAuthenticated }) => {
  const [loginError, setLoginError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailVerified, setEmailVerified] = useState(null);
  // const [email, seteEmail] = useState('');
  // const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(addUserInfo({ isLoggedIn: true }));
      get_user();
      // get_chat();
      // get_epds();
    }
  }, []);

  const get_user = async () => {
    try {
      const response = await client.get(Urls.CURRENT_USER);
      if (response.status === 200) {
        const results = response.data.results;
        dispatch(addUserInfo(results));
        navigate("/welcome");
      } else {
        navigate("/chat");
      }
    } catch (error) {
      message.error(`An error occurred while fetching user details:${error}`);
      console.error("An error occurred while fetching data.", error);
    }
  };

  const get_chat = async () => {
    try {
      const response = await client.get(Urls.GET_CHAT);
      const results = response.data.data;
      dispatch(addChatData(results));
    } catch (error) {
      message.error(`An error occurred while fetching chat data: ${error}`);
    }
  };

  const get_epds = async () => {
    try {
      const response = await client.get(Urls.EPDS);
      const results = response.data.data;
      if (results.onboarded === 1) {
        // navigate('/chat');
        navigate("/welcome");
      } else {
        navigate("/survey");
      }
    } catch (error) {
      console.error("Error occurred while fetching EPDS data:", error);
      message.error(`An error occurred while fetching EPDS data: ${error}`);
      // navigate("/");
    }
  };

  const handleLoginSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    setLoginError("");
    try {
      const response = await client.post(Urls.LOGIN, {
        email: values.email,
        password: values.password,
      });

      if (response.status === 200) {
        const { results, email_verified } = response.data;
        setEmailVerified(email_verified);

        const { email, idToken, refreshToken, expiresIn, localId } = results;

        if (email_verified === true) {
          setIsAuthenticated(true);
          dispatch(addUserInfo({ isLoggedIn: true }));
          localStorage.setItem("email", email);
          localStorage.setItem("token", idToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("expiresIn", expiresIn);
          localStorage.setItem("localId", localId);
          await get_user();
          // await get_chat();
          // await get_epds();
        }
      }
    } catch (error) {
      setLoginError(
        `Login failed. ${error.response?.data?.message || error.message}`
      );
      message.error(
        `Login failed. ${error.response?.data?.message || error.message}`
      );
    }
    setIsSubmitting(false);
    setSubmitting(false);
  };

  return (
    <>
      <div style={{ position: "relative", height: "100%" }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            <MainTitle style={{ marginBottom: "2rem" }}>
              Login to your nurtur account
            </MainTitle>
            {/* <SmallText>
              Don't have an account? Please{" "}
              <Link
                to="/signup"
                style={{ fontWeight: "bold", color: "#7B7FFF" }}
              >
                Sign Up
              </Link>
            </SmallText> */}

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              //   validateOnChange={false}
              onSubmit={handleLoginSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    mandatory
                  />
                  <Input
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter Password"
                    mandatory
                  />
                  <div>
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        paddingLeft: 10,
                      }}
                    >
                      {loginError}
                    </p>
                    {emailVerified === false && (
                      <>
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            paddingLeft: 10,
                          }}
                        >
                          Please verify your email. Check your inbox for a
                          verification link.
                        </p>
                        <SubmitButton
                          theme="blue"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Resend Verification Email
                        </SubmitButton>
                      </>
                    )}
                  </div>
                  <Link to="/forgot">
                    <h1 className="text-black text-end mr-2 underline mb-8 text-xs">
                      Forgot Password?
                    </h1>
                  </Link>
                  <SubmitButton
                    theme="blue"
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      backgroundColor: "#6869F7",
                      marginRight: "0",
                      marginLeft: "0",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Log In"
                    )}
                  </SubmitButton>
                </Form>
              )}
            </Formik>

            <Link to="/signup">
              <CreateAccount
                theme="blue"
                style={{
                  marginBottom: "2rem",
                  marginRight: "0",
                  marginLeft: "0",
                }}
              >
                Create an account
              </CreateAccount>
            </Link>
            {/* <Link to="/GuestLogin">
            <SmallText style={{ textDecoration: "underline" }}>
              Continue as Guest
            </SmallText>
          </Link> */}
            {/* <SmallText>Other Logins</SmallText>
            <OthersContainer>
              <SubmitButton theme="blue">
                <Link to="/dashboard">For Provider</Link>
              </SubmitButton>
              <SubmitButton theme="blue">
                <Link to="/GuestLogin">For Guest</Link>
              </SubmitButton>
            </OthersContainer> */}

            <SmallText
              style={{
                textDecoration: "underline",
                marginTop: "10px",
              }}
            >
              <Link to="/terms">Terms of Use</Link> |{" "}
              <Link to="/privacy">Privacy Policy</Link>
            </SmallText>
          </LoginForm>
        </FormContainer>
      </div>
    </>
  );
};

export default Login;
