// Code to render the site and set up the routes
// There are three types of access: not logged in, user, and provider

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import VerifCode from "./pages/VerifCode.js";
import SignUp from "./pages/SignUp";
import PageNotFound from "./pages/404.js";
import Account from "./pages/user/Account.js";
import Chat from "./pages/user/Chat.js";
import Chatbot from "./pages/user/Chatbot.js";
import Forgot from "./pages/Forgot.js";
import Dashboard from "./pages/provider/Dashboard.js";
import Survey from "./pages/user/Survey.js";
import Epds from "./pages/user/Epds.js";
import Terms from "./pages/PrivacyPolicy/termsAndConditions.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy.js";
import GuestChatBot from "./pages/user/Guest_Session.js";
import DefaultLayout from "./pages/layouts/DefaultLayout";
import ProtectedRoutes from "./ProtectedRoutes.js";
import EmailVerification from "./pages/EmailVerification.js";
import ResetPassword from "./pages/ResetPassword.js";
import ChatsPage from "./pages/user/ChatsPage/Chatspage.jsx";
import WelcomeSurvey from "./pages/user/PDPSurvey/welcomeSurvey.js";
import WelcomeBack from "./pages/user/WelcomeBack/WelcomeBack.jsx";
import PDPSurvey from "./pages/user/PDPSurvey/index.jsx";
import HomePage from "./pages/user/Home/homePage.jsx";

import PDPSurveyForm from "./pages/user/PDPSurvey/PDPSurveyForm.jsx";
import WelcomeNew from "./pages/user/WelcomeNew/WelcomeNew.jsx";
// TODO: implement "logged in as user" and "logged in as provider" routes

function App() {
  const token = localStorage.getItem("token");
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route exact path="/" element={<DefaultLayout />}>
          <Route
            index
            element={
              isAuthenticated ? (
                <Navigate to="/chat" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/verify" element={<VerifCode />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="pdps"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <PDPSurvey />
              </ProtectedRoutes>
            }
          />
          <Route
            path="welcome"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                {/* <WelcomeSurvey /> */}
                <WelcomeNew />
              </ProtectedRoutes>
            }
          />
          <Route
            path="survey"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <Survey />
              </ProtectedRoutes>
            }
          />
          <Route
            path="home"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <HomePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="account"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <Account />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="chat"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <Chatbot />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="chat"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <ChatsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="epds"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <ChatsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="in-take-form"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <PDPSurveyForm />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="welcome-back"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <WelcomeNew />
              </ProtectedRoutes>
            }
          /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/GuestLogin" element={<GuestChatBot />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route
            path="/account-verification"
            element={
              <EmailVerification setIsAuthenticated={setIsAuthenticated} />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
