import React from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleStartSurvey = () => {
    navigate("/survey");
  };

  return (
    <div className="homePage">
      <div className="home_container">
        <h1 className="homeMain_heading">Welcome to nurtur, Amanda!</h1>

        <p className="home_paragraph">
          Our mission is to predict and prevent postpartum depression before
          symptoms appear through educational chat sessions that help you
          prepare during your pregnancy and postpartum.{" "}
        </p>

        <h1 className="home_subHeading">
          Before starting your first chat session, please fill out this survey:
        </h1>

        <div className="home_BoxContainer">
          <h1 className="home_title">
            Edinburgh Postnatal Depression Survey (EPDS)
          </h1>
          <p>
            This screening helps spot early signs of postpartum depression,
            ensuring you get the right support when you need it.
          </p>
          <p className="home_italicText">
            <i>
              Edinburgh Postnatal Depression Scale (EPDS). Adapted from the
              British Journal of Psychiatry, June 1987, vol. 150 by J.L. Cox,
              J.M. Holden, R. Sagovsky.
            </i>
          </p>

          <button className="home_LearnButton" onClick={handleStartSurvey}>
            Start Survey
          </button>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
