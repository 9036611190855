import React from "react";
import { Field, ErrorMessage } from "formik";

const Input = ({
  placeholder = "Enter text...",
  type = "text",
  options = [],
  onValueChange = () => {},
  className = "",
  mainClassName = "",
  name,
  ...props
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    onValueChange(newValue);
  };

  return (
    <div className={`flex flex-col space-y-2 mt-3 mb-3 ${mainClassName}`}>
      <Field name={name}>
        {({ field, meta }) => (
          <>
            {type === "select" ? (
              <div className="relative">
                <select
                  {...field}
                  onChange={handleChange}
                  className={`appearance-none border rounded-2xl p-3 pr-10 w-full text-base
                    ${
                      meta.touched && meta.error
                        ? "border-red-500"
                        : "border-gray-400"
                    }
                    ${className}`}
                  {...props}
                  style={{
                    borderColor:
                      meta.touched && meta.error ? "" : "rgba(51, 51, 51, 0.5)",
                  }}
                >
                  <option value="" disabled>
                    {placeholder}
                  </option>
                  {options &&
                    options.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <input
                {...field}
                type={type}
                placeholder={placeholder}
                className={`border rounded-2xl p-3 w-full text-base
                  ${
                    meta.touched && meta.error
                      ? "border-red-500"
                      : "border-gray-500"
                  }
                  ${className}`}
                style={{
                  borderColor:
                    meta.touched && meta.error ? "" : "rgba(51, 51, 51, 0.5)",
                    backgroundColor: "#fff"
                }}
                {...props}
              />
            )}
            <ErrorMessage name={name}>
              {(msg) => <div className="text-red-500 text-sm">{msg}</div>}
            </ErrorMessage>
          </>
        )}
      </Field>
    </div>
  );
};

export default Input;
