import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import {
  Box,
  InputLabel,
  TextField,
  Select,
  Button,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import { FaUpload } from "react-icons/fa6";
import "../SupportPanel.css";

export default function GeneralInquiry({ setShowInquiryPanel }) {
  const [formData, setFormData] = useState({
    name: "",
    contactReason: "",
    email: "",
    phoneNumber: "",
    description: "",
    file: null,
  });
  const [fileName, setFileName] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleInquiryClose = () => {
    setShowInquiryPanel(false);
  };
  const handleFileUpload = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setFormData({ ...formData, file });
      setFileName(file.name);
    } else {
      console.error("No file selected or file upload failed.");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("successfully submitted form Data", formData);
  };
  return (
    <div className="support-panel-sidebar">
      <Box className="boxStyle">
        <div className="headerContainer">
          <div>
            <FaArrowLeft onClick={handleInquiryClose} className="backButton" />
          </div>
          <div>
            <h1 className="generalInquiryHeading">
              General Inquiry / Bug Report
            </h1>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="generalInquiryForm">
          <div className="formField">
            <InputLabel className="inputLabel">Name*</InputLabel>
            <TextField
              value={formData.name}
              name="name"
              fullWidth
              onChange={handleInputChange}
              required
              variant="outlined"
              className="inputField"
            />
          </div>
          <div className="formField">
            <InputLabel className="inputLabel">Contact Reason*</InputLabel>
            <FormControl fullWidth required>
              <Select
                name="contactReason"
                value={formData.contactReason}
                onChange={handleInputChange}
                displayEmpty
                variant="outlined"
                className="selectField"
                placeholder="Choose Contact Reason"
              >
                <MenuItem value="" disabled>
                  Choose Contact Reason
                </MenuItem>
                <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                <MenuItem value="Product Support">Product Support</MenuItem>
                <MenuItem value="Technical Assistance">
                  Technical Assistance
                </MenuItem>
                <MenuItem value="Account Issue">Account Issue</MenuItem>
                <MenuItem value="Billing and Payments">
                  Billing and Payments
                </MenuItem>
                <MenuItem value="Feedback/Complaint">
                  Feedback/Complaint
                </MenuItem>
              </Select>
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                <IoIosArrowDown style={{ fontSize: "20px", color: "#000" }} />
              </div>
            </FormControl>
          </div>

          <div className="formField">
            <InputLabel className="inputLabel">Email*</InputLabel>
            <TextField
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              fullWidth
              variant="outlined"
              className="inputField"
            />
          </div>
          <div className="formField">
            <InputLabel className="inputLabel">Phone Number</InputLabel>
            <TextField
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
              fullWidth
              variant="outlined"
              className="inputField"
            />
          </div>
          <div className = "formField">
            <InputLabel className="inputLabel">Description*</InputLabel>
            <TextField
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              className="descriptionField"
            />
          </div>

          <div className="formField">
            <InputLabel className="inputLabel">
              Upload screenshot or recording
            </InputLabel>
            <div className="fileUpload">
              <label htmlFor="file-upload" className="fileUploadLabel">
                {fileName ? (
                  <div className="fileName">{fileName}</div>
                ) : (
                  <label htmlFor="file-upload" className="fileUploadLabel">
                    <IconButton component="span" className="uploadButton">
                      <FaUpload />
                    </IconButton>
                    <span className="fileUploadText">
                      Select file to upload
                    </span>
                  </label>
                )}
              </label>
              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                accept="image/*,video/*"
                onChange={handleFileUpload}
              />
            </div>
          </div>

          <div className="submitButtonContainer">
            <Button type="submit" variant="contained" className="submitButton">
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
}
