// Page where first-time users get directed to register and complete EPDS survey

import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../../components/MainStyles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  MEDICAL_INSURANCE_LIST,
  SURVEY_QUESTIONS,
} from "../../utils/constants";
import { Autocomplete, TextField } from "@mui/material";
import Urls from "../../api/urls";
import client from "../../api";
import { message } from "antd";

const RadioButton = styled.label`
  display: inline-block;
  background-color: #ededff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 10px;
  width: 95%;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 4px;
  &:hover {
    background-color: #aaacff;
  }
  input {
    display: none;
  }
`;

const SegmentedProgressBar = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
`;

const ProgressSegment = styled.div`
  flex: 1;
  height: 8px;
  margin: 0 2px;
  background-color: ${(props) => (props.active ? "#7B7FFF" : "#ededff")};
  border-radius: 4px;
  transition: background-color 0.3s ease;
`;

const today = new Date().toISOString().split("T")[0];
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 1);
const maxDateFormatted = maxDate.toISOString().split("T")[0];

const validationSchema = {
  state: Yup.string()
    .min(2, "Please enter state")
    .required("Please enter state"),
  is_pregnant: Yup.string().required("Please select any option"),
  doctor_name: Yup.string()
    .min(1, "Please enter the Doctor's name")
    .required("Please enter the Doctor's name"),
  due_date: Yup.date()
    .transform((value) => {
      return value ? new Date(value) : value;
    })
    .min(new Date(today), "Due date must be a future date")
    .max(maxDate, "Date cannot be more than a year from today")
    .required("Please enter the baby's due date"),
};

const getValidationSchema = (key) => {
  console.log(key, validationSchema[key]);
  return Yup.object({
    [key]: validationSchema[key],
  });
};

// TODO: Add validation for onboarding information
const validate = (values, currentQuestion) => {
  let { state, doctorName, dueDate } = values;
  const errors = {};
  if (currentQuestion === 0 && state.trim().length === 0) {
    errors.state = "Please enter the state";
  } else if (currentQuestion === 1 && doctorName.trim().length === 0) {
    errors.doctorName = "Please enter the Doctor's name";
  } else if (currentQuestion === 2 && dueDate.trim().length === 0) {
    errors.dueDate = "Please enter baby's due date";
  }
  if (dueDate) {
    const currentDate = new Date();
    dueDate = new Date(dueDate);
    // // Calculate the date 9 months from now
    // const nineMonthsLater = new Date();
    // nineMonthsLater.setMonth(currentDate.getMonth() + 9);
    var cutOff = new Date(currentDate.setMonth(currentDate.getMonth() + 9));
    // Check if the dueDate is more than 9 months from now
    if (dueDate > cutOff) {
      errors.dueDate = "Due date must be within 9 months from now.";
    }
  }

  return errors;
};

const Survey = () => {
  // TODO: update with healthcare plan and phone number fields
  const [infoList, setInfoList] = useState([
    { key: "doctor_name", question: "Doctor's Name", answer: "", type: "text" },
    {
      key: "is_pregnant",
      question: "Are you currently pregnant?",
      answer: "",
      type: "radio",
      radios: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
    },
    { key: "due_date", question: "Baby's Due Date", answer: "", type: "date" },
    {
      key: "has_med_insurance",
      question: "Do you have Health Insurance",
      answer: "",
      type: "radio",
      radios: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
    },
    {
      key: "med_insurance",
      question: "Health Insurance",
      answer: "",
      type: "select",
    },
  ]);
  const navigate = useNavigate();
  const [currentInfo, setCurrentInfo] = useState(0);
  const [EPDS_score, setEPDS_score] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [infoComplete, setInfoComplete] = useState(false);
  const [canStartSurvey, setCanStartSurvey] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const email = localStorage.getItem("email");

  useEffect(() => {
    get_epds();
  }, []);

  useEffect(() => {
    if (currentInfo === infoList.length) {
      setCanStartSurvey(true);
    }
  }, [currentInfo]);

  const handleScoreChange = (event) => {
    setEPDS_score((prevScore) => prevScore + parseInt(event.target.value));
    const payload = {
      question: SURVEY_QUESTIONS[currentQuestion].key,
      answer: event.target.value,
      score: parseInt(event.target.value),
      next_question: currentQuestion + 1,
    };
    if (currentQuestion === SURVEY_QUESTIONS.length - 1) {
      payload.onboarded = 1;
    }
    updateEpds(payload);
  };

  const updateEpds = async (values) => {
    const payload = {
      email,
      ...values,
    };
    try {
      const response = await client.post(Urls.UPDATE_EPDS, payload);
      if (response.status < 400) {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Your session has expired. Please log in again.");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      } else {
        message.error(`An error occurred: ${error}`);
        console.error("Error occurred while updating EPDS:", error);
      }
    }
  };

  const get_epds = async () => {
    try {
      const response = await client.get(Urls.EPDS);
      const results = response.data.data;
      const { info_completed, next_question, score } = results;
      // setUserInfo(results);
      // if (results.doctor_name && results.due_date) {
      //   setInfoComplete(true);
      // }
      setCurrentQuestion(next_question);
      info_completed === 0 ? setInfoComplete(false) : setInfoComplete(true);
      setEPDS_score(score);
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Your session has expired. Please log in again.");
        console.error(
          "Unauthorized error occurred while fetching EPDS data:",
          error
        );
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      } else {
        message.error(`An error occurred while fetching EPDS data: ${error}`);
        console.error("An error occurred while fetching EPDS data:", error);
      }
    }
  };

  const onSubmitOnboarding = async (values) => {
    const { other, ...data } = values;
    if (other) {
      data.med_insurance = other;
    }
    const payload = {
      email,
      info_completed: 1,
      ...data,
    };
    setInfoComplete(true);
    try {
      await client.post(Urls.UPDATE_EPDS, payload);
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Your session has expired. Please log in again.");
        console.error(
          "Unauthorized error occurred while updating onboarding information:",
          error
        );
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      } else {
        message.error(
          `An error occurred while updating onboarding information: ${error}`
        );
        console.error(
          "Error occurred while updating onboarding information:",
          error
        );
      }
    }
  };

  return (
    <div
      style={{
        background: "#ffffff",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5vh",
        }}
      >
        {/* Deending on the status of infoComplete the user is presented either with the 
        registration questionnaire (if False) or the EPDS survey (if True).
        This ensures the sequential presentations of these two surveys. */}
        {infoComplete ? (
          // EPDS survey
          <LoginForm>
            <SmallText>
              Completed {currentQuestion} of {SURVEY_QUESTIONS.length}
            </SmallText>
            <SegmentedProgressBar>
              {SURVEY_QUESTIONS.map((_, index) => (
                <ProgressSegment key={index} active={index < currentQuestion} />
              ))}
            </SegmentedProgressBar>

            <MainTitle>Onboarding Survey</MainTitle>
            {currentQuestion < SURVEY_QUESTIONS.length ? (
              SURVEY_QUESTIONS[currentQuestion] && (
                <div key={currentQuestion}>
                  <SmallText>
                    {SURVEY_QUESTIONS[currentQuestion].text}
                  </SmallText>
                  {SURVEY_QUESTIONS[currentQuestion].answers.map(
                    (answer, i) => (
                      <RadioButton key={i}>
                        <input
                          type="radio"
                          id={`option${i}`}
                          name={`question${currentQuestion}`}
                          value={i}
                          onChange={handleScoreChange}
                        />
                        {answer}
                      </RadioButton>
                    )
                  )}
                </div>
              )
            ) : (
              // Logic to determine if the user should be directed to the chat page or to seek professional help
              <div>
                <SmallText>Thank you for completing the survey!</SmallText>
                {EPDS_score < 13 ? (
                  <SubmitButton theme="blue">
                    <Link to="/chat">Continue to Chat</Link>
                  </SubmitButton>
                ) : (
                  <SmallText>
                    Thank you for completing the survey! Based on your score, we
                    recommend that you seek professional help. Please contact
                    your healthcare provider for further assistance. Or access
                    the following help lines! Postpartum Support International
                    HelpLine: 1-800-944-4773 (available for calls and texts).
                    Support for new and expecting parents struggling with
                    perinatal mental health concerns. National Maternal Mental
                    Health Hotline: 1-833-TLC-MAMA (1-833-852-6262). 24/7
                    emotional support, resources, and counseling for pregnant
                    and postpartum individuals.
                  </SmallText>
                )}
              </div>
            )}
          </LoginForm>
        ) : (
          // Registration questionnaire
          <div>
            <MainTitle>Onboarding Information</MainTitle>
            <LoginForm>
              <SmallText>
                Completed {currentInfo} of {infoList.length}
              </SmallText>
              <SegmentedProgressBar>
                {infoList.map((_, index) => (
                  <ProgressSegment key={index} active={index < currentInfo} />
                ))}
              </SegmentedProgressBar>
              <Formik
                initialValues={{ [infoList[currentInfo]?.key]: "" }}
                validationSchema={getValidationSchema(
                  infoList[currentInfo]?.key
                )}
                onSubmit={(values) => {
                  setInfoList((prevInfoList) => {
                    const newInfoList = [...prevInfoList];
                    if (newInfoList[currentInfo]) {
                      newInfoList[currentInfo].answer =
                        values[newInfoList[currentInfo].key];
                    }
                    return newInfoList;
                  });

                  if (
                    [1, 3].includes(currentInfo) &&
                    values[infoList[currentInfo].key] === "0"
                  ) {
                    setCurrentInfo(currentInfo + 2);
                  } else {
                    setCurrentInfo(currentInfo + 1);
                  }
                  setCurrentValues(values);
                  if (canStartSurvey) {
                    onSubmitOnboarding(currentValues);
                  }
                }}
              >
                {({
                  values,
                  isValid,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => {
                  const { key, type, question, answer, radios } =
                    infoList[currentInfo] || {};
                  return (
                    <Form>
                      {type === "radio" && (
                        <>
                          <SmallText style={{ fontSize: "x-large" }}>
                            {question}
                          </SmallText>
                          {/* <div id="radio-group" style={{ fontSize: 26, textAlign: 'center' }}>{question}</div> */}
                          <div
                            role="group"
                            aria-labelledby="radio-group"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 20,
                              alignItems: "center",
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          >
                            {radios.map(({ label, value }) => (
                              <label
                                style={{
                                  fontSize: 18,
                                  cursor: "pointer",
                                  color: "#707070",
                                }}
                              >
                                <Field
                                  type={type}
                                  name={key}
                                  value={value}
                                  style={{ marginRight: 5, cursor: "pointer" }}
                                />
                                {label}
                              </label>
                            ))}
                          </div>
                        </>
                      )}
                      {["text", "date"].includes(type) && (
                        <>
                          <SmallText style={{ fontSize: "x-large" }}>
                            {question}
                          </SmallText>
                          <Field
                            key={key}
                            type={type}
                            placeholder={"Enter " + question}
                            name={key}
                            {...(key === "due_date" && { min: today, max: maxDateFormatted })}
                            style={{
                              minWidth: 200,
                              maxWidth: 400,
                              width: "100%",
                              height: 56,
                              borderRadius: 4,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#7B7FFF",
                              paddingLeft: 10,
                              margin: 10,
                              marginTop: "1rem",
                              backgroundColor: "#ffffff4d",
                              outlineColor: "#7B7FFF",
                            }}
                          />
                        </>
                      )}
                      {values.has_med_insurance === "1" &&
                        type === "select" && (
                          <>
                            <SmallText style={{ fontSize: "x-large" }}>
                              {question}
                            </SmallText>
                            <Autocomplete
                              disablePortal
                              autoHighlight
                              options={[
                                ...MEDICAL_INSURANCE_LIST.sort(),
                                "Other",
                              ]}
                              sx={{
                                marginLeft: 1,
                                width: "100%",
                                marginTop: 1,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Health Insurance"
                                />
                              )}
                              onChange={(e, value) =>
                                setFieldValue("med_insurance", value)
                              }
                            />
                            {values.med_insurance === "Other" && (
                              <Field
                                key={key}
                                type="text"
                                placeholder={"Enter Health Insurance"}
                                name="other"
                                style={{
                                  minWidth: 200,
                                  maxWidth: 400,
                                  width: "100%",
                                  height: 56,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: "#7B7FFF",
                                  paddingLeft: 10,
                                  margin: 10,
                                  marginTop: "1rem",
                                  backgroundColor: "#ffffff4d",
                                  outlineColor: "#7B7FFF",
                                }}
                              />
                            )}
                          </>
                        )}
                      {infoList[currentInfo]?.key && (
                        <ErrorMessage
                          name={infoList[currentInfo]?.key}
                          component="div"
                          style={{
                            color: "red",
                            marginLeft: 10,
                            fontSize: "small",
                          }}
                        />
                      )}
                      {currentInfo === infoList.length && (
                        <>
                          <SmallText style={{ fontWeight: "bold" }}>
                            Help Us Tailor Your Support – It Only Takes a Couple
                            of Minutes!
                          </SmallText>
                          <SmallText>
                            Before accessing our platform, we invite you to
                            complete the Edinburgh Postnatal Depression Scale
                            (EPDS). This quick and easy screening helps spot
                            early signs of postpartum depression, ensuring you
                            get the right support when you need it. Your few
                            minutes today can make a big difference in
                            supporting your well-being. Let's do this together!
                          </SmallText>
                        </>
                      )}
                      <SubmitButton type="submit" disabled={!isValid}>
                        {currentInfo < infoList.length
                          ? "Next"
                          : "Start Survey"}
                      </SubmitButton>
                    </Form>
                  );
                }}
              </Formik>
            </LoginForm>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey;
