import React, { useContext, useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ChatContext } from "../../../context/chatContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUserInfo } from "../../../redux/features/user/userInfoSlice";
import { GoHome } from "react-icons/go";
import { PiChatBold } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { RiQuestionLine } from "react-icons/ri";
import { MdOutlineArrowCircleLeft } from "react-icons/md";
import { useSelector } from "react-redux";

const SideNavBar = ({ toggleSidebar, isDrawerOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.data);
  const { setShowChatMenu } = useContext(ChatContext);

  const getMenuFromPath = (pathname) => {
    if (pathname.startsWith("/home")) return "home";
    if (pathname.startsWith("/chat")) return "chat";
    if (pathname.startsWith("/profile")) return "profile";
    if (pathname.startsWith("/support")) return "support";
    return null;
  };

  const [selectedMenu, setSelectedMenu] = useState(
    getMenuFromPath(location.pathname)
  );

  useEffect(() => {
    const menu = getMenuFromPath(location.pathname);
    setSelectedMenu(menu);

    setShowChatMenu(menu === "chat");
  }, [location.pathname, setShowChatMenu]);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenu(menuItem);
    if (menuItem === "logout") {
      handleLogout();
    }
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("localId");
    dispatch(addUserInfo({ isLoggedIn: false }));
    navigate("/login");
  };

  return (
    <>
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "#FFFFFF",
            color: "black",
            padding: "10px",
            paddingTop: "20px",
            border: "1px solid #E3E3FB",
            boxShadow: "0 4px 20px 1px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: "column",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "4px",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              alt="nurtur logo"
              src="/images/nurtur_new_logo.svg"
              style={{
                height: "2.5rem",
              }}
            />
          </Box>
          <IconButton color="primary" edge="end" onClick={toggleSidebar}>
            <img
              src="/images/LeftMenuHamburger-Purple.svg"
              alt="Menu Icon"
              style={{ width: "40px", height: "40px" }}
            />
          </IconButton>
        </Box>
        <List>
          <ListItem
            button
            component={Link}
            to="/home"
            sx={{
              fontSize: "18px",
              ".MuiTypography-root": {
                fontWeight: selectedMenu === "home" ? "600" : "400",
              },
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#E3E3FB",
                color: "#000000",
                ".MuiTypography-root": {
                  fontWeight: "600",
                },
              },
              backgroundColor:
                selectedMenu === "home" ? "#E3E3FB" : "transparent",
              borderRadius: "12px",
              padding: "8px",
            }}
            onClick={() => handleMenuItemClick("home")}
          >
            <GoHome
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/chat"
            sx={{
              fontSize: "18px",
              ".MuiTypography-root": {
                fontWeight: selectedMenu === "chat" ? "600" : "400",
              },
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#E3E3FB",
                color: "#000000",
                ".MuiTypography-root": {
                  fontWeight: "600",
                },
              },
              backgroundColor:
                selectedMenu === "chat" ? "#E3E3FB" : "transparent",
              borderRadius: "12px",
              padding: "8px",
            }}
            onClick={() => handleMenuItemClick("chat")}
          >
            <PiChatBold
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <ListItemText primary="Chat" />
          </ListItem>
        </List>

        <Box sx={{ flexGrow: 1 }} />
        <List>
          <ListItem
            button
            component={Link}
            to="/profile"
            sx={{
              fontSize: "18px",
              ".MuiTypography-root": {
                fontWeight: selectedMenu === "profile" ? "600" : "400",
              },
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#E3E3FB",
                color: "#000000",
                ".MuiTypography-root": {
                  fontWeight: "600",
                },
              },
              backgroundColor:
                selectedMenu === "profile" ? "#E3E3FB" : "transparent",
              borderRadius: "12px",
              padding: "8px",
            }}
            onClick={() => handleMenuItemClick("profile")}
          >
            <CgProfile
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <ListItemText
              primary={`${userInfo.firstname} ${userInfo.lastname}`}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/support"
            sx={{
              fontSize: "18px",
              ".MuiTypography-root": {
                fontWeight: selectedMenu === "support" ? "600" : "400",
              },
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#E3E3FB",
                color: "#000000",
                ".MuiTypography-root": {
                  fontWeight: "600",
                },
              },
              backgroundColor:
                selectedMenu === "support" ? "#E3E3FB" : "transparent",
              borderRadius: "12px",
              padding: "8px",
            }}
            onClick={() => handleMenuItemClick("support")}
          >
            <RiQuestionLine
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <ListItemText primary="Support" />
          </ListItem>

          <ListItem
            button
            sx={{
              fontSize: "18px",
              ".MuiTypography-root": {
                fontWeight: selectedMenu === "logout" ? "600" : "400",
              },
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#E3E3FB",
                color: "#000000",
                ".MuiTypography-root": {
                  fontWeight: "600",
                },
              },
              backgroundColor:
                selectedMenu === "logout" ? "#E3E3FB" : "transparent",
              borderRadius: "12px",
              padding: "8px",
            }}
            onClick={() => handleMenuItemClick("logout")}
          >
            <MdOutlineArrowCircleLeft
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default SideNavBar;
