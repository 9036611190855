import React from "react";
import { useLocation } from "react-router-dom";
import { SmallText } from "../components/MainStyles";

const PageNotFound = () => {
  const location = useLocation();
  const message =
    location.state?.message ||
    "The page you wished to access was not found, please click back or double check the URL. Thanks!";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        position: "relative",
      }}
    >
      <SmallText style={{ margin: "auto" }}>{message}</SmallText>
    </div>
  );
};

export default PageNotFound;
