// Lost access to account page

import React, { useState } from "react";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../components/MainStyles";
import logo from "../nurtur_new_logo.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import Input from "../utils/Input";

const validationSchema = Yup.object({
  email: Yup.string()
    .lowercase()
    .trim()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email address"
    )
    .required("Please enter your email"),
});

const Forgot = () => {
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [lastEmailSent, setLastEmailSent] = useState("");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/send-password-reset-email",
        {
          email: values.email,
        }
      );

      if (response.status === 200) {
        setMessage("Password reset email sent. Please check your inbox.");
        setEmailSent(true);
        setLastEmailSent(values.email);
      } else {
        setMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
    setSubmitting(false);
  };

  const handleResendEmail = async () => {
    setMessage("");
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/send-password-reset-email",
        {
          email: lastEmailSent,
        }
      );

      if (response.status === 200) {
        setMessage("Password reset email resent. Please check your inbox.");
      } else {
        setMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            <MainTitle>Forgot Password</MainTitle>

            {!emailSent ? (
              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Input
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="Enter your email"
                      mandatory
                    />
                    <SubmitButton
                      theme="blue"
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: "#6869F7",
                        marginRight: "0",
                        marginLeft: "0",
                      }}
                    >
                      Reset Password
                    </SubmitButton>
                  </Form>
                )}
              </Formik>
            ) : (
              <div style={{ textAlign: "center" }}>
                <p style={{ marginBottom: "20px", color: "#7B7FFF" }}>
                  Password reset email sent to {lastEmailSent}.
                </p>
                <p style={{ marginBottom: 5, color: "#333" }}>
                  Didn't receive the email?
                </p>
                <SubmitButton
                  theme="blue"
                  onClick={handleResendEmail}
                  style={{
                    backgroundColor: "#6869F7",
                    marginRight: "0",
                    marginLeft: "0",
                  }}
                >
                  Resend Email
                </SubmitButton>
              </div>
            )}

            {message.includes("Error") && (
              <p style={{ marginTop: 5, color: "red", textAlign: "center" }}>
                {message}
              </p>
            )}
            <Link
              to="/login"
              style={{
                display: "block",
                marginTop: "1rem",
                color: "#7B7FFF",
                textDecoration: "none",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              Back to Login
            </Link>
            <SmallText
              style={{
                textDecoration: "underline",
                fontSize: "14px",
              }}
            >
              <Link to="/terms">Terms of Use</Link> |{" "}
              <Link to="/privacy">Privacy Policy</Link>
            </SmallText>
          </LoginForm>
        </FormContainer>
      </div>
    </>
  );
};

export default Forgot;
