import { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import chat from "../../../assets/nurtur-chat illustration.svg";
import chart from "../../../assets/nurtur-chart illustration.svg";
import resources from "../../../assets/nurtur-resources illustration.svg";
import pregnant from "../../../assets/nurtur-pregnant woman illustration.svg";
import { useNavigate } from "react-router-dom";

const contentScreens = [
  {
    id: "page 1",
    heading: "Welcome to nurtur!",
    lines: ["Your pregnancy and postpartum mental health support."],
    image: pregnant,
  },
  {
    id: "page 2",
    heading: "You're not alone -",
    lines: [" after birth don't get connected to the right support."],
    highlightedText: "85% of moms who feel overwhelmed",
    highlightColor: "text-yellow-500",
  },
  {
    heading: "Learn with interactive chat sessions",
    lines: [
      "We'll walk you through important topics relating to your maternal mental health so you feel prepared for anything.",
    ],
    image: chat,
  },
  {
    heading: "Keep track of how you feel during pregnancy",
    lines: [
      "Check in daily (or every few days) to track how you feel as your pregnancy progresses.",
    ],
    image: chart,
  },
  {
    heading: "Get the support you need",
    lines: [
      "Access resources and support for anything you need during pregnancy and after your baby arrives.",
    ],
    image: resources,
  },
];

const WelcomeNew = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex === 4) {
      navigate("/pdps");
    } else {
      setCurrentIndex((prev) => (prev + 1) % contentScreens.length);
    }
  };

  const currentContent = contentScreens[currentIndex];

  return (
    <>
      <div className="min-h-screen  bg-white flex items-center justify-center">
        <div className="max-w-md w-full bg-white  p-6 h-[600px] flex flex-col justify-between">
          <div className="flex items-top justify-center">
            <img
              alt="nurtur logo"
              src="/images/nurtur_new_logo.svg"
              style={{
                height: "2.5rem",
              }}
            />
          </div>
          <div
            className={`flex-1 flex flex-col ${
              currentIndex === 0 ? "items-center text-center" : "items-start"
            } justify-center mt-5 w-full`}
          >
            <h1 className="text-3xl mb-7 leading-relaxed">
              {currentContent?.heading}
            </h1>

            {currentIndex === 1 ? (
              <h2 className=" flex-1 text-3xl text-start w-full leading-relaxed mt-5 pt-2 pb-2">
                <span className="text-2xl sm:text-3xl font-semibold text-yellow-500 my-4">
                  {currentContent?.highlightedText}
                </span>
                {currentContent?.lines[0]}
              </h2>
            ) : (
              <div className="text-lg sm:text-xl w-full flex flex-col">
                {currentContent?.lines.map((line, index) => (
                  <p key={index} className="text-base sm:text-lg mb-2">
                    {line}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-center my-5">
            <img
              src={currentContent?.image}
              className="w-4/5 sm:w-3/4 md:w-2/3 lg:w-1/2"
            />
          </div>
          <div className="flex justify-center w-full ">
            <button
              onClick={handleNext}
              className="bg-[#6869F7] text-white px-8 py-3 rounded-full text-lg w-full 
                   transition-colors duration-200 gap-2.5 
                   inline-flex items-center justify-center"
            >
              Next <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeNew;
